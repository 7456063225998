/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types } from 'mobx-state-tree';
import Utils from '../utils/Utils';
import * as constants from '../common/constants';

/**
 * A Store to handle Promo banners and cards.
 *
 * @author Hari Gangadharan
 */
const Promo = types.model(
  'Promo',
  {
    mobilePromoDismissed: types.boolean
  }
).views((self) => ({
  /* eslint-disable no-param-reassign */
  get isApplePromoShown() {
    return !self.mobilePromoDismissed && Utils.getOS() === constants.MOBILE_OS_IOS;
  },
  get isAndroidPromoShown() {
    return !self.mobilePromoDismissed && Utils.getOS() === constants.MOBILE_OS_ANDROID;
  },
  get isDesktopMobilePromoShown() {
    return !self.mobilePromoDismissed && Utils.getOS() === constants.DESKTOP_OS;
  }
})).actions((self) => ({
  dismissMobilePromo() {
    self.mobilePromoDismissed = true;
  }
  /* eslint-enable no-param-reassign */
}));

export default Promo;
