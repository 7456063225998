/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import { types, applySnapshot } from 'mobx-state-tree';
import { toJS } from 'mobx';

/**
   * A Store to handle Preference retrieve
   *
   * @author Arun Srinivasan.
   */

const Preference = types.model(
  'Preference',
  {
    status: types.maybeNull(types.string)
  }
).actions((self) => ({
  set(data) {
    applySnapshot(self, data);
    self.setLoader(false);
  },
  setError() {
    const preference = toJS(self);
    applySnapshot(self, preference);
    self.setLoader(false);
  },
  setLoader(status) {
    const preference = toJS(self);
    preference.isLoading = status;
  }
}));

export default Preference;
