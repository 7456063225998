/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import PropTypes from 'prop-types';
import { isStateTreeNode } from 'mobx-state-tree';

import Language from '../models/Language';
import ApiState from '../models/ApiState';
import User from '../models/User';

export const modelOf = (type) => {
  const fn = (props, propName) => {
    if (isStateTreeNode(props[propName]) && type.is(props[propName])) {
      return null;
    }

    return new Error(`Invalid value for prop ${propName}`);
  };
  fn.isRequired = (props, propName) => (
    !(propName in props) ? new Error(`Missing prop ${propName} in props`) : fn(props, propName)
  );

  return fn;
};

/**
 * Definition of all shapes in this project.
 *
 * @author Hari Gangadharan
 */
export const language = modelOf(Language);

export const history = PropTypes.shape({
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
});

export const match = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
});

export const state = modelOf(ApiState);

export const user = modelOf(User);

export const gtmArgs = PropTypes.shape({
  gtmId: PropTypes.string.isRequired,
  auth: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired
});
