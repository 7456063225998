/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';
import EventBus from 'eventing-bus';
import { GTM_REQUESTED } from '../common/constants';
import * as shapes from '../common/shapes';
/**
 * A Container component to initialize the Google Tag Manager
 *
 * @author Hari Gangadharan
 */
export default class TagManagerContainer extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    gtmArgs: shapes.gtmArgs.isRequired,
    delayedGTM: PropTypes.bool.isRequired
  };

  componentDidMount() {
    if (this.props.delayedGTM) {
      EventBus.on(GTM_REQUESTED, () => {
        TagManager.initialize(this.props.gtmArgs);
      });
    } else {
      TagManager.initialize(this.props.gtmArgs);
    }
  }

  render() {
    return null;
  }
}
