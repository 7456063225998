/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import BaseService from './BaseService';
import Framework from '../framework/Framework';

import * as constants from '../common/constants';
import env from '../common/env';

const clientManager = Framework.getClientManager();
/**
 * Preference Service gets the user profile from IES through Tiburon.
 *
 * @author Arun Srinivasan.
 */
export default class PreferenceService extends BaseService {
  constructor() {
    super(constants.GPC_PREFERENCE_RETRIEVE, 'preferenceStatus');
    clientManager.add({
      name: 'preferenceStatus',
      authHeader: 'authorization',
      tokenPrefix: 'Bearer ',
      baseUrl: env.get('MARIN_API_BASE_URL'),
      timeout: env.get('MARIN_API_TIMEOUT')
    });
  }

  handleEvent(eventName, event) { // eslint-disable-line class-methods-use-this
    const preferenceEndPoint = 'gpc/preference/retrieve';
    const payload = {
      emailAddress: event.email
    };

    return clientManager.get('preferenceStatus').post(preferenceEndPoint, payload);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('preference').set(response.data);
    Framework.getEventManager().publish(constants.GPC_PREFERENCE_RETRIEVE_SUCCESS, response.data);
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('preference').setError(error);
  }
}
