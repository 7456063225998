/* eslint-disable */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file MathLive.jsx
 * @author Vignesh Selvam
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import 'mathlive';
import ChatComponentStyles from '../Components/Chatbot/ChatComponentStyles';
import ThemeConstants from '../Common/ThemeConstants';
import * as constants from '../Common/constants';
import { onKeyPressMathKeyboardGAEvent} from '../analytics/analyticsEvents';
import globalContext from '../Context/globalContext';

const MathLive = ({
  onMathInput,
  onClose,
  classes,
  masterTheme,
  theme,
  bookId,
  handleGAEvents
}) => {
  const [value, setValue] = useState('');
  const mf = useRef();
  const [firstMathKeyPressed, setFirstMathKeyPressed] = useState(false);

  useEffect(() => {
    document.body.style.setProperty("--keyboard-toolbar-background-hover", ThemeConstants[masterTheme][theme].bgColor);
    document.body.style.setProperty("--variant-panel-background", ThemeConstants[masterTheme][theme].bgColor);
    document.body.style.setProperty("--keyboard-background", ThemeConstants[masterTheme][theme].bgColor);
    document.body.style.setProperty("--keycap-secondary-background", ThemeConstants[masterTheme][theme].bgColor);
    document.body.style.setProperty("--keyboard-toolbar-background", ThemeConstants[masterTheme][theme].bgColor);
    document.body.style.setProperty("--keycap-background", ThemeConstants[masterTheme][theme].bgColor);
    document.body.style.setProperty("--keycap-text", ThemeConstants[masterTheme][theme].textColor);
    document.body.style.setProperty("--variant-keycap-text", ThemeConstants[masterTheme][theme].textColor);
    document.body.style.setProperty("--keyboard-toolbar-text", ThemeConstants[masterTheme][theme].textColor);
    document.body.style.setProperty("--keycap-secondary-text", ThemeConstants[masterTheme][theme].textColor);
    document.body.style.setProperty("--keyboard-toolbar-text-active", ThemeConstants[masterTheme][theme].textColor);
    document.body.style.setProperty("--keycap-background-hover", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("--keycap-text-hover", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("--keycap-primary-background-hover", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("--keycap-secondary-background-hover", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("--keycap-background-active", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("--keycap-text-active", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("-variant-keycap-text-active", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    document.body.style.setProperty("--variant-keycap-background-active", ThemeConstants[masterTheme][theme].welcomeBtnsBgHoverColor);
    setTimeout(() => {
      const mathElement = document.getElementById('mathfield');
      mathElement.focus();
    }, 100);
    setTimeout(() => {
      const mathFieldElement = document.querySelector('.mathRoot');
      if (mathFieldElement && mathFieldElement.shadowRoot && mathFieldElement.shadowRoot.querySelector('.ML__is-inline')) {
        mathFieldElement.shadowRoot.querySelector('.ML__is-inline').setAttribute('style', 'display:block');
      }
    }, 1000);
    mathVirtualKeyboard.layouts = constants.virtualKeyboardLayers;
    mf.current.menuItems = [];
    mf.current.mathVirtualKeyboardPolicy = "manual";
    mf.current.addEventListener("focusin", () =>  {
      mathVirtualKeyboard.visible = true;
    });
    mf.current.addEventListener("focus", () =>  {
      mathVirtualKeyboard.visible = true;
    });
    mf.current.addEventListener("click", () =>  {
      mathVirtualKeyboard.visible = true;
    });
    mf.current.addEventListener('blur', (ev) => {
      ev.preventDefault();
      setValue(ev.target.value);
    });
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    if(!firstMathKeyPressed) {
      onKeyPressMathKeyboardGAEvent(handleGAEvents, bookId, globalContext.threadId)
      setFirstMathKeyPressed(true);
    }
  };

  const onSave = (event) => {
    mathVirtualKeyboard.visible = false;
    const addDelimiters = `\\(${value}\\)`;
    onMathInput(addDelimiters, document.getElementById('mathView_port').innerHTML);
  }

  return (
    <div className="mathml-editor">
      <div>
        <math-field
            id="mathfield"
            class="mathRoot"
            role="textbox"
            tabIndex="0"
            ref={mf}
            onInput={handleChange}
            math-virtual-keyboard-policy="manual"
            default-mode="inline-math"
        />
      </div>
      <IconButton onClick={onSave} className={classes.mathButtonStyle}>
        <DoneIcon fontSize='small' />
      </IconButton>
      <IconButton onClick={onClose} className={classes.mathButtonStyle}>
        <CloseIcon fontSize='small' />
      </IconButton>
      <div id="mathView_port" style={{ display: 'none' }}>
        <math-field read-only contentEditable="false" style={{ border: 'none', background: 'transparent', color: ThemeConstants[masterTheme][theme].textColor }}>
          {value}
        </math-field>
      </div>
    </div>
  );
};

MathLive.propTypes = {
  classes: PropTypes.object.isRequired,
  onMathInput: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  bookId: PropTypes.string.isRequired,
  handleGAEvents: PropTypes.func.isRequired
}

export default withStyles(ChatComponentStyles)(injectIntl(MathLive));
