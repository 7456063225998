/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * ChatHistoryDeleteItemSnackBar Toast: A Component that handles deleted thread toast
 *
 * @author Arifhusain Soudagar
 */

import React, {
  useState, useEffect, useRef, memo
} from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Snackbar, SnackbarContent } from '@material-ui/core';
import messages from '../../defaultMessages';
import ThemeConstants from '../../Common/ThemeConstants';

const useStyles = makeStyles({
  snackBarContainer: {
    fontSize: 18,
    padding: '8px 16px',
    minWidth: 260,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarBgColor,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarTextColor
  },
  snackBarContainerAction: {
    marginRight: 0
  },
  undoButtonRoot: {
    minWidth: 0,
    padding: 0,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarBgColor,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarTextColor,
    '&:hover': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarBgColor
    },
    fontSize: 18
  },
  snackbarContentWrapper: {
    padding: 0
  },
  undoButtonLabel: {
    minWidth: 0,
    textTransform: 'none',
    textDecoration: 'underline',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarTextColor,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarBgColor
  },
  undoButtonShortcut: {
    opacity: 0.5,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarTextColor,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].snackBarBgColor
  }
});

const ChatHistoryDeleteItemSnackBar = (props) => {
  const {
    onUndo,
    open,
    intl,
    selectedThread,
    onClose,
    onOpen,
    autoCloseTime
  } = props;
  const id = selectedThread;
  const { formatMessage } = intl;
  const [isControlPressed, setIsControlPressed] = useState(false);
  const [isZPressed, setIsZPressed] = useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [snackPack, setSnackPack] = React.useState([]);
  const [threadItem, setThreadItem] = React.useState();
  const classes = useStyles(props);
  const snackBarElement = useRef();

  useEffect(() => {
    if (snackBarElement && snackBarElement.current && snackBarElement.current.focus) {
      snackBarElement.current.focus();
    }
  });

  useEffect(() => {
    const newThreadId = selectedThread;
    setSnackPack((prev) => [...prev, { newThreadId, key: new Date().getTime() }]);
    setThreadItem(selectedThread);
  }, [open]);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      onOpen();
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      onClose();
    }
    setThreadItem(selectedThread);
  }, [snackPack, messageInfo, selectedThread]);

  const handleUndo = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onUndo(threadItem);
  };

  const handleOnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const handleKeyDown = (event) => {
    if (event.type === 'keydown'
      && ((window.navigator.userAgent.indexOf('Mac') === -1 && event.key === 'Control') || event.key === 'Meta')) {
      setIsControlPressed(true);
    }
    if (event.type === 'keydown' && event.key === 'z') {
      setIsZPressed(true);
    }
    if (event.key === 'Tab' && snackBarElement && snackBarElement.current && snackBarElement.current.blur) {
      snackBarElement.current.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  useEffect(() => {
    if (isControlPressed && isZPressed) {
      setIsControlPressed(false);
      setIsZPressed(false);
      handleUndo();
    }
  }, [isControlPressed, isZPressed]);

  const actions = (
    <>
      <Button
        id={`snackBar${id}`}
        ref={snackBarElement}
        classes={{ root: classes.undoButtonRoot, label: classes.undoButtonLabel }}
        color="secondary"
        onClick={handleUndo}
      >
        {formatMessage(messages.undo)}
      </Button>
      <span className={classes.undoButtonShortcut}>&#160;(&#8984;+Z)</span>
    </>
  );

  return (
    <>
      <Snackbar
        key={id}
        autoHideDuration={autoCloseTime}
        open={open}
        onClose={handleOnClose}
        onExited={handleExited}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <SnackbarContent
          classes={{
            root: classes.snackBarContainer,
            action: classes.snackBarContainerAction,
            message: classes.snackbarContentWrapper
          }}
          message={formatMessage(messages.chatDeleted)}
          action={actions}
        />
      </Snackbar>
    </>
  );
};

ChatHistoryDeleteItemSnackBar.propTypes = {
  intl: intlShape.isRequired,
  onUndo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedThread: PropTypes.object.isRequired,
  autoCloseTime: PropTypes.number.isRequired
};

export default injectIntl(memo(ChatHistoryDeleteItemSnackBar));
