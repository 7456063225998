/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Base definition of environment properties. These can be overriden by env-specific definitions.
 *
 * @author Vignesh Selvam
 */
const perf = {
  IES_BASE_URL: 'https://login-ppe.pearson.com/v1/piapi-pqa',
  MARIN_API_BASE_URL: 'https://plus-ppe.pearson.com/marin/api/1.0',
  MOJO_DOMAIN: 'plus-ppe.pearson.com',
  ETEXT_DOMAIN: 'plus-ppe.pearson.com',
  PMC_SIGNIN_URL: 'https://pearson-perf.pearson.com/store/en-us/pearsonplus/logout?sessionTimedOut=false&consoleHomePageUrl=https://pearson-perf.pearson.com/store/en-us/pearsonplus/login',
  SSI_PMC_SIGNIN_URL: 'https://pearson-perf.pearson.com/store/en-us/pearsonplus/login'
};
export default perf;
