/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChapterTopics.jsx
 * @author Sarathkumar Venkatachalam
 */

import React, { useState, useRef, useEffect } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button
} from '@material-ui/core';
import messages from '../../defaultMessages';
import ThemeConstants from '../../Common/ThemeConstants';
import { eventAction, gaEvents } from '../../analytics/analytics';
import { GPT_EVENTS } from '../../common/constants';

const styles = () => ({
  generateTopicsContainer: {
    border: 'none',
    padding: 0,
    margin: '10px 0px',
    borderRadius: '18px',
    boxSizing: 'border-box'
  },
  optionDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    margin: 0,
    marginBottom: 8
  },
  sendStyle: {
    padding: '8px 24px',
    width: 88,
    height: 40,
    background: (props) => `${ThemeConstants[props.masterTheme][props.theme].bgColor} !important`,
    border: '1px solid #bcc1cb',
    borderRadius: '24px',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    marginTop: 8,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  defaultRadio: {
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].defaultRadio}) center no-repeat !important`
  },
  selectedStyle: {
    // color: props => `${ThemeConstants[props.masterTheme][props.theme].selectedRadioBtnColor} !important`
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].radioSelectedBG}) center no-repeat !important`
  },
  radioRootStyle: {
    padding: 5,
    alignSelf: 'flex-start',
    marginTop: 0,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].radioBtnColor,
    '&:hover': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&:focus': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&.Mui-focusVisible': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  topicStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    lineHeight: '24px'
  },
  topicBottomStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    paddingTop: 8,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    letterSpacing: '0.24px',
    lineHeight: '24px'
  },
  labelStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    padding: '2.5px 0px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  selectedLabel: {
    fontWeight: '400 !important',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  labelRootStyle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  disabledButtonStyle: {
    opacity: '.5',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  fbold: {
    fontWeight: 'bold'
  }
});

const ChapterTopics = ({
  classes, onSend, topics, requestId, intl, optionSelected, intentSelected, disableOptions, handleGAEvents
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const { formatMessage } = intl;
  const handleChange = (event) => {
    const currentValue = event.target.value;
    setSelectedOption(currentValue);
  };
  const optionRef = useRef(null);
  useEffect(() => {
    if (optionRef && optionRef.current) {
      optionRef.current.focus();
    }
  }, []);

  const handleSend = () => {
    gaEvents(handleGAEvents, {
      event_label: null,
      event_value: null,
      event_action: eventAction.clickSend,
      command_source_code: 'click',
      location_in_app: GPT_EVENTS.GPT
    });
    const filteredTopics = topics.find((topic) => topic.id === selectedOption) || {};
    onSend(filteredTopics.pageIds, optionSelected, intentSelected, filteredTopics.title, filteredTopics.id, filteredTopics.type, filteredTopics.type, requestId);
  };

  const getLabel = (type, title) => (
    <span>
      <span className={classes.fbold}>
        {type === 'chapter' ? 'Chapter' : 'Section'}
        :
        {' '}
      </span>
      {title}
    </span>
  );

  return (
    <div>
      <fieldset className={classes.generateTopicsContainer}>
        <legend id="chapterTopicsTitle" className={classes.topicStyle}>
          {formatMessage(messages.chapterTopicTitle)}
        </legend>
        <RadioGroup role="none" name="topics" value={selectedOption} onChange={handleChange}>
          {
            topics.map((item, index) => (
              <FormControlLabel
                value={item.id}
                control={(
                  <Radio
                    inputRef={(index === 0) ? optionRef : null}
                    checked={selectedOption === item.id}
                    disabled={disableOptions}
                    classes={{
                      root: classes.radioRootStyle
                    }}
                    icon={<span className={classes.defaultRadio} />}
                    checkedIcon={<span className={classes.selectedStyle} />}
                  />
                )}
                label={getLabel(item.type, item.title)}
                classes={{
                  root: classes.labelRootStyle,
                  label: selectedOption === item.id ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
                }}
                key={`topic_option_${index}`}
              />
            ))
        }
        </RadioGroup>
        <Button
          className={classes.sendStyle}
          onClick={handleSend}
          disabled={!selectedOption || disableOptions}
          classes={{ disabled: classes.disabledButtonStyle }}
        >
          {formatMessage(messages.send)}
        </Button>
      </fieldset>
    </div>
  );
};

ChapterTopics.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  handleGAEvents: PropTypes.func,
  topics: PropTypes.array,
  requestId: PropTypes.string,
  optionSelected: PropTypes.string,
  disableOptions: PropTypes.bool,
  intentSelected: PropTypes.string
};

ChapterTopics.defaultProps = {
  topics: [],
  requestId: '',
  handleGAEvents: () => {},
  optionSelected: '',
  disableOptions: false,
  intentSelected: ''
};

export default withStyles(styles)(injectIntl(ChapterTopics));
