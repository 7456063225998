/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import { types, applySnapshot } from 'mobx-state-tree';
import { toJS } from 'mobx';
import Framework from '../framework/Framework';
import * as constants from '../common/constants';

/**
   * A Store to handle DeviceList details.
   *
   * @author Vijaykani Elangovan
   */
export const DeviceDetail = types.model(
  'DeviceDetail',
  {
    browserName: types.optional(types.string, ''),
    deviceId: types.optional(types.string, ''),
    deviceName: types.optional(types.string, ''),
    osName: types.optional(types.string, ''),
    lastLoginDate: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    isNativeMobile: types.maybeNull(types.boolean),
    deviceType: types.maybeNull(types.string)
  }
);

export const ViolationStatus = types.model(
  'ViolationStatus',
  {
    isWebViolation: types.maybeNull(types.boolean),
    isWebViolationLimitExceeded: types.maybeNull(types.boolean)
  }
);

export const ThreatDetail = types.model(
  'ThreatDetail',
  {
    threatLevel: types.maybeNull(types.number),
    serverUpdatedDateTime: types.maybeNull(types.string),
    hasUserWarningDisplayed: types.maybeNull(types.boolean)
  }
);

const Device = types.model(
  'Device',
  {
    limit: types.maybeNull(types.number),
    limitReached: types.maybeNull(types.boolean),
    devices: types.array(DeviceDetail),
    isSwapAlreadyDone: types.maybeNull(types.boolean),
    isLoading: types.maybeNull(types.boolean),
    violationStatus: types.maybeNull(ViolationStatus),
    primaryEmail: types.maybeNull(types.string),
    threatDetail: types.maybeNull(ThreatDetail)
  }
).actions((self) => ({

  set(data) {
    applySnapshot(self, data);
    self.setLoader(false);
  },
  setData(data) {
    const devices = { ...toJS(self), ...data };
    applySnapshot(self, devices);
    self.setLoader(false);
  },
  setError() {
    const device = toJS(self);
    device.isSwapAlreadyDone = true;
    applySnapshot(self, device);
    Framework.getEventManager().publish(constants.DEVICE_SWAP_SUCCESS, device);
    self.setLoader(false);
  },
  swapSuccess() {
    const device = toJS(self);
    Framework.getEventManager().publish(constants.DEVICE_SWAP_SUCCESS, device);
    self.setLoader(false);
  },
  setLoader(status) {
    const device = toJS(self);
    device.isLoading = status;
  }
}));

export default Device;
