/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Italy Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Prosegui',
  'browser.check.DONT_SHOW_AGAIN': 'Non mostrare più',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'sistemi operativi e browser supportati',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Il tuo browser, <<name>>, è troppo vecchio',
  'browser.check.BROWSER_MESSAGE_DESC': 'Per la migliore esperienza, seleziona uno dei browser più recenti:',
  'browser.check.BROWSER_SKIP_NOW': 'Salta per ora',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Informazioni sui browser supportati',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Il tuo browser, <<name>>, non è supportato',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Per la migliore esperienza, seleziona uno dei browser supportati.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Il tuo sistema operativo, <<name>>, non è supportato',
  'browser.check.OS_UNSUPPORTED_DESC': 'Benché il prodotto possa funzionare senza problemi, considera che ogni problema'
    + ' che riscontri può essere risolto provando a usare un dispositivo che esegue un <<link>>',
  'errors.GENERIC_TITLE': 'Errore di connessione al server',
  'errors.GENERIC_DESC': 'Spiacente, il sistema non è accessibile. Riprova più tardi o contatta l’Assistenza.',
  'errors.HTTP_400_TITLE': 'Errore di connessione al server',
  'errors.HTTP_400_DESC': 'Si è verificato un errore durante l’elaborazione della tua richiesta.',
  'errors.HTTP_401_TITLE': 'Ti sei autenticato?',
  'errors.HTTP_401_DESC': 'Impossibile ottenere i dati, poiché la tua sessione di login potrebbe essere scaduta.',
  'errors.HTTP_403_TITLE': 'Non autorizzato',
  'errors.HTTP_403_DESC': 'Non puoi accedere a questa risorsa.',
  'errors.HTTP_888_TITLE': 'Il server ha risposto troppo tardi',
  'errors.HTTP_888_DESC': 'Si è verificato un errore durante l’elaborazione della tua richiesta.'
};

export default RESOURCES;
