/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Traditional Chinese Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': '繼續',
  'browser.check.DONT_SHOW_AGAIN': '不再顯示',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': '支援的作業系統和網路瀏覽器',
  'browser.check.BROWSER_MESSAGE_TITLE': '您的瀏覽器 <<name>> 版本過低',
  'browser.check.BROWSER_MESSAGE_DESC': '為獲得最佳體驗，請選擇以下瀏覽器的最新版本：',
  'browser.check.BROWSER_SKIP_NOW': '暫時跳過',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': '關於支持的瀏覽器',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': '我們不支持您的瀏覽器 <<name>>',
  'browser.check.BROWSER_UNSUPPORTED_DESC': '為獲得最佳體驗，請使用一個我們支援的瀏覽器。',
  'browser.check.OS_UNSUPPORTED_TITLE': '我們不支援您的作業系統 <<name>>',
  'browser.check.OS_UNSUPPORTED_DESC': '產品可能正常運作，但請注意，您遇到的任何問題都可透過一台執行 <<link>> 的裝置解決。',
  'errors.GENERIC_TITLE': '伺服器錯誤',
  'errors.GENERIC_DESC': '很抱歉，系統崩潰了。請稍後重試或聯絡技術支援團隊。',
  'errors.HTTP_400_TITLE': '伺服器錯誤',
  'errors.HTTP_400_DESC': '在處理您的請求時出現錯誤。',
  'errors.HTTP_401_TITLE': '您是否已登入？',
  'errors.HTTP_401_DESC': '我們無法提取資料，可能是因為您的登入工作階段已過期。',
  'errors.HTTP_403_TITLE': '未授權',
  'errors.HTTP_403_DESC': '您無權存取該資源。',
  'errors.HTTP_888_TITLE': '伺服器回應逾時',
  'errors.HTTP_888_DESC': '在處理您的請求時出現錯誤。'
};

export default RESOURCES;
