/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * French Language Resource file.
 *
 * @author Hari Gangadharan
 */

const RESOURCES = {
  'browser.check.DONT_SHOW_AGAIN': 'Ne plus me montrer ce message',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'système d\'exploitation et navigateur Web appuyés',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Votre navigateur <<name>> est obsolète',
  // eslint-disable-next-line max-len
  'browser.check.BROWSER_MESSAGE_DESC': 'Pour avoir la meilleure expérience, veuillez sélectionner l\'un des tous derniers navigateurs :',
  'browser.check.BROWSER_SKIP_NOW': 'Passer pour l\'instant"',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'À propos des navigateurs supportés',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Votre navigateur <<name>> n\'est pas pris en charge',
  // eslint-disable-next-line max-len
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Pour avoir la meilleure expérience, veuillez sélectionner l\'un des navigateurs supportés : ',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Votre système d\'exploitation <<name>> n\'est pas pris en charge',
  // eslint-disable-next-line max-len
  'browser.check.OS_UNSUPPORTED_DESC': 'Bien que le produit puisse fonctionner sans aucun problème, veuillez noter que tout problème que vous pouvez rencontrer peut être résolu en essayant un appareil qui opère un <<link>>.',
  'common.OK': 'Ok',
  'common.CONTINUE': 'Continuer',
  'errors.GENERIC_TITLE': 'Erreur du serveur',
  // eslint-disable-next-line max-len
  'errors.GENERIC_DESC': 'Désolé, notre système est éteint. Veuillez réessayer plus tard ou communiquer avec l\'équipe de soutien. ',
  'errors.HTTP_400_TITLE': 'Erreur du serveur',
  'errors.HTTP_400_DESC': 'Une erreur s\'est produite lors du traitement de votre demande. ',
  'errors.HTTP_401_TITLE': 'Êtes-vous connecté ?',
  'errors.HTTP_401_DESC': 'Nous n\'avons pu récupérer les données puisque votre dernière session peut avoir expiré.',
  'errors.HTTP_403_TITLE': 'Non autorisé',
  'errors.HTTP_403_DESC': 'Vous n\'avez pas accès à cette ressource.',
  'errors.HTTP_888_TITLE': 'Le serveur est trop lent à répondre',
  'errors.HTTP_888_DESC': 'Une erreur s\'est produite lors du traitement de votre demande.'
};

export default RESOURCES;
