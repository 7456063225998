/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatHistorySearch.jsx
 * @author Sarathkumar Venkatachalam
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  InputBase,
  Paper
} from '@material-ui/core';
import messages from '../../defaultMessages';
import ChatHistoryStyles from './ChatHistoryStyles';
import HistorySearchIcon from '../../Common/icons/HistorySearchIcon';
import HistoryCloseIcon from '../../Common/icons/HistoryCloseIcon';

const ChatHistorySearch = ({
  intl,
  classes,
  masterTheme,
  theme,
  onSearch,
  disabled
}) => {
  const { formatMessage } = intl;
  const [searchKey, setSearchKey] = useState('');
  const handleChange = (event) => {
    if (event && event.target) {
      setSearchKey(event.target.value);
      onSearch(event.target.value);
    }
  };
  const handleClear = () => {
    setSearchKey('');
    onSearch('');
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Paper component="form" onSubmit={handleSubmit} className={classes.historySearchRoot}>
      <div className={classes.historySearchIconButton}>
        <HistorySearchIcon masterTheme={masterTheme} theme={theme} />
      </div>
      <InputBase
        className={classes.historySearchInput}
        placeholder={formatMessage(messages.historySearchPlaceHolder)}
        inputProps={{ 'aria-label': formatMessage(messages.historySearchPlaceHolder) }}
        onChange={handleChange}
        value={searchKey}
        disabled={disabled}
      />
      {
        searchKey && (
          <IconButton
            id="history_search_close"
            onClick={handleClear}
            className={classes.closeSearchStyle}
          >
            <HistoryCloseIcon theme={theme} masterTheme={masterTheme} />
          </IconButton>
        )
      }
    </Paper>
  );
};

ChatHistorySearch.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  masterTheme: PropTypes.string,
  theme: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

ChatHistorySearch.defaultProps = {
  masterTheme: 'plus',
  theme: 'default',
  disabled: false
};

export default withStyles(ChatHistoryStyles)(injectIntl(ChatHistorySearch));
