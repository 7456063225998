/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import UserService from './UserService';
import DeviceService from './DeviceService';
import BrowserCheckService from './BrowserCheckService';
import IntUserService from './IntUserService';
import IntUserLogoutService from './IntUserLogoutService';
import PreferenceService from './PreferenceService';

export default function (browserCheck, mojoMVP, subscriptionFilter, enableGetDeviceList) {
  (new UserService(mojoMVP, subscriptionFilter, enableGetDeviceList)).start();
  (new DeviceService()).start();
  (new IntUserService()).start();
  (new IntUserLogoutService()).start();
  if (mojoMVP) {
    (new PreferenceService()).start();
  }
  if (browserCheck) {
    (new BrowserCheckService()).start();
  }
}
