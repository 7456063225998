/* eslint-disable */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file BaseParser.js
 * @author Sarathkumar Venkatachalam
 */

export default class BaseParser {
  constructor() {
    if (new.target === BaseParser) {
      throw new TypeError('Cannot construct BaseParser instances directly');
    }
  }

  // This will be overridden by derived classes
  static parse(domElement) {
    throw new Error('parse method must be implemented');
  }
}
