/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import ulog from 'ulog';
import stores from '../stores';
import * as constants from '../common/constants';
import Framework from '../framework/Framework';
import ApiState from '../models/ApiState';

const log = ulog('BaseService');
/**
 * An Abstract Base Service which is extended by all other services. This service allows call to be queued
 * until authentication is complete.
 *
 * @author Hari Gangadharan
 */
export default class BaseService {
  constructor(eventName, statusStoreName, noAuth = false) {
    this.eventName = eventName;
    this.statusStoreName = statusStoreName;
    this.noAuth = noAuth;
  }

  // eslint-disable-next-line class-methods-use-this
  getClient = (name) => Framework.getClientManager().get(name);

  start() {
    if (this.statusStoreName) {
      Framework.getStoreRegistry().register({
        name: this.statusStoreName,
        saveLocally: false,
        initialState: {
          isPending: false,
          isError: false
        },
        spec: ApiState
      });
    }

    EventBus.on(this.eventName, (event) => {
      // if authenticated call the service immediately
      if (stores.user.isAuthenticated || this.noAuth) {
        this.callHandleEvent(this.eventName, event);
      } else {
        // Otherwise wait until Auth completed event
        EventBus.on(constants.AUTH_VERIFIED, () => {
          // auth complete - now we can handle event
          this.callHandleEvent(this.eventName, event);
        });
      }
    });
  }

  callHandleEvent(eventName, event) {
    log.debug(eventName, event);
    if (this.statusStoreName) {
      Framework.getStoreRegistry().getStore(this.statusStoreName).setPending();
    }
    Promise.resolve(this.handleEvent(eventName, event)).then(
      (response) => {
        this.handleResponse(response, event);
        if (this.statusStoreName) {
          Framework.getStoreRegistry().getStore(this.statusStoreName).setFulfilled();
        }
      },
      (error) => {
        if (this.statusStoreName) {
          if (error) {
            Framework.getStoreRegistry().getStore(this.statusStoreName).setError(error);
          }
          this.handleError(error, event);
        }
      }
    );
  }

  /**
   * Sub classes override this method.
   */
  /* eslint-disable class-methods-use-this, no-unused-vars */
  handleEvent(eventName, event) {
  }

  handleResponse(response, event) {
  }

  handleError(error, event) {
  }
  /* eslint-enable class-methods-use-this, no-unused-vars */
}
