/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Japanese Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': '続ける',
  'browser.check.DONT_SHOW_AGAIN': '再表示しない',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': '対応するOSとウェブブラウザ',
  'browser.check.BROWSER_MESSAGE_TITLE': 'ご使用のブラウザ<<name>>は古いものです',
  'browser.check.BROWSER_MESSAGE_DESC': 'すぐれたエクスペリエンスを得るには、最新のブラウザのいずれかを選択してください。',
  'browser.check.BROWSER_SKIP_NOW': '今はスキップする',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'サポート対象ブラウザ',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'ご使用のブラウザ<<name>>はサポートされていません',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'すぐれたエクスペリエンスを得るには、サポート対象ブラウザのいずれかを選択してください。',
  'browser.check.OS_UNSUPPORTED_TITLE': 'ご使用のOS<<name>>はサポートされていません',
  'browser.check.OS_UNSUPPORTED_DESC': '製品は問題なく動作する可能性がありますが、問題が発生した場合は、<<link>>を実行しているデバイスを試すことで解決する場合があることに注意してください。',
  'errors.GENERIC_TITLE': 'サーバーエラー',
  'errors.GENERIC_DESC': '申し訳ありません。現在システムがダウンしています。後でもう一度お試しいただくか、サポートにご連絡ください。',
  'errors.HTTP_400_TITLE': 'サーバーエラー',
  'errors.HTTP_400_DESC': 'リクエストの処理中にエラーが発生しました。',
  'errors.HTTP_401_TITLE': 'ログインしていますか？',
  'errors.HTTP_401_DESC': 'ログインセッションの有効期限が切れている可能性があるため、データを取得できませんでした。',
  'errors.HTTP_403_TITLE': '無許可',
  'errors.HTTP_403_DESC': 'このリソースにアクセスできません。',
  'errors.HTTP_888_TITLE': 'サーバーが遅延しており応答しません',
  'errors.HTTP_888_DESC': 'リクエストの処理中にエラーが発生しました。'
};

export default RESOURCES;
