/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import BaseService from './BaseService';
import Framework from '../framework/Framework';

import * as constants from '../common/constants';
import env from '../common/env';

const clientManager = Framework.getClientManager();
/**
 * Internal User Service gets the user info from Internal IDP.
 *
 * @author Hari Gangadharan
 */
export default class IntUserService extends BaseService {
  constructor() {
    super(constants.INT_USER_PROFILE_REQUESTED);
    clientManager.add({
      name: 'intAuthService',
      authHeader: 'myCloudProxySession',
      baseUrl: env.get('INT_BASE_URL'),
      timeout: env.get('INT_TIMEOUT'),
      addCorrelationId: false
    });
  }

  handleEvent(eventName, event) { // eslint-disable-line class-methods-use-this
    const userProfileClient = clientManager.get('intAuthService');
    if (event.userId) {
      userProfileClient.get(`/users/${event.userId}`).then(
        (response) => {
          const user = {
            id: response.data.username,
            userName: response.data.username,
            firstName: response.data.displayName[0],
            lastName: '',
            initials: response.data.displayName[0].slice(0, 1) + response.data.givenName[0].slice(0, 1),
            email: response.data.mail[0]
          };
          Framework.getStoreRegistry().getStore('user').set(user);
        }
      );
    } else {
      userProfileClient.post('/sessions/?_action=validate', { headers: { 'Content-Type': 'application/json' } }).then(
        (response) => {
          if (response.data.valid) {
            EventBus.publish(constants.INT_USER_PROFILE_REQUESTED, { userId: response.data.uid });
          } else {
            const location = window.location.toString();
            EventBus.publish(constants.LOGIN_REQUESTED, { location });
          }
        }
      );
    }
  }
}
/*
https://identity-internal-test.pearson.com/auth/json/pearson/sessions/?_action=validate
https://identity-internal-test.pearson.com/auth/json/pearson/sessions?_action=validate
*/
