/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Korean Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': '계속하기',
  'browser.check.DONT_SHOW_AGAIN': '앞으로 이것을 표시하지 마세요',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': '지원되는 운영 체제 및 웹 브라우저',
  'browser.check.BROWSER_MESSAGE_TITLE': '귀하의 브라우저 <<name>>은(는) 최신 상태가 아닙니다',
  'browser.check.BROWSER_MESSAGE_DESC': '최상의 경험을 위해 최신 브라우저 중 하나를 선택하세요',
  'browser.check.BROWSER_SKIP_NOW': '지금은 건너뛰기',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': '지원되는 브라우저 정보',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': '귀하의 브라우저인 <<name>>은(는) 지원되지 않습니다',
  'browser.check.BROWSER_UNSUPPORTED_DESC': '지원되는 브라우저 중에 하나를 선택하여 최상의 경험을 누리세요.',
  'browser.check.OS_UNSUPPORTED_TITLE': '귀하의 운영 체제인 <<name>>은(는) 지원되지 않습니다',
  'browser.check.OS_UNSUPPORTED_DESC': '제품이 문제없이 작동하겠지만, 혹 문제가 발생하면 장치에서 <<link>>를 실행하여 해결할 수 있습니다',
  'errors.GENERIC_TITLE': '서버 오류',
  'errors.GENERIC_DESC': '죄송합니다, 시스템이 다운되었습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
  'errors.HTTP_400_TITLE': '서버 오류',
  'errors.HTTP_400_DESC': '귀하의 요청을 처리하는 동안 오류가 발생했습니다.',
  'errors.HTTP_401_TITLE': '로그인이 되어 있나요?',
  'errors.HTTP_401_DESC': '로그인 세션이 만료되었을 수 있으므로 데이터를 가져올 수 없습니다.',
  'errors.HTTP_403_TITLE': '승인되지 않음',
  'errors.HTTP_403_DESC': '이 리소스에 대한 액세스 권한이 없습니다.',
  'errors.HTTP_888_TITLE': '서버가 매우 느려 응답할 수 없습니다',
  'errors.HTTP_888_DESC': '귀하의 요청을 처리하는 동안 오류가 발생했습니다.'
};

export default RESOURCES;
