/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Dutch Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'browser.check.DONT_SHOW_AGAIN': 'Niet meer weergeven',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'ondersteunend besturingssysteem en geschikte browser',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Uw browser <<name>> is verouderd',
  'browser.check.BROWSER_MESSAGE_DESC': 'Voor de beste ervaring kiest u uit de onderstaande browsers:',
  'browser.check.BROWSER_SKIP_NOW': 'Nu even overslaan',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Over geschikte browsers',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Uw browser <<name>> wordt niet ondersteund',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Voor de beste ervaring kiest u uit de ondersteunde browsers:',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Uw besturingssysteem <<name>> wordt niet ondersteund',
  // eslint-disable-next-line max-len
  'browser.check.OS_UNSUPPORTED_DESC': 'Het product kan probleemloos werken, maar wees u ervan bewust dat als u problemen ervaart, deze kunnen worden opgelost door een <<link>> uit te voeren.',
  'common.OK': 'Ok',
  'common.CONTINUE': 'Doorgaan',
  'errors.GENERIC_TITLE': 'Serverfout',
  'errors.GENERIC_DESC': 'Sorry, ons systeem ligt eruit. Probeer het later nog eens.',
  'errors.HTTP_400_TITLE': 'Serverfout',
  'errors.HTTP_400_DESC': 'Er is een fout opgetreden bij het verwerken van uw verzoek.',
  'errors.HTTP_401_TITLE': 'Bent u ingelogd?',
  'errors.HTTP_401_DESC': 'We konden uw gegevens niet ophalen omdat uw sessie mogelijk is verlopen.',
  'errors.HTTP_403_TITLE': 'Ongeautoriseerd',
  'errors.HTTP_403_DESC': 'U heeft geen toegang tot deze bron.',
  'errors.HTTP_888_TITLE': 'Server heeft er te lang over gedaan om te antwoorden',
  'errors.HTTP_888_DESC': 'Er is een fout opgetreden bij het verwerken van uw verzoek.'
};

export default RESOURCES;
