/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file BulletIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  bulletIcon: {
    width: 24,
    height: 24
  }
});
const BulletIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 24 24"
    classes={{
      root: classes.expandIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8 17.76C23.4627 17.76 24 18.2973 24 18.96C24 19.6227 23.4627 20.16 22.8 20.16H7.2C6.53726 20.16 6 19.6227 6 18.96C6 18.2973 6.53726 17.76 7.2 17.76H22.8ZM22.8 10.56C23.4627 10.56 24 11.0973 24 11.76C24 12.4227 23.4627 12.96 22.8 12.96H7.2C6.53726 12.96 6 12.4227 6 11.76C6 11.0973 6.53726 10.56 7.2 10.56H22.8ZM22.8 3.36C23.4627 3.36 24 3.89726 24 4.56C24 5.22274 23.4627 5.76 22.8 5.76H7.2C6.53726 5.76 6 5.22274 6 4.56C6 3.89726 6.53726 3.36 7.2 3.36H22.8Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
    <path
      d="M3 4.5C3 5.32843 2.32843 6 1.5 6C0.671573 6 0 5.32843 0 4.5C0 3.67157 0.671573 3 1.5 3C2.32843 3 3 3.67157 3 4.5Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
    <path
      d="M3 11.7C3 12.5284 2.32843 13.2 1.5 13.2C0.671573 13.2 0 12.5284 0 11.7C0 10.8716 0.671573 10.2 1.5 10.2C2.32843 10.2 3 10.8716 3 11.7Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
    <path
      d="M3 18.9C3 19.7284 2.32843 20.4 1.5 20.4C0.671573 20.4 0 19.7284 0 18.9C0 18.0716 0.671573 17.4 1.5 17.4C2.32843 17.4 3 18.0716 3 18.9Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>
);

BulletIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

BulletIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(BulletIcon);
