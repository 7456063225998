/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import env from '../../common/env';
import Utils from '../../utils/Utils';
import * as constants from '../../common/constants';

/**
 * Authentication Strategy for authenticating the user against Pearson Internal Auth (My cloud).
 *
 * @author Hari Gangadharan
 */
export default class IntAuthStrategy {
  constructor(authService) {
    this.isInitialized = false;
    this.authService = authService;
  }

  login(successUrl) { // eslint-disable-line class-methods-use-this
    window.location.href = `${env.get('INT_LOGIN_URL')}?url=${successUrl}`;
  }

  // eslint-disable-next-line class-methods-use-this
  logout = () => {
    EventBus.publish(constants.INT_USER_LOGOUT_REQUESTED);
  };

  /**
   */
  init(onInit) {
    if (!this.isInitialized) {
      this.isInitialized = true;
      const cookies = Utils.parseCookie();
      if (cookies.myCloudProxySession) {
        this.authService.setAuthenticated('', cookies.myCloudProxySession);
      } else {
        this.login(window.location.href);
      }
      onInit();
    }
  }
}
