/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { Route } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object().shape({
  exact: yup.boolean().required(),
  render: yup.mixed().required()
}).required();

/**
 * ModuleBuilder builds the Modules in the application based on the module spec.
 *
 * @author Hari Gangadharan
 */
export default class ModuleBuilder {
  constructor(storeRegistry) {
    this.modules = [];
    this.storeRegistry = storeRegistry;
  }

  add(moduleSpec) {
    schema.validateSync(moduleSpec);
    if (typeof moduleSpec.render !== 'function') {
      throw new Error('The render method needed in the Module Spec');
    }
    if (typeof moduleSpec.path !== 'string' && !moduleSpec.path.length) {
      throw new Error('The path should be an array or string in the Module Spec');
    }
    this.modules.push(moduleSpec);

    return this;
  }

  build() {
    return this.modules.map((module, key) => {
      if (module.services && module.services.length > 0) {
        module.services.forEach(
          (service) => service.start()
        );
      }
      const storeRegistry = this.storeRegistry;
      if (module.stores && module.stores.length > 0) {
        module.stores.forEach(
          (store) => storeRegistry.register(store)
        );
      }

      return (
        <Route
          key={key}
          path={module.path}
          exact={module.exact}
          render={module.render}
        />
      );
    });
  }
}
