/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import axios from 'axios';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required(),
  baseUrl: yup.string().required(),
  timeout: yup.number().required().min(200).max(60000),
  addCorrelationId: yup.boolean(),
  authHeader: yup.mixed().oneOf(['authorization', 'x-authorization', 'myCloudProxySession']),
  tokenPrefix: yup.string()
});

/**
 * ClientManager helps create and maintain the clients in the application. Clients are used to make API calls to the
 * backend. It is built according to the predefined spec.
 *
 * @author Hari Gangadharan
 */
export default class ClientManager {
  constructor(authService) {
    this.clients = new Map();
    this.authService = authService;
  }

  add(clientSpec) {
    schema.validateSync(clientSpec);
    if (this.clients.has(clientSpec.name)) {
      throw new Error(`${clientSpec.name} duplicate definition attempted`);
    }
    const client = axios.create({
      baseURL: clientSpec.baseUrl,
      timeout: clientSpec.timeout,
      headers: clientSpec.headers || {}
    });
    const addCorrelationId = (typeof clientSpec.addCorrelationId === 'undefined') ? true : clientSpec.addCorrelationId;
    const tokenPrefix = (typeof clientSpec.tokenPrefix === 'undefined') ? '' : clientSpec.tokenPrefix;
    const authHeader = clientSpec.authHeader ? clientSpec.authHeader : '';
    this.authService.intercept(authHeader, tokenPrefix, client, addCorrelationId);
    this.clients.set(clientSpec.name, client);

    return this;
  }

  get(name) {
    return this.clients.get(name);
  }
}
