/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { inject } from 'mobx-react';

import * as shapes from '../shapes';
import ErrorCard from './ErrorCard';
import history from '../history';

const ErrorAction = ({ language, onClick, textKey }) => (
  <Button
    variant="outlined"
    color="primary"
    size="medium"
    onClick={onClick}
  >
    <FormattedMessage {...language.getText(textKey)} />
  </Button>
);

ErrorAction.propTypes = {
  language: shapes.language.isRequired,
  onClick: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired
};

/**
 * A component that displays errors from an Error Code.
 *
 * @author Hari Gangadharan
 */
const ErrorDisplay = (
  {
    errorCode, language, url, textKey, langKeyPrefix
  }
) => (
  <ErrorCard
    title={(
      <FormattedMessage
        {...language.getText(`${langKeyPrefix}.${errorCode}_TITLE`)}
      />
    )}
    body={(
      <div>
        <FormattedMessage
          {...language.getText(`${langKeyPrefix}.${errorCode}_DESC`)}
        />
      </div>
    )}
    actions={
      url ? (
        <ErrorAction
          language={language}
          onClick={() => (url.startsWith('http') ? window.location.replace(url) : history.replace(url))}
          url={url}
          textKey={textKey || 'common.OK'}
        />
      ) : null
    }
  />
);

ErrorDisplay.propTypes = {
  language: shapes.language.isRequired,
  errorCode: PropTypes.string.isRequired,
  url: PropTypes.string,
  textKey: PropTypes.string,
  langKeyPrefix: PropTypes.string
};

ErrorDisplay.defaultProps = {
  url: null,
  textKey: null,
  langKeyPrefix: 'errors'
};

export default inject('language')(ErrorDisplay);
