/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */

/**
 *  PEARSON PROPRPIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Quiz.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useRef, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  // IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  // Tooltip,
  Link,
  ClickAwayListener,
  Typography
} from '@material-ui/core';
// import LikeIcon from '../../Common/icons/LikeIcon';
// import LikeIconFilled from '../../Common/icons/LikeIconFilled';
// import DisLikeIcon from '../../Common/icons/DisLikeIcon';
// import DisLikeIconFilled from '../../Common/icons/DisLikeIconFilled';
import { Utilities } from '@aquila/core';
import messages from '../../defaultMessages';
import PositiveFeedback from '../../Components/FeedbackComponent/PositiveFeedback';
import NegativeFeedback from '../../Components/FeedbackComponent/NegativeFeedback';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ThemeConstants from '../../Common/ThemeConstants';
import * as constants from '../../Common/constants';
import { clickThumbsGAEvent } from '../../analytics/analyticsEvents';
import globalContext from '../../Context/globalContext';

const styles = () => ({
  quizWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  quizContainer: {
    border: 'none',
    padding: '0px',
    minHeight: 184,
    maxHeight: 'fit-content',
    // background: '#e8eefa',
    borderRadius: '18px',
    boxSizing: 'border-box',
    width: '100%'
  },
  quizContainer__question__title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor,
    margin: 0,
    marginBottom: 8
  },
  quizContainer__question__description: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor,
    margin: 0,
    marginBottom: 8
  },
  quizContainer__options__radioButton: {
    width: 20,
    minWidth: 20,
    height: 20,
    margin: 0,
    accentColor: 'black'
  },
  quizContainer__options__label__selected: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  quizContainer__options__label__correctAnswer: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`,
    opacity: 'initial !important',
    fontWeight: '400 !important'
  },
  quizContainer__options__label__wrongAnswer: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`,
    opacity: 'initial !important',
    fontWeight: '400 !important',
    padding: '2.5px 0'
  },
  quizContainer__options__label__default: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`,
    padding: '2.5px 0px',
    fontWeight: '400 !important'
  },
  quizContainer__actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 8
  },
  quizContainer__actions__buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24
  },
  quizContainer__actions__buttons__skip: {
    width: 'auto',
    textDecoration: 'underline',
    // width: 34,
    height: 24,
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: '#655591',
    cursor: 'pointer'
  },
  doneAction: {
    textDecoration: 'underline',
    // width: '100%',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: '#655591',
    cursor: 'pointer',
    textAlign: 'right'
  },
  quizContainer__actions__buttons__next: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',
    width: 106,
    height: 40,
    background: props => ThemeConstants[props.masterTheme][props.theme].bgColor,
    border: '1px solid #BCC1CB',
    borderRadius: 18,
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 700,
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontFamily: 'TT Commons'
  },
  '@keyframes horizontal-shaking': {
    '0%': { transform: 'translateX(0)' },
    '25%': { transform: 'translateX(10px)' },
    '50%': { transform: 'translateX(-10px)' },
    '75%': { transform: 'translateX(10px)' },
    '100%': { transform: 'translateX(0)' }
  },
  feedbackButton: {
    padding: 8
  },
  defaultRadio: {
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].defaultRadio}) center no-repeat !important`
  },
  correctAnswerStyle: {
    // color: '#008638 !important',
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].correctSelectedBG}) center no-repeat !important`,
    padding: '0px 5px 5px 0px'
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 1,
    display: 'block'
  },
  wrongAnswerStyle: {
    // color: '#DB0020 !important',
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].wrongSelectedBG}) center no-repeat !important`,
    padding: '5px 5px 5px 0px'
  },
  selectedStyle: {
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].radioSelectedBG}) center no-repeat !important`
  },
  radioRootStyle: {
    padding: '5px !important',
    alignSelf: 'flex-start',
    marginTop: 0,
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`,
    '&:hover': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&:focus': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&.Mui-focusVisible': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  labelStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  selectedLabel: {
    fontWeight: '400 !important',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  labelRootStyle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  correctAnswer: {
    color: '#008638 !important',
    display: 'flex'
  },
  wrongAnswer: {
    color: '#DB0020 !important',
    display: 'flex'
  },
  poperStyle: {
    zIndex: 99999
  },
  iconsTooltip: {
    padding: 8,
    width: 'fit-content',
    background: '#161C29',
    borderRadius: '4px',
    color: 'white',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  tooltipArrow: {
    color: '#161C29'
  },
  disabledStyle: {
    opacity: '.5',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  lessContentStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  disableRadioStyle: {
    opacity: 'initial !important'
  },
  disableRadioLabelStyle: {
    opacity: '0.8'
  },
  doneContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginRight: 10
  }
});

const YES = 'yes';

const QuizInteractive = memo((props) => {
  const {
    classes,
    intl,
    quizData: question,
    handleNextQuizQuestion,
    handleGAEvents,
    handleSubmitFeedback,
    pageIds,
    lessContent,
    theme,
    masterTheme,
    disableOptions,
    handleQuizStop,
    currentQuizQstnIndex,
    isLastPage,
    feedbackIconFocusId,
    chapterData,
    totalQuestionCount,
    isExplainCYUMode,
    isExplainCYUModeB,
    responseData,
    initExplainQuizEvent
  } = props;
  const { formatMessage } = intl;
  const targetRef = useRef(null);
  // States
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const optionRef = useRef(null);
  const radioGroupRef = useRef(null);
  const [isClickAway, setIsClickAway] = useState(false);

  useEffect(() => {
    // if (currentQuizQstnIndex === 0 && optionRef && optionRef.current) {
    if (optionRef && optionRef.current && !isExplainCYUModeB) {
      optionRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const target = isExplainCYUModeB && targetRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          initExplainQuizEvent();
          observer.unobserve(target);
        }
      });
    }, { threshold: 0.5 });

    if (target) {
      observer.observe(target);
    }

    // Cleanup function to unobserve when the component unmounts
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  useEffect(() => {
    if (radioGroupRef.current && !isExplainCYUModeB) {
      radioGroupRef.current.querySelectorAll('label').forEach((item) => {
        item.querySelector('span').removeAttribute('aria-disabled');
      });
    }
  }, [radioGroupRef.current]);


  // storing userAnswer for current question and checking if choosen option is correct
  const handleOptionChoose = (event) => {
    const answerIndex = Number(event.target.value);
    setSelectedAnswerIndex(answerIndex); // preserving user selected answers into an array
  };
  // setting dynamic classes to label tag upon validation
  const getClassName = (optionId, isCorrect) => {
    if (isConfirmed && selectedAnswerIndex !== null) {
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() === YES)) {
        return classes.quizContainer__options__label__correctAnswer;
      }
      if ((isCorrect && isCorrect.toLowerCase() === YES)) {
        return classes.quizContainer__options__label__correctAnswer;
      }
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() !== YES)) {
        return classes.quizContainer__options__label__wrongAnswer;
      }
    }
    return isConfirmed ? `${classes.quizContainer__options__label__default} ${classes.disableRadioLabelStyle}` : classes.quizContainer__options__label__default;
  };
  const getRadioStyle = (optionId, checked) => {
    // if (isConfirmed) {
    // if (selectedAnswerIndex === optionId && selectedAnswerIndex === (question && question.answer.id)) {
    //   return classes.correctAnswerStyle;
    // }
    // if (selectedAnswerIndex === optionId && selectedAnswerIndex !== (question && question.answer.id)) {
    //   return classes.wrongAnswerStyle;
    // }
    // if ((question && question.answer.id === optionId)) {
    //   console.log(question.answer.id, optionId);
    //   return classes.correctAnswerStyle;
    // }
    // }
    // eslint-disable-next-line no-nested-ternary
    return isConfirmed ? `${classes.radioRootStyle}` : checked ? `${classes.selectedStyle}` : `${classes.radioRootStyle}`;
  };

  const validateOption = (optionId, isCorrect, distractor) => {
    if (isConfirmed && selectedAnswerIndex !== null) {
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() === YES)) {
        return <span className={classes.correctAnswerStyle}><span className={classes.visuallyHidden}>Correct Answer</span></span>;
      }
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() !== YES)) {
        return <span className={classes.wrongAnswerStyle}><span className={classes.visuallyHidden}>Incorrect Answer</span></span>;
      }
    }
    return <span className={classes.selectedStyle} />;
  };

  // eslint-disable-next-line consistent-return
  const showCorrectOption = (optionId, isCorrect, distractor) => {
    if (isConfirmed && (isCorrect && isCorrect.toLowerCase() === YES) && selectedAnswerIndex !== null) {
      return <span className={classes.correctAnswerStyle}><span className={classes.visuallyHidden}>Correct Answer</span></span>;
    }
    return <span className={classes.defaultRadio} />;
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, constants.GPT_EVENTS.QUESTION, constants.GPT_EVENTS.PRACTICE_TYPE);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, constants.GPT_EVENTS.QUESTION, constants.GPT_EVENTS.PRACTICE_TYPE);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    setIsClickAway(false);
    let payloadInfo = {
      type: 'summary',
      requestId: question.requestId,
      responseId: question.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: ''
    };
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
  };

  const handleSubmitPositiveFeedback = (value) => {
    const payloadInfo = {
      type: 'quiz',
      requestId: question.requestId,
      responseId: question.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: question
    };
    setPostiveFeedbackFlag(true);
    // const additionalFields = {
    //   event_label: null,
    //   event_value: null,
    //   event_action: eventAction.providePositiveFeedback,
    //   input: value
    // };
    // gaEvents(handleGAEvents, additionalFields);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    const payloadInfo = {
      type: 'quiz',
      requestId: question.requestId,
      responseId: question.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      data: question
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  // const handleSkip = () => {
  //   const additionalFields = {
  //     event_label: null,
  //     event_value: null,
  //     command_source_code: 'click',
  //     event_action: eventAction.skipButton
  //   };
  //   gaEvents(handleGAEvents, additionalFields);
  // };

  const handleNextQuestion = (event) => {
    if (event && (event.key === 'Enter' || event.type === 'click')) {
      setIsConfirmed(true);
      const answerIdObj = question.options.find(option => (option.correct_choice && option.correct_choice.toLowerCase() === YES));
      const answerId = answerIdObj && answerIdObj.id;
      const answerDescription = answerIdObj && answerIdObj.distractor;
      const feedbackObj = question.options.find(option => (option.id === selectedAnswerIndex));
      const feedback = feedbackObj && feedbackObj.feedback;
      const commandSourceCode = (event && event.key === 'Enter') ? 'type' : 'click';
      handleNextQuizQuestion(
        question && question.options[selectedAnswerIndex - 1].distractor,
        selectedAnswerIndex,
        answerId,
        feedback,
        answerDescription,
        false,
        pageIds,
        false,
        commandSourceCode,
        chapterData,
        totalQuestionCount,
        isExplainCYUMode,
        responseData
      );
      setTimeout(() => {
        if (radioGroupRef.current) {
          radioGroupRef.current.querySelectorAll('label').forEach((item) => {
            if (item.querySelector('span').hasAttributes('tabindex')) {
              item.querySelector('span').removeAttribute('tabindex');
            }
            if (item.querySelector('span').hasAttributes('aria-disabled')) {
              item.querySelector('span').removeAttribute('aria-disabled');
            }
          });
        }
      }, 10);
    }
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };
  const getQuestionTitle = () => {
    let questionTitle = `Question ${currentQuizQstnIndex + 1}:`;
    if (totalQuestionCount) {
      questionTitle = `Question ${currentQuizQstnIndex + 1} of ${totalQuestionCount}:`;
    }
    return questionTitle;
  };
  console.log('QuizInteractive ***', question?.id);
  return (
    <React.Fragment>
      {lessContent ? (
        <Typography variant="paragraph" className={classes.lessContentStyle}>
          {lessContent}
        </Typography>
      ) : (
        !question && isLastPage ?
          (
            <Typography variant="paragraph" className={classes.lessContentStyle}>
              {formatMessage(messages.noQuizQuestion)}
            </Typography>
          ) :
          (
            <div className={classes.quizWrapper} ref={targetRef}>
              <fieldset className={classes.quizContainer}>
                <legend id="quizQuestions" >
                  {
                    !isExplainCYUMode && (
                      <p className={classes.quizContainer__question__title}>
                        {getQuestionTitle()}
                      </p>
                    )
                  }
                  <p className={classes.quizContainer__question__description}>{`${isExplainCYUModeB ? formatMessage(messages.comprehensiveCheck) : ''}`} {question && question.question_stem && useCallback(Utilities.textFromContent(question.question_stem, globalContext.enableNewMathConfig), [])}</p>
                </legend>
                <RadioGroup
                  innerRef={radioGroupRef}
                  role="none"
                  name={`quiz ${classes.quizContainer__question__description}`}
                  value={selectedAnswerIndex}
                  onChange={handleOptionChoose}
                >
                  {
                    question && question.options && question.options.length > 0 && question.options.map((option, index) => (
                      <FormControlLabel
                        value={option.id}
                        disabled={isConfirmed || disableOptions}
                        control={
                          <Radio
                            inputRef={(index === 0) ? optionRef : null}
                            checked={Number(selectedAnswerIndex) === Number(option.id)}
                            classes={{
                              // checked: getRadioStyle(option.id, true),
                              root: getRadioStyle(option.id),
                              disabled: classes.disableRadioStyle
                            }}
                            icon={showCorrectOption(option.id, option.correct_choice, option.distractor)}
                            checkedIcon={validateOption(option.id, option.correct_choice, option.distractor)}
                          />
                        }
                        label={useCallback(Utilities.textFromContent(option.distractor, globalContext.enableNewMathConfig), [])}
                        classes={{
                          root: classes.labelRootStyle,
                          label: getClassName(option.id, option.correct_choice)
                        }}
                        key={`option_${index}`}
                      />
                    ))}
                </RadioGroup>
                {
                  !isConfirmed && !disableOptions && (
                    <div>
                      <div className={classes.quizContainer__actions}>
                        <div className={classes.quizContainer__actions__buttons}>
                          <Button
                            disabled={!selectedAnswerIndex || isConfirmed || disableOptions}
                            onClick={handleNextQuestion}
                            onKeyDown={handleNextQuestion}
                            className={classes.quizContainer__actions__buttons__next}
                            classes={{
                              disabled: classes.disabledStyle
                            }}
                          >
                            {formatMessage(messages.submit)}
                          </Button>
                          {
                            !isExplainCYUMode && (
                              <Link
                                disabled={isConfirmed || disableOptions}
                                href={false}
                                component="button"
                                variant="body1"
                                className={classes.quizContainer__actions__buttons__skip}
                                onClick={() => {
                                setIsConfirmed(true);
                                handleNextQuizQuestion(null, null, null, null, null, true, pageIds, false, '', chapterData);
                                // handleSkip();
                              }}
                              >
                                {formatMessage(messages.skip)}
                              </Link>
                            )
                          }
                        </div>
                        {/* <div>
                      <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as positive" title="Report as positive" placement="top" arrow>
                        <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={event => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
                          {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} /> : <LikeIcon masterTheme={masterTheme} theme={theme} />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as negative" title="Report as negative" placement="top" arrow>
                        <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={event => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
                          {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} />}
                        </IconButton>
                      </Tooltip>
                    </div> */}
                      </div>
                      {
                        !isExplainCYUMode &&
                          (
                            <div className={classes.doneContainer}>
                              <Link
                                disabled={isConfirmed || disableOptions}
                                href={false}
                                component="button"
                                variant="body1"
                                className={classes.doneAction}
                                onClick={() => {
                                  setIsConfirmed(true);
                                  handleQuizStop();
                                }}
                              >
                                {formatMessage(messages.no)}
                              </Link>
                            </div>
                          )
                      }
                    </div>
                  )
                }
              </fieldset>
              {
                showPositiveFeedbackPopUp && (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
                  >
                    <PositiveFeedback
                      handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
                      handleClose={handleClose}
                      theme={theme}
                      masterTheme={masterTheme}
                      isClickAway={isClickAway}
                    />
                  </ClickAwayListener>
                )
              }
              {
                showNegativeFeedbackPopUp && (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
                  >
                    <NegativeFeedback
                      handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
                      handleClose={handleClose}
                      theme={theme}
                      masterTheme={masterTheme}
                      isClickAway={isClickAway}
                    />
                  </ClickAwayListener>
                )
              }
            </div>
          )
      )}
    </React.Fragment>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

QuizInteractive.defaultProps = {
  classes: {},
  lessContent: '',
  feedbackIconFocusId: {},
  handleGAEvents: () => {},
  pageIds: [],
  theme: 'default',
  masterTheme: 'plus',
  disableOptions: false,
  chapterData: {},
  responseData: {},
  totalQuestionCount: 0,
  isExplainCYUModeB: false,
  isExplainCYUMode: false,
  initExplainQuizEvent: () => {}
};

QuizInteractive.propTypes = {
  classes: PropTypes.object,
  feedbackIconFocusId: PropTypes.object,
  lessContent: PropTypes.string,
  handleGAEvents: PropTypes.func,
  intl: intlShape.isRequired,
  quizData: PropTypes.object.isRequired,
  handleNextQuizQuestion: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  pageIds: PropTypes.array,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  disableOptions: PropTypes.bool,
  handleQuizStop: PropTypes.func.isRequired,
  currentQuizQstnIndex: PropTypes.number.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  chapterData: PropTypes.object,
  responseData: PropTypes.object,
  totalQuestionCount: PropTypes.number,
  isExplainCYUModeB: PropTypes.bool,
  initExplainQuizEvent: PropTypes.func,
  isExplainCYUMode: PropTypes.bool
};
export default memo(withStyles(styles)(injectIntl(QuizInteractive)));
