/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import * as constants from '../../common/constants';
import env from '../../common/env';
// eslint-disable-next-line import/no-cycle
import Framework from '../../framework/Framework';
import Utils from '../../utils/Utils';

/**
 * Authentication Strategy for authenticating the user against Pearson Identity (PI/IES) using piSession.js.
 *
 * @author Hari Gangadharan
 */
export default class IesAuthStrategy {
  constructor(authService) {
    this.isInitialized = false;
    this.authService = authService;
  }

  // eslint-disable-next-line class-methods-use-this
  redirectURL(redirectURL) {
    if (redirectURL) {
      window.location.href = redirectURL;
    } else {
      window.location.reload();
    }
  }

  login(successUrl) {
    const piSession = window.piSession;
    piSession.login(successUrl, 100, (status, accessToken) => {
      if (status === piSession.Success) {
        const userId = piSession.userId();
        const contextId = piSession.getContextId();
        this.authService.setAuthenticated(userId, accessToken, contextId);
        // setTimeout(() => { this.login(window.location.href); }, constants.LOGIN_REFRESH_TIME);
        piSession.on(piSession.RefreshEvent, (token) => {
          if (token && token.data) {
            this.authService.setUserToken(token.data);
          }
        });
        if (this.authService.enableSessionTimedOutEvent) {
          piSession.on(piSession.SessionTimedOutEvent, this.logout);
        }
      } else {
        // something wrong
        let redirectionUrl = '';
        if (window.isSSIEnabled) {
          const isGhostAccount = Utils.isQueryParamAvailable(constants.TPI, 'y');
          redirectionUrl = isGhostAccount ? this.getGhostAccountLogoutUrl() : env.get('SSI_PMC_SIGNIN_URL');
        }
        if (window.sessionStorage.getItem(constants.PEARSON_SESSION)) {
          window.sessionStorage.removeItem(constants.PEARSON_SESSION);
        }
        piSession.logout(redirectionUrl);
      }
    });
  }

  logout = (requireLogin = false) => {
    // TODO: This is a temp soultion for handling the fallback case for Aug 2nd release
    // In Next aug release it should be refactore
    const piSession = window.piSession;
    const isGhostAccount = Utils.isQueryParamAvailable(constants.TPI, 'y');
    const ghostAccountLogoutUrl = this.getGhostAccountLogoutUrl();
    if (window.sessionStorage.getItem('sortByActive')) {
      window.sessionStorage.removeItem('sortByActive');
      window.sessionStorage.removeItem('activeValue');
    }
    if (window.sessionStorage.getItem('sortByInactive')) {
      window.sessionStorage.removeItem('sortByInactive');
      window.sessionStorage.removeItem('inactiveValue');
    }
    if (window.sessionStorage.getItem(constants.PEARSON_SESSION)) {
      window.sessionStorage.removeItem(constants.PEARSON_SESSION);
    }
    if (!window.isSSIEnabled) {
      const isCanadianProxy = Utils.getQueryParameterbyName('cp');
      let redirectURL = '';
      const user = Framework.getStoreRegistry().getStore('user');
      if (isCanadianProxy === '1') {
        redirectURL = `${env.get('CP_URL')}`;
      }
      if (user.isMojoUser) {
        redirectURL = `${env.get('PMC_SIGNIN_URL')}`;
      }
      if (isGhostAccount) {
        redirectURL = ghostAccountLogoutUrl;
      }
      if (piSession) {
        piSession.logout(redirectURL);
      }
      if (isCanadianProxy === '1' && !piSession) {
        window.location.href = `${env.get('CP_URL')}`;
      }
      if (user.isMojoUser && !piSession) {
        window.location.href = `${env.get('PMC_SIGNIN_URL')}`;
      }
      if (isGhostAccount && !piSession) {
        window.location.href = ghostAccountLogoutUrl;
      }
      if (requireLogin) {
        this.redirectURL(redirectURL);
      }
    } else if (piSession) {
      const redirectionUrl = isGhostAccount ? ghostAccountLogoutUrl : env.get('SSI_PMC_SIGNIN_URL');
      piSession.logout(redirectionUrl);
      if (requireLogin) {
        this.redirectURL(redirectionUrl);
      }
    }
  };

  /**
   * Load piSession.js script.
   */
  init(onInit) {
    if (!this.isInitialized) {
      this.isInitialized = true;
      if (window.piSession) {
        this.login(window.location.href);
        onInit();

        return;
      }
      const sessionTime = this.authService.getSessionDuration();
      this.clientId = this.authService.getClientId();
      const script = document.createElement('script');
      script.onload = () => {
        const piSession = window.piSession;
        if (piSession) {
          piSession.initialize(this.clientId, {
            monitorUserActivity: true,
            sessionIdleTimeoutWarningSeconds: 300,
            useDefaultIdleTimoutWarningPopup: false,
            requireLogin: true,
            useJwt: true,
            checkSessionMilliseconds: 3000,
            sessionIdleTimeoutSeconds: sessionTime,
            autoRefresh: true
          });
          this.login(window.location.href);
        }
        onInit();
      };
      script.src = `${env.get('IES_BASE_URL')}/login/js/v2/session.js`;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  /**
   * To get live token from piSession
   * @returns
   */
  // eslint-disable-next-line class-methods-use-this
  getToken = () => new Promise((resolve) => {
    window.piSession.getToken((status, accessToken) => {
      resolve(accessToken);
    });
  });

  getGhostAccountLogoutUrl() {
    const IES_BASE_URL = env.get('IES_BASE_URL');
    const CLIENT_ID = this.authService.getClientId();
    const replaceContext = {
      '{IES_BASE_URL}': IES_BASE_URL,
      '{CLIENT_ID}': CLIENT_ID
    };
    const contextRegEx = new RegExp(Object.keys(replaceContext).join('|'), 'gi');
    contextRegEx.lastIndex = 0;

    return env.get('TPI_LOGOUT_URL').replace(contextRegEx, (match) => (replaceContext[match]));
  }
}
