/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types } from 'mobx-state-tree';
import { parse } from 'query-string';
import ulog from 'ulog';
import LocaleSupport from '../i18n/LocaleSupport';
import Utils from '../utils/Utils';

const log = ulog('App');
/**
 * A store/mobx model for language.
 *
 * @author Hari Gangadharan
 */
const Language = types.model(
  'Language',
  {
    language: types.maybe(types.string),
    userLanguages: types.maybe(types.array(types.string)),
    fromParam: types.boolean
  }
).views((self) => ({

  get currentLanguage() {
    return self.language;
  },
  get messages() {
    return LocaleSupport.getInstance().getMessages(self.language);
  },
  get defaultMessages() {
    return LocaleSupport.getInstance().getDefaultMessages();
  },
  /**
   * Gets the text associated with the string key.
   *
   * @param key the key for the string
   * @returns {*} the object that can used as props to FormatMessage
   */
  getText(key) {
    return {
      id: key,
      defaultMessage: self.defaultMessages[key]
    };
  },

  /**
   * Gets the text associated with the string key.
   *
   * @param key the key for the string
   * @returns {string} the text associated with the key
   */
  getMessage(key) {
    return self.messages[key];
  }

})).actions((self) => ({
  /* eslint-disable no-param-reassign */
  determineLang(mojoMVP) {
    let determinedLanguage = LocaleSupport.getInstance().getDefaultLang();
    const navigatorLanguages = function navigatorLanguages() {
      return (navigator.languages || [navigator.language]);
    };
    // eslint-disable-next-line consistent-return
    const findLanguage = (language) => {
      if (!language) {
        return false;
      }
      const translations = LocaleSupport.getInstance().getTranslations();
      if (mojoMVP) {
        determinedLanguage = 'en-us';
        // eslint-disable-next-line consistent-return
        translations.forEach((element, key) => {
          const locale = Utils.getLanguage(navigator.language);

          if (key === (locale && locale.toLowerCase())) {
            determinedLanguage = key;

            return true;
          }
        });
      } else {
        const supportedLanguages = translations.keys();
        let result = supportedLanguages.next();
        while (!result.done) {
          const key = result.value;
          language = Utils.getLanguage(language);

          log.info(`language: ${language} key: ${key}`);
          if (key === (language && language.toLowerCase())) {
            determinedLanguage = key;
            log.info('Determined language', determinedLanguage);

            return true;
          }

          result = supportedLanguages.next();
        }

        return false;
      }
    };
    const queryString = parse(window.location.search);
    if (!self.language
      || self.language !== determinedLanguage
      || queryString.locale
      || (!self.fromParam && self.userLanguages
        && JSON.stringify(self.userLanguages.toJSON()) !== JSON.stringify(navigator.languages))) {
      self.userLanguages = navigatorLanguages();
      if (!findLanguage(queryString.locale)) {
        navigatorLanguages().some(findLanguage);
      }
      self.language = determinedLanguage;
    }
  },
  /**
   * Changes language to specified language.
   *
   * @param language the language to change to
   */
  changeLanguageTo(language) {
    self.language = language;
  }
  /* eslint-enable no-param-reassign */
}));

export default Language;
