/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file FlashCardIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  flashCardIcon: {
    width: 20,
    height: 20
  }
});

const FlashCardIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 20 20"
    classes={{
      root: classes.flashCardIcon
    }}
  >
    <path
      d="M5.66667 6.56472C5.66667 6.06908 6.03976 5.66729 6.5 5.66729C6.96024 5.66729 7.33333 6.06908 7.33333 6.56472V7.73467L8.68745 7.7289C9.09016 7.72719 9.4182 8.09889 9.42016 8.55913C9.42212 9.01936 9.09725 9.39384 8.69454 9.39556L7.33333 9.40135V10.6032C7.33333 11.0988 6.96024 11.5006 6.5 11.5006C6.03976 11.5006 5.66667 11.0988 5.66667 10.6032V9.40844L4.31958 9.41417C3.91688 9.41588 3.58884 9.04418 3.58688 8.58395C3.58492 8.12371 3.90979 7.74923 4.31249 7.74752L5.66667 7.74176V6.56472Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1179 2.11464L12.1351 1.43363C11.6028 0.742356 10.7577 0.296016 9.80825 0.297827L3.18276 0.310459C1.58188 0.313511 0.290716 1.58962 0.298866 3.16072L0.353678 13.7269C0.361828 15.298 1.66621 16.5691 3.26709 16.5661L5.65836 16.5615C5.98992 16.7829 6.37359 16.9403 6.794 17.0121L13.3247 18.1288C14.9027 18.3986 16.4007 17.3626 16.6707 15.8149L18.4863 5.40566C18.7562 3.8579 17.6959 2.38446 16.1179 2.11464ZM11.0653 3.14019L11.1201 13.7064C11.1236 14.3797 10.5702 14.9266 9.88415 14.9279L3.25866 14.9405C2.57256 14.9418 2.01354 14.3971 2.01005 13.7237L1.95524 3.15756C1.95175 2.48423 2.5051 1.93733 3.19119 1.93602L9.81669 1.92339C10.5028 1.92208 11.0618 2.46686 11.0653 3.14019ZM16.8536 5.1265L15.038 15.5357C14.9223 16.199 14.2803 16.643 13.604 16.5274L11.3796 16.147C12.2214 15.6482 12.7819 14.74 12.7765 13.7032L12.7219 3.18315L15.8386 3.71606C16.5149 3.83169 16.9693 4.46317 16.8536 5.1265Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>
);

FlashCardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

FlashCardIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(FlashCardIcon);
