/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

export const QUIZ = 'quiz';
export const SUMMARY = 'summary';
export const CHAT = 'chat';
export const DISCUSS = 'discuss';
export const BULLET = 'bullet_points';
export const PARAGRAPH = 'paragraph_format';
export const CLEAR = 'clear';
export const ANSWER = 'answer';
export const CURRENT_PAGE = 'currentpage';
export const CURRENT_SECTION = 'currentsection';
export const PAGE_RANGE = 'pagerange';
export const CHAPTERS = 'chapters';
export const CURRENT_CHAPTER = 'currentchapter';
export const OPEN_BOT = 'OPEN_BOT_FROM_SELECTOR';
export const BOT_EVENTS = 'BOT_EVENTS';
export const MULTIPLE_CHOICE = 'multiple_choice';
export const FREE_RESPONSE = 'free_response';
export const SIMPLE_EXPLANATION = 'SIMPLE';
export const ASK_QUESTION = 'ASK';
export const CHATGPT_FLAHSCARD = 'CHATGPT FLAHSCARD';
export const SUMMARY_INTENT = 'summarize_feature';
export const EXPLAIN_INTENT = 'explain_feature';
export const QUIZ_INTENT = 'practice_questions';
export const CONTACT_INTENT = 'contact_support_feature';
export const PROBLEM_SOLVE_FEATURE = 'problem_solve_feature';
export const UNKNOWN = 'unknown';
export const INVALID = 'invalid';
export const MOVE_ON_INTENT = 'move_on';
export const TELL_MORE_INTENT = 'tell_me_more';
export const YES_QUIZ_ME_INTENT = 'yes_quiz_me';
export const IMAGE_EXPLAIN_INTENT = 'image_explain';
export const IMAGE_CYU_INTENT = 'image_cyu';
export const IMAGE_QUESTION_INTENT = 'image_ask_question';
export const IMAGE_CYU_EVALUATE_INTENT = 'image_cyu_evaluate';
export const IMAGE_HAVE_QUESTION_INTENT = 'have_question';
export const IMAGE_INTENT_LIST = [IMAGE_EXPLAIN_INTENT, IMAGE_CYU_INTENT, IMAGE_QUESTION_INTENT, IMAGE_CYU_EVALUATE_INTENT, IMAGE_HAVE_QUESTION_INTENT];
export const PDF = 'PDF';
export const CHATGPT_CARD_CREATED = 'CHATGPT_CARD_CREATED';
export const CHATGPT_CUSTOM_NOTE_CREATED = 'CHATGPT_CUSTOM_NOTE_CREATED';
export const CHATGPT_CUSTOM_FLASHCARD_CREATED = 'CHATGPT_CUSTOM_FLASHCARD_CREATED';
export const QUIZ_VALIDATE = 'QUIZ_VALIDATE';
export const NEXT_QUESTIONS = 'NEXT_QUESTIONS';
export const REFRESH_DRAWER = 'REFRESH_DRAWER';
export const REFRESH_DECK = 'REFRESH_DECK';
export const REFRESH_AI_DECK = 'REFRESH_AI_DECK';
export const NEXT_QUIZ_FEATURE = 'next_quiz_feature';
export const NEXT_QUESTION_FEATURE = 'next_question_feature';
export const READY_FOR_NEXT_QUESTION_FEATURE = 'ready_for_next_question_feature';
export const READY_FOR_NEXT_FEATURE = 'ready_for_next_feature';
export const ANSWER_FEATURE = 'answer_feature';
export const STOP_QUIZ_FEATURE = 'stop_quiz_feature';
export const NOT_READY_FEATURE = 'not_ready_feature';
export const NOT_READY_FOR_NEXT_FEATURE = 'not_ready_for_next_feature';
export const PAUSED_QUIZ_FEATURE = 'pause_quiz_feature';
export const SWITCH_CHAT_FEATURE = 'switch_chat_feature';
export const SALUTATION_FEATURE = 'salutation_feature';
export const SUMMARY_OPTIONS = 'summaryOptions';
export const QUIZ_OPTIONS = 'quizOptions';
export const LOADER = 'loader';
export const USER_MESSAGE = 'userMessage';
export const CLARIFY = 'clarify';
export const SUMMARY_CONTENT = 'summaryContent';
export const QUIZ_CONTENT = 'quizContent';
export const NEXT_QUESTION = 'nextQuestion';
export const QUIZ_ANSWER = 'quizAnswer';
export const CUSTOM_INPUT = 'customInput';
export const CONTENT_SELECTED_INPUT = 'contentSelectedInput';
export const NO_QUESTION_LEFT = 'noQuestionsLeft';
export const ERROR_MESSAGE = 'errorResponse';
export const QUIZ_LOADER = 'quizLoader';
export const WELCOME_MESSAGE = 'welcomeMessage';
export const FREE_RESPONSE_QUIZ = 'freeResponseQuiz';
export const FREE_RESPONSE_QUIZ_VALIDATE = 'quizValidate';
export const GENERATE_TOPIC = 'GENERATE_TOPIC';
export const SINGLE_TOPIC = 'SINGLE_TOPIC';
export const DISCUSS_TOPICS = 'DISCUSS_TOPICS';
export const STREAMING_SUMMARY_CONTENT = 'STREAMING_SUMMARY_CONTENT';
export const START = 'start';
export const DONE = 'done';
export const STREAMING = 'streaming';
export const STREAMING_DISCUSS = 'STREAMING_DISCUSS';
export const KEEP_ALIVE = 'keepAlive';
export const CHAPTER_TOPIC_OPTION = 'CHAPTER_TOPIC_OPTION';
export const TOPIC_CHAPTER = 'chapter';
export const TOPIC_MODULE = 'module';
export const TOPIC_SUBMODULE = 'submodule';
export const TOPIC_SLATE = 'slate';
export const CHAPTER = 'chapter';
export const SECTION = 'section';
export const SUGGESTION_CONTENT = 'SUGGESTION_CONTENT';
export const DELAYED_RESPONSE = 'delay';
export const AFTER_STOP = 'stopMessage';
export const CHATBOT_OPEN = 'AIStudyTool_Opened';
export const SHOW_MORE_IDENTIFIER = 'SHOW_MORE_IDENTIFIER';
export const USER_SAYS = 'User says';
export const TUTOR_SAYS = 'Tutor says';
export const SPECIFIC_TOPIC = 'specific_topic';
export const THREAD_ID = 'thread-id';
export const NEW_THREAD = 'new-thread';
export const CHANNELS_VIDEOS_ES_SCORE = 60; // 60 is set to default
export const CHANNELS_VIDEO_CLICKED_FROM_GPT = 'ChannelsVideoClickedFromGPT';
export const ETEXT_AI_STUDY_TOOL = 'eTextAIStudyTool';
export const PROD = 'prod';
export const GPT_EVENTS = {
  TYPE: 'type',
  CLICK: 'click',
  PAGE: 'Page',
  SUMMARY: 'Summary',
  PARAGRAPH: 'Paragraph',
  BULLET: 'Bullet',
  MIX: 'mix',
  TOPIC: 'Topic',
  UI_QUICK_ACTION: 'UI Quick Action',
  CONTENT_SELECTOR: 'Content Selector',
  COMMAND_LINE: 'Command Line',
  FORMAT_BUTTON: 'Format Button',
  SHORT_ANSWER: 'Short Answer',
  PRACTICE: 'Practice',
  EXPLAIN: 'Explain',
  GPT_GENERATED: 'GPT-generated',
  CACHE_GENERATED: 'cache-generated',
  FREE_RESPONSE: 'Free Response',
  MULTI_CHOICE: 'Multi choice',
  MULTIPLE_CHOICE: 'Multiple Choice',
  PRACTICE_QUIZ: 'practice_question',
  SUMMARY_EXPLANATION: 'summary explanation',
  COMPLETED: 'Completed',
  AI_STUDY_TOOLS: 'AI study tool',
  EXPLAIN_TRIGGER_EVENT: 'explain',
  STOP_QUIZ: 'stop quiz',
  PAUSED_QUIZ: 'pause quiz',
  NEXT_QUIZ: 'next quiz',
  SWITCH_CHAT: 'switch chat',
  UNKNOWN_INTENT: 'unknown intent',
  YES: 'yes',
  NO: 'no',
  CHAPTER: 'Chapter',
  SECTION: 'Section',
  ENGAGEMENT_SUGGESTION: 'Engagement Suggestion',
  FOLLOWUP_SUMMARY_EXPLANATION: 'Follow-up Summary Explanation',
  FOLLOWUP_SUMMARY: 'Follow-up Summary',
  FOLLOWUP_EXPLANATION: 'Follow-up Explanation',
  FOLLOWUP_PRACTICE: 'Follow-up Practice',
  FOLLOWUP_TOPICS: 'Follow-up Topics',
  CLICKTOSHOW: 'click to show',
  AUTOMATICALLY: 'automatically',
  SYSTEM: 'System',
  CLICKED: 'Clicked',
  AUTO_OPEN: 'Auto Open',
  BULLET_POINT_TYPE: 'bullet point',
  PARAGRAPH_TYPE: 'paragraph',
  QUESTION: 'question',
  PRACTICE_TYPE: 'practice',
  UP: 'Up',
  DOWN: 'Down',
  SELECTED: 'Selected',
  DESELECTED: 'Deselected',
  GPT: 'GPT',
  VIDEO_CTA: 'video_cta',
  AI_STUDY_TOOL_START_POSITION: 'AI Study Tool start position',
  EREADER: 'eReader',
  VIDEO: 'Video',
  PAGE_SUGGESTIONS: 'page_suggestions',
  FREESTANDING_ICON: 'freestanding icon',
  NAVIGATION_DRAWER: 'navigation drawer',
  CONTENT_MEDIA: 'Content-media',
  LIGHTBOX: 'Lightbox',
  CONTENTSELECTOR_SUGGESTIONS: 'ContentSelector_suggestions',
  ENGLISH_US: 'en-US',
  SOURCE_HYPERLINK: 'source_hyperlink',
  FREEMIUM: 'Freemium',
  CLOSE_POP_UP_SUBS: 'Close pop up Subs',
  POP_UP_SUBS: 'Pop up Subs'
};
export const maxInputCharLength = 4000;
export const footerLink = 'https://www.pearson.com/en-us/privacy-center/resources/ai-frequently-asked-questions.html';
export const PERIOD = '.';
export const SUMMARIZE_EXPLAIN_MODE = 'summarize_explain_mode';
export const SUMMARY_MODE = 'summary_mode';
export const QUIZ_MODE = 'quiz_mode';
export const TOPIC = 'topic';
export const POSITIVE_FEEDBACK = 'positiveFeedback';
export const NEGATIVE_FEEDBACK = 'negativeFeedback';
export const SLEEP_MODE_TIME_OUT = 30000;
export const CHATBOT_HOVER = 'chatBotHovered';
export const CHATBOT_CLICKED = 'chatBotClicked';
export const AI_TUTOR_LOADED = 'AITutorLoaded';
export const USER_SELECTION_MODE = 'user_selection_mode';
export const USER_SELECTION = 'user_selection';
export const EXPLAIN_QUIZ_OPTION_A = 1;
export const EXPLAIN_QUIZ_OPTION_B = 2;
export const EXPLAIN_QUIZ_OPTION_C = 3;
export const EXPLANATION_CYU = 'explanation_cyu';
export const CYU_ANSWER_SUBMITTED = 'cyu_answer_submitted';
export const OPEN_FULL_VIEW = 'openFullView';
export const LIVE = 'LIVE';
export const PROMPT_VERSION_KEYWORDS = {
  TRIGGER_INTENTION: 'TRIGGER_INTENTION',
  OUTPUT_SUCCESS: 'OUTPUT_SUCCESS',
  ANSWER_PRACTICE_QUESTION: 'ANSWER_PRACTICE_QUESTION',
  CLICK_TOPIC_SUGGESTION: 'TOPIC_USER_SELECTED',
  TOPIC_USER_SELECTED: 'TopicUserSelected',
  QUESTIONS_FROM_TOPICS: 'QUESTIONS_FROM_TOPICS',
  QUESTIONS_TOPICS: 'QuestionsFromTopics',
  MAIN_INTENT: 'MAIN_INTENT',
  MainIntent: 'MainIntent',
  EXPLAIN_CASE: 'EXPLAIN',
  Explain_Name: 'Explain',
  SUMMARYTOPIC_CASE: 'SUMMARYTOPIC',
  SummaryTopic_Name: 'SummaryTopic',
  PROBLEMFIXER_CASE: 'PROBLEMFIXER',
  ProblemFixer_Name: 'ProblemFixer',
  SUMMARY_CASE: 'SUMMARY',
  SummaryReg_Name: 'SummaryReg',
  MCQTOPIC_CASE: 'MCQTOPIC',
  MCQTopic_Name: 'MCQTopic',
  SHORTANSTOPIC_CASE: 'SHORTANSTOPIC',
  ShortAnsTopic_Name: 'ShortAnsTopic',
  MCQCYU: 'MCQCYU',
  SHORTANS_CASE: 'SHORTANS',
  ShortAns_Name: 'ShortAns',
  TOPICEXTRACT_CASE: 'TOPICEXTRACT',
  TopicExtract_Name: 'TopicExtract'
};
export const PROBLEM_SOLVE_RECTIFIER = 'problem_solve_rectifier';
export const PROBLEM_SOLVE_CALCULATION = 'problem_solve_calculation';
export const CALCULATION = 'calculation';
export const PROBLEM_SOLVE = 'problem_solve';
export const PROBLEM_SOLVE_LOADER_MESSAGE = 'problem_solve_loader_message';
export const CANNED_MESSAGE = 'canned_message';
export const CHAT_HISTORY_TITLE_LIMIT = 100;
export const CHAT_HISTORY_MESSAGE_LIMIT = 20;
export const CHAT_HISTORY_SEARCH_CHAR_LIMIT = 3;
export const DELETE_THREAD_TIMEOUT = 4000;
export const FILTER_SEARCH_DELAY_GA = 3000;
export const WHAT_WAS_WRONG_RESPONSE = 'What was wrong with that response?';
export const PAYWALL_CHANNELS_SUBSCRIPTION_URL_DEV = 'https://pearson-dev.pearson.com/channels/subscription?CEP=eTextAIStudyTool';
export const PAYWALL_CHANNELS_SUBSCRIPTION_URL_PROD = 'https://www.pearson.com/channels/subscription?CEP=eTextAIStudyTool';
// eslint-disable-next-line import/prefer-default-export
export const virtualKeyboardLayers = [
  {
    label: '123',
    tooltip: 'Numeric',
    layer: '123',
    rows: [
      [
        {
          class: 'keycap tex',
          label: '<i>a</i>'
        },
        {
          class: 'keycap tex',
          label: '<i>x</i>'
        },
        {
          class: 'separator w5'
        },
        {
          class: 'keycap',
          latex: '7'
        },
        {
          class: 'keycap',
          latex: '8'
        },
        {
          class: 'keycap',
          latex: '9'
        },
        {
          class: 'keycap',
          latex: '\\div'
        },
        {
          class: 'separator w5'
        },
        {
          class: 'keycap tex',
          insert: '$$#@^{2}$$',
          label: '<span><i>x</i>&thinsp;²</span>'
        },
        {
          class: 'keycap tex',
          insert: '$$#@^{}$$',
          label: '<span><i>x</i><sup>&thinsp;<i>n</i></sup></span>'
        },
        {
          class: 'keycap tex',
          insert: '$$\\sqrt{#0}$$',
          latex: '\\sqrt{#0}'
        }
      ],
      [
        {
          class: 'keycap tex',
          label: '<i>b</i>'
        },
        {
          class: 'keycap tex',
          label: '<i>y</i>'
        },
        {
          class: 'separator w5'
        },

        {
          class: 'keycap',
          latex: '4'
        },
        {
          class: 'keycap',
          latex: '5'
        },
        {
          class: 'keycap',
          latex: '6'
        },
        {
          class: 'keycap',
          latex: '\\times'
        },
        {
          class: 'separator w5'
        },
        {
          class: 'keycap',
          latex: '\\frac{#0}{#0}'
        },
        {
          class: 'separator w6'
        },
        {
          class: 'separator w6'
        }
      ],
      [
        {
          class: 'keycap tex',
          label: '<i>c</i>'
        },
        {
          class: 'keycap tex',
          label: '<i>z</i>'
        },
        {
          class: 'separator w5'
        },

        {
          class: 'keycap',
          latex: '1'
        },
        {
          class: 'keycap',
          latex: '2'
        },
        {
          class: 'keycap',
          latex: '3'
        },
        {
          class: 'keycap',
          latex: '-'
        },
        {
          class: 'separator w5'
        },
        {
          class: 'separator w6'
        },
        {
          class: 'separator w6'
        },
        {
          class: 'separator w6'
        }
      ],
      [
        {
          class: 'keycap tex',
          label: '('
        },
        {
          class: 'keycap tex',
          label: ')'
        },

        {
          class: 'separator w5'
        },
        {
          class: 'keycap',
          latex: '0'
        },
        {
          class: 'keycap',
          latex: '.'
        },
        {
          class: 'keycap',
          latex: '='
        },
        {
          class: 'keycap',
          latex: '+'
        },
        {
          class: 'separator w5'
        },
        {
          class: 'action',
          command: ['performWithFeedback', 'moveToPreviousChar'],
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
        },
        {
          class: 'action',
          command: ['performWithFeedback', 'moveToNextChar'],
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
        },
        {
          class: 'action font-glyph bottom right',
          command: ['performWithFeedback', 'deleteBackward'],
          label: '&#x232b;'
        }
      ]
    ]
  },
  'symbols',
  'alphabetic',
  'greek'
];
export const MARKDOWN_URL = 'https://cdn.jsdelivr.net/npm/marked/marked.min.js';
export const RECOMMENDED_PROMPT_LIMIT = 3;
export const IMAGE = 'image';
export const LINK = 'link';
export const GLOSSARY = 'glossary';
export const IMAGE_CONTEXT = 'ImageContext';
export const IMAGE_COPILET_MODE = 'image_copilot_mode';
export const IMAGE_SOMETHING_ELSE = 'something_else';
export const IMAGE_CO_PILET_PROMPT_ARRAY = [
  {
    title: 'Explain it to me',
    userAction: 'explain_image'
  },
  {
    title: 'Check my understanding',
    userAction: 'check_my_understanding'
  },
  {
    title: 'Something else',
    userAction: IMAGE_SOMETHING_ELSE
  }
];
