/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Simplified Chinese Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': '继续',
  'browser.check.DONT_SHOW_AGAIN': '不再显示',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': '支持的操作系统和网络浏览器',
  'browser.check.BROWSER_MESSAGE_TITLE': '您的浏览器 <<name>> 版本过低',
  'browser.check.BROWSER_MESSAGE_DESC': '为获得最佳体验，请选择以下浏览器的最新版本：',
  'browser.check.BROWSER_SKIP_NOW': '暂时跳过',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': '关于支持的浏览器',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': '我们不支持您的浏览器 <<name>>',
  'browser.check.BROWSER_UNSUPPORTED_DESC': '为获得最佳体验，请使用一个我们支持的浏览器。',
  'browser.check.OS_UNSUPPORTED_TITLE': '我们不支持您的操作系统 <<name>>',
  'browser.check.OS_UNSUPPORTED_DESC': '产品可能正常运作，但请注意，您遇到的任何问题都可通过一台运行 <<link>> 的设备解决。',
  'errors.GENERIC_TITLE': '服务器错误',
  'errors.GENERIC_DESC': '很抱歉，系统崩溃了。请稍后重试或联系技术支持团队。',
  'errors.HTTP_400_TITLE': '服务器错误',
  'errors.HTTP_400_DESC': '在处理您的请求时出现错误。',
  'errors.HTTP_401_TITLE': '您是否已登录？',
  'errors.HTTP_401_DESC': '我们无法提取数据，可能是因为您的登录会话已过期。',
  'errors.HTTP_403_TITLE': '未授权',
  'errors.HTTP_403_DESC': '您无权访问该资源。',
  'errors.HTTP_888_TITLE': '服务器响应超时',
  'errors.HTTP_888_DESC': '在处理您的请求时出现错误。'
};

export default RESOURCES;
