/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ctaButtons.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import messages from '../../defaultMessages';
import ChatComponentStyles from './ChatComponentStyles';
import HistoryIcon from '../../Common/icons/HistoryIcon';

const TAB = 'tab';
const CtaButtons = (props) => {
  const {
    inputValue,
    handleSend,
    handleClear,
    classes,
    isTextBoxDisable,
    disableNewTopic,
    intl: { formatMessage },
    isCharLimitExceeded,
    isExpanded,
    onHistoryClick,
    masterTheme,
    theme,
    showHistoryIcon
  } = props;
  // const handleSuggestion = () => {
  //   const additionalFields = {
  //     event_label: null, // TODO: [Hide/Show]
  //     event_value: null,
  //     event_action: eventAction.clickSuggestion
  //   };
  //   gaEvents(handleGAEvents, additionalFields);
  // };
  const handleSendKeyDown = (event) => {
    const eventKey = event.key && event.key.toLowerCase();
    if (isExpanded) {
      if (event.shiftKey && eventKey === TAB) {
        event.preventDefault();
        document.getElementById('faqLink').focus();
      } else if (eventKey === TAB) {
        event.preventDefault();
        if (!disableNewTopic) {
          document.getElementById('clear').focus();
        } else {
          document.getElementById('collapse_button').focus();
        }
      }
    }
  };
  const handleClearKeyDown = (event) => {
    const eventKey = event.key && event.key.toLowerCase();
    if (isExpanded) {
      if (event.shiftKey && eventKey === TAB) {
        event.preventDefault();
        if ((inputValue && inputValue.trim()) || isTextBoxDisable || isCharLimitExceeded) {
          document.getElementById('send').focus();
        } else {
          document.getElementById('faqLink').focus();
        }
      } else if (eventKey === TAB) {
        event.preventDefault();
        document.getElementById('collapse_button').focus();
      }
    }
  };
  const handleHistoryClick = (event) => {
    event.stopPropagation();
    onHistoryClick();
  };
  return (
    <div className={classes.buttonWrapper}>
      {/* <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Suggestions" placement="top" arrow>
        <IconButton onClick={handleSuggestion}>
          <HelpIcon masterTheme={masterTheme} theme={theme} />
        </IconButton>
      </Tooltip> */}
      <div className={classes.clearButtonWrapper}>
        <Button
          id="clear"
          className={classes.clearButton}
          onClick={handleClear}
          disabled={disableNewTopic}
          classes={{
            disabled: classes.clearButtonDisabled
          }}
          onKeyDown={(event) => handleClearKeyDown(event)}
        >
          {formatMessage(messages.clearChat)}
        </Button>
        {
          showHistoryIcon && (
            <Tooltip
              placement="top"
              title={formatMessage(messages.chatHistoryToolTip)}
              arrow
              classes={{
                tooltip: classes.tooltipStyleHistory,
                arrow: classes.arrowStyle
              }}
            >
              <div className={classes.historyIconWrapper}>
                <IconButton
                  id="history_icon"
                  className={classes.historyButtonStyle}
                  onClick={(event) => { handleHistoryClick(event); }}
                >
                  <HistoryIcon masterTheme={masterTheme} theme={theme} />
                </IconButton>
              </div>
            </Tooltip>
          )
        }
      </div>
      <Button
        id="send"
        disabled={!(inputValue && inputValue.trim()) || isTextBoxDisable || isCharLimitExceeded}
        classes={{ disabled: classes.sendButtonDisabled }}
        className={classes.sendButton}
        onClick={handleSend}
        onKeyDown={(event) => handleSendKeyDown(event)}
      >
        {formatMessage(messages.send)}
      </Button>
    </div>
  );
};

CtaButtons.propTypes = {
  intl: intlShape.isRequired,
  handleSend: PropTypes.func,
  handleClear: PropTypes.func,
  isTextBoxDisable: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  inputValue: PropTypes.string,
  disableNewTopic: PropTypes.bool.isRequired,
  isCharLimitExceeded: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onHistoryClick: PropTypes.func.isRequired,
  masterTheme: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  showHistoryIcon: PropTypes.bool
};

CtaButtons.defaultProps = {
  handleSend: () => {},
  isTextBoxDisable: false,
  handleClear: () => {},
  inputValue: '',
  isCharLimitExceeded: false,
  isExpanded: false,
  showHistoryIcon: false
};

export default withStyles(ChatComponentStyles)(injectIntl(CtaButtons));
