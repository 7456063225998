/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file TutorEyesSleep.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';

const TutorEyesSleep = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_10502_93260)">
      <path d="M8.00932 26.2974C7.98031 25.8363 8.33094 25.4462 8.79249 25.4261L20.7494 24.9042C21.211 24.8841 21.6087 25.2415 21.6377 25.7026C21.6667 26.1637 21.3161 26.5538 20.8545 26.5739L8.89757 27.0958C8.43602 27.1159 8.03834 26.7585 8.00932 26.2974Z" fill="#AEDDD8" />
    </g>
    <g filter="url(#filter1_d_10502_93260)">
      <path d="M26.3623 25.7027C26.3913 25.2416 26.789 24.8841 27.2505 24.9043L39.2075 25.4261C39.669 25.4463 40.0196 25.8364 39.9906 26.2974C39.9616 26.7585 39.5639 27.116 39.1024 27.0958L27.1454 26.574C26.6839 26.5538 26.3333 26.1637 26.3623 25.7027Z" fill="#AEDDD8" />
    </g>
    <defs>
      <filter id="filter0_d_10502_93260" x="0.00762939" y="16.9034" width="29.6317" height="18.1931" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10502_93260" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10502_93260" result="shape" />
      </filter>
      <filter id="filter1_d_10502_93260" x="18.3606" y="16.9034" width="29.6317" height="18.1932" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10502_93260" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10502_93260" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default TutorEyesSleep;
