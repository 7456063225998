/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file HistorySearchIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  searchIcon: {
    width: 20,
    height: 20,
    opacity: '0.35'
  }
});

const HistorySearchIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 20 20"
    classes={{
      root: classes.searchIcon
    }}
  >
    <path
      d="M8.5 3C11.5376 3 14 5.46243 14 8.5C14 9.74832 13.5841 10.8995 12.8834 11.8226L17.0303 15.9697C17.3232 16.2626 17.3232 16.7374 17.0303 17.0303C16.7641 17.2966 16.3474 17.3208 16.0538 17.1029L15.9697 17.0303L11.8226 12.8834C10.8995 13.5841 9.74832 14 8.5 14C5.46243 14 3 11.5376 3 8.5C3 5.46243 5.46243 3 8.5 3ZM8.5 4.5C6.29086 4.5 4.5 6.29086 4.5 8.5C4.5 10.7091 6.29086 12.5 8.5 12.5C10.7091 12.5 12.5 10.7091 12.5 8.5C12.5 6.29086 10.7091 4.5 8.5 4.5Z"
      fill={ThemeConstants[masterTheme][theme].searchIconColor}
    />
  </SvgIcon>
);

HistorySearchIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

HistorySearchIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(HistorySearchIcon);
