/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Quiz.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { intlShape, injectIntl } from 'react-intl';
import ThemeConstants from '../../Common/ThemeConstants';

const styles = () => ({
  noMoreQuestionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    width: '100%'
  },
  noMoreQuestionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  noMoreQuestionsContainer__question: {
    width: 280,
    height: 48,
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    margin: 0
  },
  noMoreQuestionsContainer__actions: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 5 // need to add fallback property for old browser
  },
  noMoreQuestionsContainer__actions__button: {
    padding: '4px',
    width: 'auto',
    border: '1px solid #8246b5',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    fontSize: '12px',
    textTransform: 'none',
    color: '#312d2d'
  }
});

const NoMoreQuestions = (props) => {
  const { classes, intl } = props;
  const { formatMessage } = intl;
  console.log(formatMessage);
  return (
    <div className={classes.noMoreQuestionsWrapper}>
      <div className={classes.noMoreQuestionsContainer}>
        <Typography className={classes.noMoreQuestionsContainer__question} variant="p">
          {'That was the last question, What\'s next?'}
        </Typography>
        {/* <div className={classes.noMoreQuestionsContainer__actions}>
          <Button className={classes.noMoreQuestionsContainer__actions__button}>
            Show more questions like those
          </Button>
          <Button className={classes.noMoreQuestionsContainer__actions__button}>
            Create practice questions for something else
          </Button>
          <Button className={classes.noMoreQuestionsContainer__actions__button}>
            Explain a topic or concept from those questions
          </Button>
        </div> */}
      </div>
    </div>
  );
};

NoMoreQuestions.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

NoMoreQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

export default withStyles(styles)(injectIntl(NoMoreQuestions));
