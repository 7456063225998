/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file CheckIcon.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const Styles = () => ({
  checkIcon: {
    width: 20,
    height: 20
  }
});

const CheckIcon = ({ classes }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" classes={{ root: classes.checkIcon }}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM7.36085 11.5042L11.7111 5.28948C11.9223 4.98785 11.8489 4.57216 11.5473 4.36102C11.2457 4.14987 10.83 4.22323 10.6188 4.52486L6.73047 10.0797L5.34113 8.60348C5.08878 8.33537 4.66687 8.32258 4.39875 8.57492C4.13063 8.82727 4.11785 9.24919 4.37019 9.5173L6.3212 11.5902C6.52284 11.8045 6.83276 11.8557 7.08596 11.7389C7.17345 11.7183 7.25498 11.6516 7.34263 11.53C7.34893 11.5215 7.355 11.5129 7.36085 11.5042Z" fill="#01CBB7" />
  </SvgIcon>
);

CheckIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(CheckIcon);
