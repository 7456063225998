/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * A React component for ExportIcon.
 *
 * @author Vigesh Selvam
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  exportIcon: {
    width: 20,
    height: 20
  }
});

const ExportIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    width="20"
    height="20"
    classes={{
      root: classes.exportIcon
    }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71 5.7099L9 3.4099V12.9999C9 13.2651 9.10536 13.5195 9.29289 13.707C9.48043 13.8945 9.73478 13.9999 10 13.9999C10.2652 13.9999 10.5196 13.8945 10.7071 13.707C10.8946 13.5195 11 13.2651 11 12.9999V3.4099L13.29 5.7099C13.383 5.80362 13.4936 5.87802 13.6154 5.92879C13.7373 5.97956 13.868 6.00569 14 6.00569C14.132 6.00569 14.2627 5.97956 14.3846 5.92879C14.5064 5.87802 14.617 5.80362 14.71 5.7099C14.8037 5.61693 14.8781 5.50633 14.9289 5.38447C14.9797 5.26261 15.0058 5.13191 15.0058 4.9999C15.0058 4.86788 14.9797 4.73718 14.9289 4.61532C14.8781 4.49346 14.8037 4.38286 14.71 4.2899L10.71 0.289896C10.6149 0.198856 10.5028 0.127491 10.38 0.0798963C10.1365 -0.0201217 9.86346 -0.0201217 9.62 0.0798963C9.49725 0.127491 9.3851 0.198856 9.29 0.289896L5.29 4.2899C5.19676 4.38314 5.1228 4.49383 5.07234 4.61565C5.02188 4.73747 4.99591 4.86804 4.99591 4.9999C4.99591 5.13176 5.02188 5.26232 5.07234 5.38415C5.1228 5.50597 5.19676 5.61666 5.29 5.7099C5.38324 5.80314 5.49393 5.8771 5.61575 5.92756C5.73757 5.97802 5.86814 6.00399 6 6.00399C6.13186 6.00399 6.26243 5.97802 6.38425 5.92756C6.50607 5.8771 6.61676 5.80314 6.71 5.7099ZM19 11.9999C18.7348 11.9999 18.4804 12.1053 18.2929 12.2928C18.1054 12.4803 18 12.7347 18 12.9999V16.9999C18 17.2651 17.8946 17.5195 17.7071 17.707C17.5196 17.8945 17.2652 17.9999 17 17.9999H3C2.73478 17.9999 2.48043 17.8945 2.29289 17.707C2.10536 17.5195 2 17.2651 2 16.9999V12.9999C2 12.7347 1.89464 12.4803 1.70711 12.2928C1.51957 12.1053 1.26522 11.9999 1 11.9999C0.734784 11.9999 0.48043 12.1053 0.292893 12.2928C0.105357 12.4803 0 12.7347 0 12.9999V16.9999C0 17.7955 0.316071 18.5586 0.87868 19.1212C1.44129 19.6838 2.20435 19.9999 3 19.9999H17C17.7956 19.9999 18.5587 19.6838 19.1213 19.1212C19.6839 18.5586 20 17.7955 20 16.9999V12.9999C20 12.7347 19.8946 12.4803 19.7071 12.2928C19.5196 12.1053 19.2652 11.9999 19 11.9999Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>

);

ExportIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

ExportIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(ExportIcon);
