/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import ulog from 'ulog';

/**
 * Base definition of environment properties. These can be overriden by env-specific definitions.
 *
 * @author Hari Gangadharan
 */
const base = {
  LOG_LEVEL: ulog.DEBUG,
  IES_BASE_URL: 'https://login-stg.pearson.com/v1/piapi-int',
  INT_LOGIN_URL: 'https://mycloudtest.pearson.com/redirect',
  INT_BASE_URL: 'https://mycloudtest.pearson.com/auth/json/pearson',
  INT_TIMEOUT: 10000,
  USER_PROFILE_BASE_URL: 'https://tiburon-stg.stg-prsn.com/api/1.0',
  MARIN_API_BASE_URL: 'https://plus-stg.pearson.com/marin/api/1.0',
  MARIN_API_TIMEOUT: 20000,
  USER_PROFILE_TIMEOUT: 20000,
  ETEXT: 'ETEXT',
  BROWSER_CHECK_SCRIPT: 'https://e2e-comms-stg.pearson.com/osbrowserchecker/stg/checker-module.min.js',
  BROWSER_CHECK_STYLE: 'https://e2e-comms-stg.pearson.com/osbrowserchecker/stg/checker-module.min.css',
  PMC_SIGNIN_URL: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/logout?sessionTimedOut=false&consoleHomePageUrl=https://pearson-stg2.pearson.com/store/en-us/pearsonplus/login',
  SSI_PMC_SIGNIN_URL: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/login',
  MOJO_DOMAIN: 'plus-stg.pearson.com',
  ETEXT_DOMAIN: 'plus-stg.pearson.com',
  LAUNCH_DARKLY_BASE_URL: 'https://app.launchdarkly.com/api/v2',
  LD_ENVIRONMENT: 'staging',
  LD_API_ACCESS_KEY: 'api-deab7479-c46e-4f6d-8df4-2914294f2941',
  CP_URL: 'http://etext-sms-piiprx-dev.pearsoncmg.com/etext_login/login.html',
  TPI_LOGOUT_URL: '{IES_BASE_URL}/login/showIdpLogout?client_id={CLIENT_ID}'
};
export default base;
