/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import BaseService from './BaseService';
import * as constants from '../common/constants';
import stores from '../stores';

/**
 * Browser Check Service pops up an incompatibility message if user uses an incompatible browser or OS.
 *
 * @author Hari Gangadharan
 */
export default class BrowserCheckService extends BaseService {
  constructor() {
    super(constants.BROWSER_CHECK_REQUESTED);
  }

  handleEvent(eventName, event) { // eslint-disable-line class-methods-use-this
    const { language } = stores;
    const browserChecker = window.browserChecker;
    if (browserChecker) {
      browserChecker.systemsRequirementURL = event.browserRequirementsUrl;
      browserChecker.isPopupCheckDisabled = true;
      browserChecker.browserMessageDescription = language.getMessage('browser.check.BROWSER_MESSAGE_DESC');
      browserChecker.browserMessageTitle = language.getMessage('browser.check.BROWSER_MESSAGE_TITLE');
      browserChecker.browserUnSupportedMessageDescription = language.getMessage(
        'browser.check.BROWSER_UNSUPPORTED_DESC'
      );
      browserChecker.browserUnSupportedMessageTitle = language.getMessage('browser.check.BROWSER_UNSUPPORTED_TITLE');
      browserChecker.osMessageDescription = language.getMessage('browser.check.OS_UNSUPPORTED_DESC');
      browserChecker.osMessageTitle = language.getMessage('browser.check.OS_UNSUPPORTED_TITLE');
      browserChecker.dontShowMeAgain = language.getMessage('browser.check.DONT_SHOW_AGAIN');
      browserChecker.skipNow = language.getMessage('browser.check.BROWSER_SKIP_NOW');
      browserChecker.supportedBrowserLinkText = language.getMessage('browser.check.BROWSER_SUPPORTED_LINK_TEXT');
      browserChecker.systemsRequirementLinkText = language.getMessage('browser.check.SYS_REQUIREMENTS_LINK_TEXT');
      browserChecker.displayCompatibilityMessage();
      browserChecker.init(event.product);
    }

    return Promise.resolve(true);
  }
}
