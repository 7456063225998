/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import ErrorModel from './ErrorModel';

const ApiState = types.model(
  'ApiState',
  {
    isPending: types.boolean,
    isError: types.boolean,
    error: types.maybeNull(ErrorModel)
  }
).actions((self) => ({
  setPending() {
    applySnapshot(self, {
      isPending: true,
      isError: false,
      error: null
    });
  },

  setFulfilled() {
    self.clear();
  },

  /*
   * Set error details
   *
   * Accepted format #1
   * {
   *    response : {
   *      status: 502
   *    },
   *    message: "Bad Gateway"
   * }
   *
   * Accepted format #2
   * {
   *    status: 502
   *    message: "Bad Gateway"
   * }
   *
   * Accepted format #3
   * {
   *    statusCode: 502
   *    message: "Bad Gateway"
   * }
   */
  setError(error) {
    const message = error.message;
    const status = (error.response && error.response.status)
      || error.status
      || error.statusCode
      || 888;

    applySnapshot(self, {
      isPending: false,
      isError: true,
      error: {
        message,
        status
      }
    });
  },

  clear() {
    applySnapshot(self, {
      isPending: false,
      isError: false,
      error: null
    });
  }
}));

export default ApiState;
