/* eslint-disable no-case-declarations */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import BaseService from './BaseService';
import Framework from '../framework/Framework';

import * as constants from '../common/constants';
import env from '../common/env';
import Utils from '../utils/Utils';

const clientManager = Framework.getClientManager();
/**
 * Device Service gets the user profile from IES through Tiburon.
 *
 * @author Vijaykani Elangovan
 */
export default class DeviceService extends BaseService {
  constructor() {
    super(constants.IES_DEVICE_LIST_REQUESTED, 'deviceStatus');
    clientManager.add({
      name: 'deviceList',
      authHeader: 'authorization',
      tokenPrefix: 'Bearer ',
      baseUrl: env.get('MARIN_API_BASE_URL'),
      timeout: env.get('MARIN_API_TIMEOUT'),
      headers: { 'X-TenantId': env.get('ETEXT') }
    });
  }

  handleEvent(eventName, event) { // eslint-disable-line class-methods-use-this
    let payload;
    switch (event.type) {
      case constants.REGISTER:
        const deviceRegisterEndPoint = '/dm/devices';
        payload = {
          deviceId: event.payload
        };

        return clientManager.get('deviceList').post(deviceRegisterEndPoint, payload);
      case constants.SWAP:
        Framework.getStoreRegistry().getStore('device').setLoader(true);
        const deviceSwapEndPoint = '/dm/swap';
        const { addedDeviceId, removedDeviceId } = event.payload;
        payload = {
          addedDeviceId,
          removedDeviceId
        };

        return clientManager.get('deviceList').post(deviceSwapEndPoint, payload);
      case constants.DISCONNECT:
        const deviceDeleteEndPoint = `/dm/devices/${event.payload.deviceId}`;

        return clientManager.get('deviceList').delete(deviceDeleteEndPoint);
      case constants.VIOLATION:
        const violationEndPoint = `/dm/webstatus/${event.data.deviceId}`;

        return clientManager.get('deviceList').get(violationEndPoint);
      case constants.GENERATE_OTP:
        const generateOTPEndPoint = '/dm/otp/token';

        return clientManager.get('deviceList').post(generateOTPEndPoint, event.data);
      case constants.VALIDATE_OTP:
        const validateOTPEndPoint = '/dm/otp/redeem';

        return clientManager.get('deviceList').post(validateOTPEndPoint, event.data);
      case constants.THREAT_STATUS:
        const threatStatusEndPoint = `/dm/accountstatus/devices/${event.data.deviceId}?isNativeMobile=false`;

        return clientManager.get('deviceList').get(threatStatusEndPoint);
      case constants.WARNING_DISPLAYED:
        const warningEndPoint = '/dm/accountwarningdisplayed';

        return clientManager.get('deviceList').post(warningEndPoint);
      case constants.ACCOUNT_LOCK:
        const accountLockEndPoint = `/dm/accountaction/users/${event.data.userId}/devices/${event.data.deviceId}`;

        return clientManager.get('deviceList').post(accountLockEndPoint);
      default:
        const deviceListEndPoint = `/dm/devices?includeDeviceDetails=${event.payload.includeDeviceDetails}`;

        return clientManager.get('deviceList').get(deviceListEndPoint);
    }
  }

  handleResponse(response, event) { // eslint-disable-line class-methods-use-this
    switch (event.type) {
      case constants.LIST:
        Framework.getStoreRegistry().getStore('device').set(response.data);
        if ((typeof event.payload.includeDeviceDetails !== 'undefined' && !event.payload.includeDeviceDetails)) {
          Framework.getEventManager().publish(constants.DEVICE_LIST_REQUEST_SUCCESS, response.data);
        }
        break;
      case constants.DISCONNECT:
        const device = { ...Framework.getStoreRegistry().getStore('device') };
        if (device && device.devices) {
          // eslint-disable-next-line no-shadow
          device.devices = device.devices.filter((device) => device.deviceId !== event.payload.deviceId);
          Framework.getStoreRegistry().getStore('device').set(device);
          Framework.getEventManager().publish(constants.DEVICE_LIST_REQUEST_SUCCESS, device);
        }
        break;
      case constants.SWAP:
        Framework.getStoreRegistry().getStore('device').swapSuccess();
        break;
      case constants.VIOLATION:
        Framework.getStoreRegistry().getStore('device').setData(response.data);
        Framework.getEventManager().publish(constants.DEVICE_VIOLATION_SUCCESS, response.data);
        break;
      case constants.GENERATE_OTP:
        Framework.getStoreRegistry().getStore('device').setData(response.data);
        Framework.getEventManager().publish(constants.DEVICE_OTP_GENERATE_SUCCESS, response.data);
        break;
      case constants.VALIDATE_OTP:
        Framework.getStoreRegistry().getStore('device').setData(response.data);
        Framework.getEventManager().publish(constants.DEVICE_OTP_VALIDATE_SUCCESS, response.data);
        break;
      case constants.REGISTER:
        Framework.getEventManager().publish(constants.DEVICE_REGISTER_SUCCESS, response.data);
        break;
      case constants.THREAT_STATUS:
        Framework.getEventManager().publish(constants.THREAT_STATUS_SUCCESS, response.data);
        break;
      case constants.WARNING_DISPLAYED:
        Framework.getEventManager().publish(constants.DEVICE_WARNING_SUCCESS, response.data);
        break;
      case constants.ACCOUNT_LOCK:
        Framework.getEventManager().publish(constants.ACCOUNT_LOCK_SUCCESS, response.data);
        break;
      default:
        break;
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    if (event.type === constants.SWAP && error.response.status === 403) {
      Framework.getStoreRegistry().getStore('device').setError();
    }
    if (event.type === constants.VALIDATE_OTP) {
      Framework.getEventManager().publish(constants.DEVICE_OTP_VALIDATE_FAILURE, error.data);
    }
    Utils.dispatchGaEvent(constants.LOAD_ERROR, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_LABEL, error.data);
  }
}
