import React, { useState, useRef } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, TextField, Button, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import messages from '../../defaultMessages';
import FeedbackStyles from './FeedbackStyles';
import * as constants from '../../Common/constants';

function PositiveFeedback({
  classes,
  intl,
  handleSubmitPositiveFeedback,
  handleClose,
  isClickAway
}) {
  const { formatMessage } = intl;
  const [inputValue, setInputValue] = useState('');
  const [isClose, setIsClose] = useState(false);
  const inputFocusRef = useRef(null);
  const submitButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitPositiveFeedback(inputValue);
    } else if (event.key === 'Tab' && !inputValue) {
      event.preventDefault();
      cancelButtonRef.current.focus();
    } else if (event.shiftKey && event.key === 'Tab' && inputValue) {
      event.preventDefault();
      submitButtonRef.current.focus();
    }
  };
  const handleCloseKeyDown = (event) => {
    if (!event.shiftKey && event.key === 'Tab' && !inputValue) {
      event.preventDefault();
      inputFocusRef.current.focus();
    } else if (!event.shiftKey && event.key === 'Tab' && inputValue) {
      event.preventDefault();
      submitButtonRef.current.focus();
    } else if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      inputFocusRef.current.focus();
    }
  };
  const handleCloseClick = () => {
    setIsClose(true);
    setTimeout(() => {
      handleClose(constants.POSITIVE_FEEDBACK);
    }, 200); // After animation duration time closed the feedback popup
  };
  const handleSubmit = (event) => {
    if (!event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      inputFocusRef.current.focus();
    } else if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      cancelButtonRef.current.focus();
    } else if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      setIsClose(true);
      setTimeout(() => {
        handleSubmitPositiveFeedback(inputValue);
      }, 150);
    }
  };
  const handleSubmitClick = () => {
    setIsClose(true);
    setTimeout(() => {
      handleSubmitPositiveFeedback(inputValue);
    }, 150);
  };
  return (
    <Box className={`${classes.feedbackContainer} ${isClose || isClickAway ? classes.slideDown : classes.slideUp}`} aria-labelledby="positiveFeedbackHeading">
      <Box className={classes.feedbackContainer__Header} id="positiveFeedback">
        <Typography id="positiveFeedbackHeading" className={classes.feedbackContainer__Header__text} variant="h4">{formatMessage(messages.positiveFeedbackHeading)}</Typography>
        <Typography id="positiveFeedbackHeading" className={classes.feedbackDescription} variant="h6">{formatMessage(messages.positiveFeedbackDescription)}</Typography>
      </Box>
      <TextField
        id="inputTextBox"
        inputRef={inputFocusRef}
        autoFocus
        InputProps={{
          classes: {
            root: `${classes.textBoxStyle} ${classes.textBoxAlignmentTop}`,
            input: classes.textBoxInputStyle,
            underline: classes.underlineStyle
          }
        }}
        multiline
        minRows={1}
        fullWidth
        placeholder={formatMessage(messages.positiveFeedbackPlaceHolder)}
        value={inputValue}
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => handleInputChange(event)}
      />
      <div className={classes.feedbackActionWrapper}>
        <Button
          id="positiveFeedbackCancel"
          ref={cancelButtonRef}
          className={classes.cancelStyle}
          onClick={handleCloseClick}
          onKeyDown={handleCloseKeyDown}
        >
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          id="summary__PositiveFeedback__submit"
          ref={submitButtonRef}
          disabled={!inputValue}
          classes={{ disabled: classes.sendButtonDisabled }}
          className={classes.sendButton}
          onClick={handleSubmitClick}
          onKeyDown={handleSubmit}
        >
          {formatMessage(messages.submit)}
        </Button>
      </div>
    </Box>
  );
}

PositiveFeedback.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  handleSubmitPositiveFeedback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isClickAway: PropTypes.bool.isRequired
};

export default withStyles(FeedbackStyles)(injectIntl(PositiveFeedback));
