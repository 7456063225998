/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import uuidv1 from 'uuid/v1';
import { EventProvider } from '@aquila/core';
import * as constants from '../common/constants';

/**
 * @author Hari Gangadharan
 */
export default class Utils {
  static uuid() {
    return uuidv1();
  }

  static isInIframe() {
    try {
      return window.self !== window.top;
    // eslint-disable-next-line no-unused-vars
    } catch (e) {
      return true;
    }
  }

  static getQueryParameterbyName = (name, searchParams) => {
    const queryParams = searchParams || window.location.search;
    const queryParamObject = new URLSearchParams(queryParams);

    return queryParamObject.get(name);
  };

  static getUserAgent() {
    return window.navigator.userAgent || window.navigator.vendor || window.opera;
  }

  static getOS() {
    const userAgent = Utils.getUserAgent();
    let systemOs = constants.DESKTOP_OS;
    if (/windows phone/i.test(userAgent)) {
      systemOs = constants.MOBILE_OS_WINDOWS_PHONE;
    }

    if (/android/i.test(userAgent)) {
      systemOs = constants.MOBILE_OS_ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      systemOs = constants.MOBILE_OS_IOS;
    }

    return systemOs;
  }

  static transformErrorCode(messageNode, error) {
    let errorString = 'errors.timeout';
    if (error && error.response) {
      switch (error.response.status) {
        case 404: // not found
          errorString = `${messageNode}.404`;
          break;
        default:
          errorString = 'errors.general';
      }
    }

    return errorString;
  }

  static isStorageSupported() {
    return ((typeof (Storage) !== 'undefined'));
  }

  static parseCookie() {
    return document.cookie.split(';').reduce((cookieObject, cookieString) => {
      const splitCookie = cookieString.split('=');
      /* eslint-disable no-param-reassign */
      try {
        cookieObject[splitCookie[0].trim()] = decodeURIComponent(splitCookie[1]);
      // eslint-disable-next-line no-unused-vars
      } catch (error) {
        cookieObject[splitCookie[0].trim()] = splitCookie[1];
      }

      /* eslint-enable no-param-reassign */
      return cookieObject;
    }, {});
  }

  /**
   * A tree walk implementation. Invokes callback with each node in the tree.
   *
   * @param node a node of the tree
   * @param parent the parent of the current node of tree
   * @param childFieldName the child field name
   * @param depth the depth to traverse (-ve for unlimited levels)
   * @param callback the callback function to be invoked on each node. Breaks walk if this function return false.
   */
  static treeWalk(node, parent, childFieldName, depth, callback) {
    const walkDepth = depth;
    if (Array.isArray(node)) {
      for (let i = 0; i < node.length; i += 1) {
        if (!Utils.treeWalk(node[i], parent, childFieldName, walkDepth, callback)) {
          return false;
        }
      }

      return true;
    }
    if (node && callback(node, parent)) {
      if (node[childFieldName] && walkDepth !== 0) {
        for (let i = 0; i < node[childFieldName].length; i += 1) {
          if (!Utils.treeWalk(node[childFieldName][i], node, childFieldName, walkDepth - 1, callback)) {
            return false;
          }
        }
      }

      return true;
    }

    return false;
  }

  /**
   *
   * @param {string} browserLanguage
   * @returns selected language from browser if available in app
   */
  static getLanguage(browserLanguage) {
    let browserLanguageSelected = browserLanguage;

    switch (browserLanguage) {
      case constants.TRADITIONAL_CHINESE_BROWSER:
        browserLanguageSelected = constants.TRADITIONAL_CHINESE;
        break;
      case constants.GERMAN_GERMANY_BROWSER:
        browserLanguageSelected = constants.GERMAN_GERMANY;
        break;
      case constants.SPANISH_SPANISH_BROWSER:
        browserLanguageSelected = constants.SPANISH_SPANISH;
        break;
      default:
        browserLanguageSelected = browserLanguage;
    }

    return browserLanguageSelected;
  }

  static getLocalIsoTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }

  static getUUID() {
    const crypto = window.crypto || window.msCrypto;

    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line
      (crypto && (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)));
  }

  static dispatchGaEvent(eventCategory, event, action, label, value) {
    const eventContent = {
      event_category: eventCategory,
      event_action: action,
      event_label: label,
      event,
      event_name: event,
      event_value: value,
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime(),
      event_unix_timestamp: new Date().getTime(),
      message_id: this.getUUID()
    };
    EventProvider.getEventManager().dispatch(eventContent);
  }

  /**
   * To identify whether query param is present or not with actual value
   *
   * @param {*} query
   * @param {*} value
   * @returns
   */
  static isQueryParamAvailable(query, value) {
    const isQueryExist = this.getQueryParameterbyName(query);

    return (isQueryExist && isQueryExist.toLowerCase() === value) || false;
  }

  /**
   * To initate DM flow with getDeviceList
   *
   * @param {*} isImpersonatedId
   * @param {*} delay
   */
  static getDeviceList(isImpersonatedId, delay = 0) {
    const deviceListEvent = `IES${constants.DEVICE_LIST_REQUESTED}`;
    const payload = { includeDeviceDetails: false };
    if (isImpersonatedId) {
      payload.includeDeviceDetails = true;
    }
    setTimeout(() => { EventBus.publish(deviceListEvent, { type: 'list', payload }); }, delay);
  }
}
