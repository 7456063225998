/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * English Language Resource file.
 *
 * @author Hari Gangadharan
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Continue',
  'browser.check.DONT_SHOW_AGAIN': 'Don\'t show me this again',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'supported operating system and web browser',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Your browser <<name>> is out of date',
  'browser.check.BROWSER_MESSAGE_DESC': 'For the best experience, select one of the latest browsers:',
  'browser.check.BROWSER_SKIP_NOW': 'Skip for now',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'About supported browsers',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Your browser, <<name>> is not supported',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'For the best experience, select one of the supported browsers.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Your operating system, <<name>> is not supported',
  'browser.check.OS_UNSUPPORTED_DESC': 'While the product may work with no issues, please be aware that any problems '
    + 'you encounter might be resolved by trying with a device running a <<link>>',
  'errors.GENERIC_TITLE': 'Server Error',
  'errors.GENERIC_DESC': 'Sorry, our system is down. Please try again later or contact support.',
  'errors.HTTP_400_TITLE': 'Server Error',
  'errors.HTTP_400_DESC': 'We encountered an error while processing your request.',
  'errors.HTTP_401_TITLE': 'Are you logged in?',
  'errors.HTTP_401_DESC': 'We could not fetch the data since your login session might have expired.',
  'errors.HTTP_403_TITLE': 'Unauthorized',
  'errors.HTTP_403_DESC': 'You don\'t have access to this resource.',
  'errors.HTTP_888_TITLE': 'Server was too slow to respond',
  'errors.HTTP_888_DESC': 'We encountered an error while processing your request.'
};

export default RESOURCES;
