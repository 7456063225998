/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { addLocaleData } from 'react-intl';
import LocaleSupport from '../i18n/LocaleSupport';

export const AddLocaleData = (locale) => {
  const lang = locale || 'en';
  // load locale data
  const parentLocale = lang.split('-')[0];
  try {
    addLocaleData(require(`react-intl/locale-data/${parentLocale}`));// eslint-disable-line
  // eslint-disable-next-line no-unused-vars
  } catch (e) {
    // TODO: need to add logger
    // console.log(e);
  }
};
/**
 * A Builder class that builds the internationalization support.
 *
 * @author Hari Gangadharan
 */
export default class IntlBuilder {
  constructor() {
    this.translations = new Map();
    this.defaultLang = null;
    this.psuedoLocaleCode = null;
  }

  add(langCode, messages) {
    if (!langCode) {
      throw new Error('Language code is empty.');
    }
    if (this.translations.has(langCode)) {
      throw new Error(`Language ${langCode} is already defined.`);
    }
    this.translations.set(langCode, messages);
    AddLocaleData(langCode);

    return this;
  }

  addPseudoLocale(pseudoLocaleCode) {
    if (pseudoLocaleCode) {
      this.psuedoLocaleCode = pseudoLocaleCode;
      AddLocaleData(pseudoLocaleCode);
    } else {
      this.psuedoLocaleCode = null;
    }

    return this;
  }

  setDefaultLang(langCode) {
    this.defaultLang = langCode;

    return this;
  }

  build() {
    const localeSupport = LocaleSupport.getInstance();
    localeSupport.setDefaultLang(this.defaultLang);
    localeSupport.setTranslations(this.translations);
    localeSupport.setPseudoLocaleCode(this.psuedoLocaleCode);
    localeSupport.checkValidity();

    return localeSupport;
  }
}
