/* eslint-disable no-unused-vars */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file FreeResponseQuizResult.jsx
 * @author Arifhusain Soudagar
 */

import React, {
  useState, useEffect, useRef, memo
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Link,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  Menu,
  MenuItem
} from '@material-ui/core';
import { Utilities } from '@aquila/core';
import PropTypes from 'prop-types';
import * as constants from '../../Common/constants';
import PositiveFeedback from './PositiveFeedback';
import NegativeFeedback from './NegativeFeedback';
import ExportIcon from '../../Common/icons/ExportIcon';
import LikeIcon from '../../Common/icons/LikeIcon';
import LikeIconFilled from '../../Common/icons/LikeIconFilled';
import DisLikeIcon from '../../Common/icons/DisLikeIcon';
import DisLikeIconFilled from '../../Common/icons/DisLikeIconFilled';
import messages from '../../defaultMessages';
import CopyIcon from '../../Common/icons/CopyIcon';
import { eventAction, gaEvents } from '../../analytics/analytics';
import FeedbackStyles from './FeedbackStyles';
import FlashCardIcon from '../../Common/icons/FlashCardIcon';
import Utils from '../../Common/Utils';
import { clickThumbsGAEvent } from '../../analytics/analyticsEvents';

const FreeResponseQuizResult = memo(({
  classes,
  intl,
  summaryData,
  pageRange,
  selectedChapter,
  pageId,
  selectedOptionType,
  chapterList,
  pageList,
  handleGAEvents,
  handleSubmitFeedback,
  theme,
  masterTheme,
  isReadyNext,
  onCopy,
  isLastQuestion,
  feedbackIconFocusId,
  chapterId,
  chapterTitle,
  onQuizStop,
  pageIds,
  onShowNextQuestion,
  onFlashCardClick,
  isFromMultipleChoiceFlow,
  currentQuestion,
  handleNextQuizQuestion,
  chapterData
}) => {
  const { formatMessage } = intl;
  const [showExportOptions, setExportOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const [disabled, setDisabled] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const explainRef = useRef(null);
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const [isClickAway, setIsClickAway] = useState(false);

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  const handleQuizStop = () => {
    explainRef.current.focus();
    setDisabled(true);
    onQuizStop();
  };

  useEffect(() => {
    if (isLastQuestion) handleQuizStop(true);
  }, [isLastQuestion]);

  const handleQuizStart = () => {
    setDisabled(true);
    onShowNextQuestion(pageIds);
  };

  const onExportClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setExportOptions(!showExportOptions);
  };

  const getChapterDetails = () => {
    let chapterDetails = {
      chapterId: '',
      chapterTitle: ''
    };
    let filteredChapterList = null;
    const pageArray = [];
    switch (selectedOptionType) {
      case constants.CURRENT_PAGE:
      case constants.CURRENT_SECTION:
        chapterDetails = pageId.length && chapterList.filter((chapter) => chapter.pageIds.find((pageNo) => pageNo === pageId[0]))[0];
        break;
      case constants.PAGE_RANGE:
        pageList.filter((page) => (page.pageNo >= Number(pageRange.startIndex)
          && page.pageNo <= Number(pageRange.endIndex)) && pageArray.push(page.pageId));
        filteredChapterList = chapterList.filter((chapter) => chapter.pageIds.find((pageNo) => pageArray.find((item) => item === pageNo)));
        if (filteredChapterList.length === 1) {
          chapterDetails = filteredChapterList[0];
        }
        break;
      case constants.CHAPTERS:
        filteredChapterList = chapterList.filter((chapter) => selectedChapter.find((item) => item === chapter.chapterTitle));
        if (filteredChapterList.length === 1) {
          chapterDetails = filteredChapterList[0];
        }
        break;
      case constants.CURRENT_CHAPTER:
        chapterDetails = {
          chapterId,
          chapterTitle
        };
        break;
      default:
        chapterDetails = {
          chapterId: '',
          chapterTitle: ''
        };
        break;
    }
    return chapterDetails || {};
  };

  const handleFlashCard = () => {
    onExportClick();
    onFlashCardClick(currentQuestion, pageIds, chapterData);
    const additionalFields = {
      event_label: 'Save as Flashcard',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.PRACTICE
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, constants.GPT_EVENTS.QUESTION, constants.GPT_EVENTS.PRACTICE_TYPE);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, constants.GPT_EVENTS.QUESTION, constants.GPT_EVENTS.PRACTICE_TYPE);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    let payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId,
      responseId: summaryData.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: summaryData.bulletPoints
    };
    setIsClickAway(false);
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
  };

  const handleSubmitPositiveFeedback = (value) => {
    const payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId,
      responseId: summaryData.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: summaryData.bulletPoints
    };
    setPostiveFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    const payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId,
      responseId: summaryData.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      data: summaryData.bulletPoints
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  const multiplChoiceAnswerValidation = () => ((summaryData && summaryData.result && summaryData.result.mcq && summaryData.result.mcq.reason) ? Utilities.textFromContent(summaryData.result.mcq.reason.replaceAll('\\\\', '\\')) : '');

  const handleCopy = () => {
    onExportClick();
    if (`${summaryData.requestId}_${currentQuestion.id}`) {
      const feedback = summaryData && summaryData.type === constants.MULTIPLE_CHOICE ? summaryData.result.mcq.reason : summaryData && summaryData.result && summaryData.result.freeForm && summaryData.result.freeForm;
      const copyTextId = document.getElementById(`${summaryData.requestId}_${currentQuestion.id}`);
      copyTextId.innerHTML = Utils.quizCopyTemplate((summaryData && summaryData.type), currentQuestion.question || currentQuestion.question_stem, currentQuestion && currentQuestion.options, summaryData.userAnswer, feedback, '');
      const copyText = copyTextId.innerHTML;
      Utils.exportCopyText(copyText);
      onCopy();
      copyTextId.innerHTML = '';
    }
    const additionalFields = {
      event_label: 'Copy',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.PRACTICE
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };

  return (
    <Box>
      <Box className={summaryData && summaryData.type === constants.MULTIPLE_CHOICE ? classes.actionMultiContainer : classes.actionContainer}>
        <Typography variant="p" className={`${classes.summaryData} ${classes.alignmentBottom}`} innerRef={explainRef}>
          {summaryData && summaryData.type === constants.MULTIPLE_CHOICE ? multiplChoiceAnswerValidation() : summaryData && summaryData.result && summaryData.result.freeForm && Utilities.textFromContent(summaryData.result.freeForm.replaceAll('\\\\', '\\'))}
        </Typography>
        <div className={classes.copyWrapperHidden} id={`${summaryData.requestId}_${currentQuestion.id}`} />
      </Box>
      <div className={!isConfirmed && !isLastQuestion && isReadyNext ? classes.buttonContainer : classes.defaultButtonContainer}>
        {
          !isConfirmed && isReadyNext && (
            (
              <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Next" title="Next" placement="top" arrow>
                <Button
                  onClick={() => {
                    setIsConfirmed(true);
                    handleNextQuizQuestion(null, null, null, null, null, false, pageIds, summaryData && summaryData.type === constants.FREE_RESPONSE ? constants.FREE_RESPONSE : constants.MULTIPLE_CHOICE, '', chapterData);
                  }}
                  className={classes.quizContainer__actions__buttons__next}
                >
                  Next
                </Button>
              </Tooltip>
            )
          )
        }
        <div className={classes.alignmentRight}>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as positive" title="Report as positive" placement="top" arrow>
            <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
              {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} /> : <LikeIcon masterTheme={masterTheme} theme={theme} />}
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as negative" title="Report as negative" placement="top" arrow>
            <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
              {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} />}
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Export" aria-haspopup title="Export" placement="top" arrow>
            <IconButton className={classes.feedbackButton} onClick={(event) => onExportClick(event)} aria-label="Export">
              <ExportIcon masterTheme={masterTheme} theme={theme} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {
        !isConfirmed && isReadyNext && (
          <div className={classes.doneContainer}>
            <Link
              href={false}
              onClick={() => {
                setIsConfirmed(true);
                handleQuizStop();
              }}
              component="button"
              variant="text"
              className={classes.doneAction}
              disabled={disabled}
            >
              {formatMessage(messages.no)}
            </Link>
          </div>

        )
      }
      {/* {
        isLastQuestion && (
          <Typography variant="p" className={classes.lastQuestionText}>
            That was the last question. What&apos;s next?
          </Typography>
        )
      } */}
      {
        showExportOptions && (
          <Menu
            id="exportOptions"
            open={showExportOptions}
            anchorEl={anchorEl}
            classes={{ paper: classes.exportList }}
            className={classes.poperStyle}
            keepMounted
            onClose={onExportClick}
          >
              {isFromMultipleChoiceFlow && (
              <MenuItem
                button
                onClick={handleFlashCard}
                classes={{
                  root: classes.listItemRootStyle
                }}
              >
                <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                  <FlashCardIcon masterTheme={masterTheme} theme={theme} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.textRootStyle }}>
                  {formatMessage(messages.saveFlashcards)}
                </ListItemText>
              </MenuItem>
              )}
            <MenuItem
              button
              classes={{
                root: `${classes.listItemRootStyle} ${classes.listAlignment}`
              }}
              onClick={handleCopy}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <CopyIcon masterTheme={masterTheme} theme={theme} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.copy)}
              </ListItemText>
            </MenuItem>
          </Menu>
        )
      }
      {
        showPositiveFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
          >
            <PositiveFeedback
              handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
              handleClose={handleClose}
              masterTheme={masterTheme}
              theme={theme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
      {
        showNegativeFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
          >
            <NegativeFeedback
              handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
              handleClose={handleClose}
              masterTheme={masterTheme}
              theme={theme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
    </Box>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

FreeResponseQuizResult.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  summaryData: PropTypes.object,
  pageRange: PropTypes.object,
  selectedChapter: PropTypes.array,
  pageId: PropTypes.string,
  selectedOptionType: PropTypes.string,
  chapterList: PropTypes.array,
  pageList: PropTypes.array,
  handleGAEvents: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  isReadyNext: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool,
  onCopy: PropTypes.func.isRequired,
  chapterId: PropTypes.string.isRequired,
  chapterTitle: PropTypes.string.isRequired,
  feedbackIconFocusId: PropTypes.object,
  onQuizStop: PropTypes.func,
  onShowNextQuestion: PropTypes.func,
  pageIds: PropTypes.array,
  onFlashCardClick: PropTypes.func,
  isFromMultipleChoiceFlow: PropTypes.bool,
  currentQuestion: PropTypes.object,
  handleNextQuizQuestion: PropTypes.func.isRequired,
  chapterData: PropTypes.object
};

FreeResponseQuizResult.defaultProps = {
  pageRange: {},
  selectedChapter: [],
  summaryData: {},
  feedbackIconFocusId: {},
  pageIds: [],
  onQuizStop: () => { },
  onShowNextQuestion: () => { },
  chapterList: [],
  pageList: [],
  selectedOptionType: '',
  pageId: '',
  isLastQuestion: false,
  onFlashCardClick: () => { },
  isFromMultipleChoiceFlow: false,
  currentQuestion: {},
  chapterData: {}
};

export default memo(withStyles(FeedbackStyles)(injectIntl(FreeResponseQuizResult)));
