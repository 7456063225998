/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatHistoryHeader.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography
} from '@material-ui/core';
import messages from '../../defaultMessages';
import ChatHistoryStyles from './ChatHistoryStyles';
import HistoryCollapseIcon from '../../Common/icons/HistoryCollapseIcon';

const ChatHistoryHeader = ({
  intl,
  classes,
  masterTheme,
  theme,
  onClose
}) => {
  const { formatMessage } = intl;
  return (
    <div className={classes.chatHistoryHeader}>
      <Typography variant="h2" className={classes.chatHistoryTitle}>
        {formatMessage(messages.chatHistory)}
      </Typography>
      <IconButton
        id="history_close_button"
        onClick={onClose}
        className={classes.historyCloseButton}
      >
        <HistoryCollapseIcon
          masterTheme={masterTheme}
          theme={theme}
        />
      </IconButton>
    </div>
  );
};

ChatHistoryHeader.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  masterTheme: PropTypes.string,
  theme: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

ChatHistoryHeader.defaultProps = {
  masterTheme: 'plus',
  theme: 'default'
};

export default withStyles(ChatHistoryStyles)(injectIntl(ChatHistoryHeader));
