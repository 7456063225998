/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import ulog from 'ulog';
import history from '../common/history';

import ModuleBuilder from './ModuleBuilder';
import ClientManager from './ClientManager';
import AuthService from '../services/AuthService';
import StoreRegistry from './StoreRegistry';
import IntlBuilder from './IntlBuilder';
// eslint-disable-next-line import/no-cycle
import IesAuthStrategy from '../services/strategies/IesAuthStrategy';
import IntAuthStrategy from '../services/strategies/IntAuthStrategy';
import Utils from '../utils/Utils';

const storeRegistry = new StoreRegistry();
const authService = new AuthService(storeRegistry);
const clientManager = new ClientManager(authService);
const moduleBuilder = new ModuleBuilder(storeRegistry);
const intlBuilder = new IntlBuilder();

/**
 * Greenville Framework - A React Mobx based framework.
 *
 * @author Hari Gangadharan
 */
export default class Framework {
  static isDeviceMangementActivated = false;

  static getAuthService() {
    return authService;
  }

  static getClientManager() {
    return clientManager;
  }

  static getLogger(scriptName) {
    return ulog(scriptName);
  }

  static getModuleBuilder() {
    return moduleBuilder;
  }

  static getStoreRegistry() {
    return storeRegistry;
  }

  static getIntlBuilder() {
    return intlBuilder;
  }

  static init() {
    authService.init();
  }

  static register(authType, clientId, correlationIdPrefix, sessionDuration, enableSessionTimedOutEvent) {
    authService.register('IES', new IesAuthStrategy(authService));
    authService.register('INT', new IntAuthStrategy(authService));
    authService.setAuthStrategy(authType, clientId, correlationIdPrefix, sessionDuration, enableSessionTimedOutEvent);
  }

  static redirectTo(path) {
    history.push(path);
  }

  static redirectToErrorPage(errorCode, buttonTextKey, buttonHref) {
    let url = `/errors/${errorCode}`;
    if (buttonHref) {
      url += `?url=${encodeURIComponent(buttonHref)}`;
      url += buttonHref ? `&textKey=${buttonTextKey}` : '';
    }
    history.push(url);
  }

  static logout(requireLogin = false) {
    authService.logout(requireLogin);
  }

  static getUserProfile(userId) {
    authService.getUserProfile(userId);
  }

  /**
   * Method to update user profile details
   *
   * payLoad - update payload
   * isSilentUpdate - set to true to suppress status response
   */
  static updateUserProfile(payLoad, isSilentUpdate) {
    authService.updateUserProfile(payLoad, isSilentUpdate);
  }

  static getEventManager() {
    return EventBus;
  }

  /**
   * To initiate device management
   *
   * @param {*} isImpersonatedId
   * @param {*} delay
   */
  static initiateDeviceManagement(isImpersonatedId, delay = 0) {
    if (!Framework.isDeviceMangementActivate) {
      Utils.getDeviceList(isImpersonatedId, delay);
    }
    Framework.isDeviceMangementActivate = true;
  }
}
