/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
export const AUTH_VERIFIED = 'AUTH_VERIFIED';
export const HISTORY_REPLACE_REQUESTED = 'HISTORY_REPLACE_REQUESTED';
export const LOGIN_REFRESH_TIME = 14 * 60 * 1000; // 14 min
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const ACTIVE = 'ACTIVE';
export const PAUSED = 'PAUSED';
export const USER_PROFILE_REQUESTED = '_USER_PROFILE_REQUESTED';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATE_FAILED = 'USER_PROFILE_UPDATE_FAILED';
export const INT_USER_PROFILE_REQUESTED = 'INT_USER_PROFILE_REQUESTED';
export const INT_USER_LOGOUT_REQUESTED = 'INT_USER_LOGOUT_REQUESTED';
export const IES_USER_PROFILE_REQUESTED = 'IES_USER_PROFILE_REQUESTED';
export const IES_DEVICE_LIST_REQUESTED = 'IES_DEVICE_LIST_REQUESTED';
export const DEVICE_LIST_REQUESTED = '_DEVICE_LIST_REQUESTED';
export const DEVICE_LIST_REQUEST_SUCCESS = 'DEVICE_LIST_REQUEST_SUCCESS';
export const DEVICE_SWAP_SUCCESS = 'DEVICE_SWAP_SUCCESS';
export const DEVICE_VIOLATION_SUCCESS = 'DEVICE_VIOLATION_SUCCESS';
export const DEVICE_OTP_VALIDATE_SUCCESS = 'DEVICE_OTP_VALIDATE_SUCCESS';
export const DEVICE_OTP_VALIDATE_FAILURE = 'DEVICE_OTP_VALIDATE_FAILURE';
export const DEVICE_REGISTER_SUCCESS = 'DEVICE_REGISTER_SUCCESS';
export const DEVICE_OTP_GENERATE_SUCCESS = 'DEVICE_OTP_GENERATE_SUCCESS';
export const BROWSER_CHECK_REQUESTED = 'BROWSER_CHECK_REQUESTED';
export const GTM_REQUESTED = 'GTM_REQUESTED';
export const GPC_PREFERENCE_RETRIEVE = 'PREFERENCE_RETRIEVE';
export const GPC_PREFERENCE_RETRIEVE_SUCCESS = 'GPC_PREFERENCE_RETRIEVE_SUCCESS';
export const THREAT_STATUS_SUCCESS = 'THREAT_STATUS_SUCCESS';
export const DEVICE_WARNING_SUCCESS = 'DEVICE_WARNING_SUCCESS';
export const ACCOUNT_LOCK_SUCCESS = 'ACCOUNT_LOCK_SUCCESS';

export const DESKTOP_OS = 'DESKTOP_OS';
export const MOBILE_OS_IOS = 'IOS';
export const MOBILE_OS_ANDROID = 'Android';
export const MOBILE_OS_WINDOWS_PHONE = 'Windows Phone';

export const TRADITIONAL_CHINESE = 'zh-hk';
export const TRADITIONAL_CHINESE_BROWSER = 'zh-TW';
export const GERMAN_GERMANY_BROWSER = 'de-DE';
export const GERMAN_GERMANY = 'de';
export const SPANISH_SPANISH_BROWSER = 'es-ES';
export const SPANISH_SPANISH = 'es';
export const PLUS = 'plus';
export const ETEXT = 'etext-ise';
export const LOCAL = 'local-dev';
export const REGISTER = 'register';
export const SWAP = 'swap';
export const DISCONNECT = 'disconnect';
export const VIOLATION = 'violation';
export const GENERATE_OTP = 'generateOTP';
export const VALIDATE_OTP = 'validateOTP';
export const LIST = 'list';
export const LD_PRODUCT_KEY = 'e-text';
export const THREAT_STATUS = 'threatStatus';
export const WARNING_DISPLAYED = 'warningDisplayed';
export const ACCOUNT_LOCK = 'accountLock';
export const LOAD_ERROR = 'Load error';
export const LOAD_ERROR_NAME = 'loaderror';
export const LOAD_ERROR_LABEL = 'Can\'t access Etext';
export const PEARSON_SESSION = 'PEARSON_SESSION';
export const TPI = 'isTpi';
