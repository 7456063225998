/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ImageContext.jsx
 * @author Srikanth Vadla
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, Typography, Card, CardActions, CardContent, CardMedia, Link
} from '@material-ui/core';
import ThemeConstants from '../../Common/ThemeConstants';
import * as constants from '../../Common/constants';
import messages from '../../defaultMessages';

const Styles = () => ({
  imageContextMainContainer: {
    padding: '0px 0px 10px 0px'
  },
  imageContextBtnContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: 10
  },
  imageContextBtnStyle: {
    maxWidth: '85%',
    borderRadius: '8px',
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].predefinedBtnsBgColor,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    textTransform: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgHoverColor,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
    },
    '& span': {
      textAlign: 'left',
      margin: '5px 10px'
    }
  },
  titleStyle: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    marginTop: 16
  },
  cardContainer: {
    background: 'transparent',
    padding: '13px 16px',
    marginBottom: 10,
    borderRadius: 6,
    border: (props) => ThemeConstants[props.masterTheme][props.theme].borderColor,
    boxShadow: 'none'
  },
  cardContentRootStyle: {
    padding: '0px 0px 10px'
  },
  figureTitleStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    fontWeight: 600,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColorS
  },
  figureDescriptionStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    fontWeight: 400,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColorS
  },
  cardActions: {
    justifyContent: 'flex-end',
    padding: 0,
    display: 'flex'
  },
  linkStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    fontWeight: 'bold',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].faqColor,
    textDecoration: 'underline',
    '&:hover': {
      color: (props) => ThemeConstants[props.masterTheme][props.theme].faqColor,
      textDecoration: 'underline'
    }
  },
  mediaRootStyle: {
    marginBottom: 10,
    width: 'auto',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'fill'
  }
});

const ImageContext = React.memo(({
  classes,
  intl,
  imageContextData,
  onImagePromptClick,
  onPageNavigation,
  disableOptions
}) => {
  const { formatMessage } = intl;
  const {
    contextUrl,
    contextFigureId,
    contextTitle,
    pageId,
    contextId
  } = imageContextData;
  const getPromptList = () => (
    <div className={`${classes.imageContextBtnContainer}`}>
      {
        constants.IMAGE_CO_PILET_PROMPT_ARRAY.map((prompt) => (
          <Button
            component="button"
            onClick={() => { onImagePromptClick(prompt); }}
            className={`${classes.imageContextBtnStyle}`}
          >
            { prompt.title }
          </Button>
        ))
      }
    </div>
  );
  return (
    <>
      {contextUrl && (
        <div className={`${classes.imageContextMainContainer}`} id="imageContext_prompt_container">
          <Card className={classes.cardContainer} variant="outlined">
            {
              contextFigureId && (
                <CardContent classes={{ root: classes.cardContentRootStyle }}>
                  <Typography variant="body2" component="p" className={classes.figureTitleStyle}>
                    {contextFigureId}
                  </Typography>
                </CardContent>
              )
            }
            <CardMedia
              component="img"
              alt={contextTitle}
              image={contextUrl}
              title={contextTitle}
              classes={{ root: classes.mediaRootStyle }}
            />
            {/* {
              contextTitle && (
                <CardContent classes={{ root: classes.cardContentRootStyle }}>
                  <Typography variant="body2" component="p" className={classes.figureDescriptionStyle}>
                    {contextTitle}
                  </Typography>
                </CardContent>
              )
            } */}
            { pageId && (
            <CardActions className={classes.cardActions}>
              <Link
                component="button"
                variant="body2"
                className={classes.linkStyle}
                onClick={() => { onPageNavigation(pageId, contextId); }}
              >
                {
                      formatMessage(messages.viewInContent)
                    }
              </Link>
            </CardActions>
            )}
          </Card>
          { !disableOptions && (
          <div>
            <Typography variant="paragraph" className={classes.titleStyle}>
              { formatMessage(messages.imageCopilotHelperText) }
            </Typography>
            {
                  getPromptList()
                }
          </div>
          )}
        </div>
      )}
    </>
  );
});
ImageContext.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  imageContextData: PropTypes.object.isRequired,
  onImagePromptClick: PropTypes.func.isRequired,
  onPageNavigation: PropTypes.func.isRequired,
  disableOptions: PropTypes.bool
};

ImageContext.defaultProps = {
  disableOptions: false
};

export default withStyles(Styles)(injectIntl(ImageContext));
