/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Quiz.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Typography } from '@material-ui/core';
import { intlShape, injectIntl } from 'react-intl';
import messages from '../../defaultMessages';
import ThemeConstants from '../../Common/ThemeConstants';

const styles = () => ({
  NextQuestionPromptWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    width: '100%'
  },
  NextQuestionPromptContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // background: '#e8eefa',
    borderRadius: '18px',
    boxSizing: 'border-box'
  },
  NextQuestionPromptContainer__question: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  NextQuestionPromptContainer__actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    gap: 10
  },
  NextQuestionPromptContainer__actions__no: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  NextQuestionPromptContainer__actions__yes: {
    padding: '8px 24px',
    width: 76,
    height: 40,
    border: '1px solid #BCC1CB',
    borderRadius: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none'
  },
  NextQuestionPromptContainer__actions__yes__textNode: {
    width: 28,
    height: 24,
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '0.4px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  disabledButtonStyle: {
    opacity: '.5',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  }
});

const NextQuestionPrompt = (props) => {
  const {
    classes,
    intl: { formatMessage },
    onShowNextQuestion,
    onQuizStop,
    pageIds
  } = props;
  const [disabled, setDisabled] = useState(false);

  const handleQuizStop = () => {
    setDisabled(true);
    onQuizStop();
  };
  const handleQuizStart = () => {
    setDisabled(true);
    onShowNextQuestion(pageIds);
  };
  return (
    <div className={classes.NextQuestionPromptWrapper}>
      <div className={classes.NextQuestionPromptContainer}>
        <Typography className={classes.NextQuestionPromptContainer__question} variant="p">
          Ready for next question?
        </Typography>
        <div className={classes.NextQuestionPromptContainer__actions}>
          <Button
            disabled={disabled}
            onClick={handleQuizStart}
            className={classes.NextQuestionPromptContainer__actions__yes}
            classes={{ disabled: classes.disabledButtonStyle }}
          >
            {formatMessage(messages.yes)}
          </Button>
          <Link
            href={false}
            onClick={handleQuizStop}
            component="button"
            variant="text"
            className={classes.NextQuestionPromptContainer__actions__no}
            disabled={disabled}
          >
            {formatMessage(messages.no)}
          </Link>
        </div>
      </div>
    </div>
  );
};

NextQuestionPrompt.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  onShowNextQuestion: PropTypes.func.isRequired,
  onQuizStop: PropTypes.func.isRequired,
  pageIds: PropTypes.array.isRequired
};

export default withStyles(styles)(injectIntl(NextQuestionPrompt));
