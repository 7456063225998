/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import ulog from 'ulog';

/**
 * Overrides of environment properties for Prod. These override by base definitions.
 *
 * @author Hari Gangadharan
 */
const prod = {
  LOG_LEVEL: ulog.INFO,
  IES_BASE_URL: 'https://login.pearson.com/v1/piapi',
  FR_BASE_URL: 'https://mycloud.pearson.com/redirect',
  USER_PROFILE_BASE_URL: 'https://tiburon.prd-prsn.com/api/1.0',
  BROWSER_CHECK_SCRIPT: 'https://e2e-comms.pearson.com/osbrowserchecker/prd/checker-module.min.js',
  BROWSER_CHECK_STYLE: 'https://e2e-comms.pearson.com/osbrowserchecker/prd/checker-module.min.css',
  MARIN_API_BASE_URL: 'https://plus.pearson.com/marin/api/1.0',
  MARIN_API_TIMEOUT: 10000,
  ETEXT: 'ETEXT',
  MOJO_DOMAIN: 'plus.pearson.com',
  ETEXT_DOMAIN: 'plus.pearson.com',
  PMC_SIGNIN_URL: 'https://www.pearson.com/store/en-us/pearsonplus/logout?sessionTimedOut=false&consoleHomePageUrl=https://www.pearson.com/store/en-us/pearsonplus/login',
  SSI_PMC_SIGNIN_URL: 'https://www.pearson.com/store/en-us/pearsonplus/login',
  LAUNCH_DARKLY_BASE_URL: 'https://app.launchdarkly.com/api/v2',
  LD_ENVIRONMENT: 'production',
  LD_API_ACCESS_KEY: 'api-deab7479-c46e-4f6d-8df4-2914294f2941',
  CP_URL: 'https://etext-sms.navajo.pearsoncanada.ca/etext_login/login.html'
};
export default prod;
