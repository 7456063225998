/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import base from './base';
import stg from './stg';
import qa from './qa';
import qaInt from './qaInt';
import perf from './perf';
import prod from './prod';
import local from './local';

/**
 * A holder for currently selected environment values.
 *
 * @author Hari Gangadharan
 */
class EnvHolder {
  setEnv(envName, config = {}) {
    let env;
    switch (envName) {
      case 'stg':
        env = stg;
        break;
      case 'local':
        env = local;
        break;
      case 'qaInt':
        env = qaInt;
        break;
      case 'qa':
        env = qa;
        break;
      case 'perf':
        env = perf;
        break;
      case 'prod':
        env = prod;
        break;
      default:
        env = stg;
    }
    this.env = { ...base, ...env, ...config };
  }

  get(name) {
    if (!this.env) {
      throw new Error('Env not set!');
    }

    return this.env[name];
  }
}

export default new EnvHolder();
