/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useEffect, memo
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  MenuItem,
  Menu,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Utilities } from '@aquila/core';
import PositiveFeedback from '../Components/FeedbackComponent/PositiveFeedback';
import NegativeFeedback from '../Components/FeedbackComponent/NegativeFeedback';
import ExportIcon from '../Common/icons/ExportIcon';
import LikeIcon from '../Common/icons/LikeIcon';
import LikeIconFilled from '../Common/icons/LikeIconFilled';
import DisLikeIcon from '../Common/icons/DisLikeIcon';
import DisLikeIconFilled from '../Common/icons/DisLikeIconFilled';
import messages from '../defaultMessages';
import CopyIcon from '../Common/icons/CopyIcon';
// import RegenerateIcon from '../Common/icons/RegenerateIcon';
import { eventAction, gaEvents } from '../analytics/analytics';
import ThemeConstants from '../Common/ThemeConstants';
import * as constants from '../Common/Constants';
import Utils from '../Common/Utils';
import NoteIcon from '../Common/icons/NoteIcon';
import ReadMore from './Summary/ReadMore';
import FormatChange from '../Components/FeedbackComponent/FormatChange';
import { clickThumbsGAEvent } from '../analytics/analyticsEvents';

const styles = () => ({
  moreIconStyle: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  feedbackButton: {
    padding: 8
  },
  exportList: {
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    margin: '0 auto',
    listStyle: 'none',
    background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor,
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    width: 240
  },
  iconRootStyle: {
    minWidth: 20,
    marginRight: 7
  },
  textRootStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  regenerateStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    color: '#020917',
    marginRight: 5
  },
  poperStyle: {
    zIndex: '99999 !important'
  },
  iconsTooltip: {
    padding: 8,
    width: 'fit-content',
    background: '#161C29',
    borderRadius: '4px',
    color: 'white',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  tooltipArrow: {
    color: '#161C29'
  },
  summaryContainer: {
    width: '100%'
  },
  listItemRootStyle: {
    height: 24,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  },
  listAlignment: {
    marginBottom: 0
  },
  descriptionStyle: {
    whiteSpace: 'pre-wrap',
    fontSize: 16,
    fontFamily: 'TT Commons',
    lineHeight: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      fontSize: 16,
      fontStyle: 'normal',
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
    },
    '& ul': {
      fontSize: 16,
      paddingInlineStart: 20,
      margin: 0,
      lineHeight: '24px',
      listStyleType: 'disc',
      '& li': {
        fontSize: 16,
        fontFamily: 'TT Commons',
        color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
        marginBottom: 5
      }
    },
    '& ol': {
      fontSize: 16,
      paddingInlineStart: 20,
      margin: 0,
      lineHeight: '24px',
      listStyleType: 'decimal',
      '& li': {
        fontSize: 16,
        fontFamily: 'TT Commons',
        color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
        marginBottom: 5,
        display: 'list-item'
      }
    },
    '& p': {
      lineHeight: '24px'
    }
  },
  orderList: {
    fontSize: '16px',
    paddingInlineStart: 20,
    margin: 0,
    lineHeight: '24px',
    listStyleType: 'disc',
    '& li': {
      fontSize: 16,
      fontFamily: 'TT Commons',
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
      marginBottom: 5
    }
  },
  formatWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  formatStyle: {
    color: (props) => ThemeConstants[props.masterTheme][props.theme].formatColor,
    fontSize: 14,
    fontFamily: 'TT Commons'
  },
  alignmentRight: {
    textAlign: 'right'
  },
  explainHeader: {
    whiteSpace: 'pre-wrap',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    lineHeight: '35px'
  },
  showMoreActionWrapper: {
    marginTop: 15,
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%'
  },
  buttonStyle: {
    height: '32px',
    width: '50%',
    borderRadius: '8px',
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgColor,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    textTransform: 'none',
    border: (props) => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBorderColor,
    '&:hover': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgHoverColor,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
    }
  },
  alignRight: {
    marginRight: 12
  }
});

const Discuss = memo(({
  classes,
  intl,
  botResponse,
  handleChatGPTCustomNote,
  onRegenerate,
  chapterId,
  chapterTitle,
  handleGAEvents,
  handleSubmitFeedback,
  onCopy,
  theme,
  masterTheme,
  feedbackIconFocusId,
  showFormat,
  onFormatChange,
  inputValue,
  explainFlowMessage,
  onSend,
  onMove,
  disableOptions
}) => {
  const { formatMessage } = intl;
  const [showExportOptions, setExportOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [isFormat, setIsFormat] = useState(showFormat);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const [showSummaryButton, setShowSummaryButton] = useState(true);
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const [isClickAway, setIsClickAway] = useState(false);

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  const onExportClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setExportOptions(!showExportOptions);
  };

  /* eslint-disable no-return-assign */
  const removeBulletPoints = (summary, toParentApp) => {
    // At present we're supporting total 11 prefixes combinations seperated by |
    const regex = /^(•\d+\.|•[a-zA-Z\d]+\.|-[a-zA-Z\d]+\.|-[a-zA-Z\d]|[a-zA-Z]\)|•[a-zA-Z\d]+\)\.|\d+\.\)|\d+\)|\d+\.|[a-zA-Z]\.\)|[a-zA-Z]\.|(•-|-•)|-|•)/;
    const output = summary.replace(regex, '');
    return toParentApp ? `${output}\n` : output;
  };

  /* eslint-disable no-return-assign */
  let text = botResponse.response && botResponse.response.text;
  if (botResponse.response && botResponse.response.richTextFormat) {
    text = botResponse.response.richTextFormat;
  } else if (botResponse.response && botResponse.response.bulletPoints && botResponse.response.bulletPoints.length) {
    text = '';
    botResponse.response.bulletPoints.map((summary) => (text += removeBulletPoints(summary, true)));
  } else if (botResponse.response && botResponse.response.paragraph) {
    text = botResponse.response.paragraph;
  }

  const handleCopy = () => {
    onExportClick();
    if (botResponse.requestId) {
      const copyText = document.getElementById(botResponse.requestId).innerHTML;
      Utils.exportCopyText(copyText);
      onCopy();
    }
    const additionalFields = {
      event_label: 'Copy',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.EXPLAIN
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleNote = () => {
    onExportClick();
    handleChatGPTCustomNote(text, chapterId, chapterTitle);
    const additionalFields = {
      event_label: 'Save as Note',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.EXPLAIN
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleRegenerate = () => {
    onRegenerate();
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    const option = botResponse.response && botResponse.response.option === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, option, constants.GPT_EVENTS.EXPLAIN_TRIGGER_EVENT);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    const option = botResponse.response && botResponse.response.option === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, option, constants.GPT_EVENTS.EXPLAIN_TRIGGER_EVENT);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    let payloadInfo = {
      type: 'summary',
      requestId: botResponse.requestId,
      responseId: botResponse.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: botResponse.bulletPoints
    };
    setIsClickAway(false);
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
  };

  const handleSubmitPositiveFeedback = (value) => {
    const payloadInfo = {
      type: 'discuss',
      requestId: botResponse.requestId,
      responseId: botResponse.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: botResponse.response
    };
    setPostiveFeedbackFlag(true);
    // const additionalFields = {
    //   event_label: null,
    //   event_value: null,
    //   event_action: eventAction.providePositiveFeedback,
    //   input: value
    // };
    // gaEvents(handleGAEvents, additionalFields);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    const payloadInfo = {
      type: 'discuss',
      requestId: botResponse.requestId,
      responseId: botResponse.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      data: botResponse.response
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  const handleFormatChange = () => {
    setIsFormat(false);
    onFormatChange((botResponse.response && botResponse.response.option === constants.BULLET) ? constants.PARAGRAPH : constants.BULLET, inputValue);
  };
  const getDiscussData = () => {
    let content = '';
    if (botResponse.response && botResponse.response.richTextFormat) {
      content = <ReadMore summaryData={botResponse.response.richTextFormat} masterTheme={masterTheme} theme={theme} requestId={botResponse.requestId} />;
    } else if (botResponse.response && botResponse.response.bulletPoints && botResponse.response.bulletPoints.length > 0) {
      content = (
        <div>
          <ul className={classes.orderList}>
            {
              botResponse.response.bulletPoints.map((summary, index) => (
                <li key={`bullet_list_${index}`}>{Utilities.textFromContent(removeBulletPoints(summary))}</li>
              ))
            }
          </ul>
        </div>
      );
    } else {
      content = (<ReadMore summaryData={botResponse.response && botResponse.response.paragraph} masterTheme={masterTheme} theme={theme} requestId={botResponse.requestId} />);
    }
    return content;
  };

  const handleShowMore = () => {
    gaEvents(handleGAEvents, {
      event_action: eventAction.clickChatContinuation,
      event_label: 'Tell me more',
      event_value: null,
      command_source_code: 'click'
    });
    setShowSummaryButton(false);
    onSend({}, inputValue, false, true, false, true, true);
  };
  const handleMoveOn = () => {
    setShowSummaryButton(false);
    onMove();
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };

  return (
    <Box className={classes.summaryContainer}>
      <Box>
        {/* {
        explainFlowMessage && (
          <Typography variant="span" className={classes.explainHeader}>{explainFlowMessage}</Typography>
        )
      } */}
        {
        botResponse && botResponse.response && (
        <Box id={botResponse.requestId}>
          {' '}
          { getDiscussData() }
          {' '}
        </Box>
        )
      }
        {
        botResponse && botResponse.response && !botResponse.response.richTextFormat && !(botResponse.response.bulletPoints && botResponse.response.bulletPoints.length) && !botResponse.response.paragraph && (
          <Typography variant="span" className={classes.descriptionStyle}>{Utilities.textFromContent(botResponse && botResponse.response && botResponse.response.text && botResponse.response.text.replaceAll('###', ''))}</Typography>
        )
      }
      </Box>
      <Box className={isFormat ? classes.actionContainer : classes.alignmentRight}>
        {
          isFormat && (
            <FormatChange
              optionSelected={(botResponse.response && botResponse.response.option === constants.BULLET) ? constants.BULLET : constants.PARAGRAPH}
              onFormatChange={handleFormatChange}
              masterTheme={masterTheme}
              theme={theme}
            />
          )
        }
        <div>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as positive" title="Report as positive" placement="top" arrow>
            <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
              {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} /> : <LikeIcon masterTheme={masterTheme} theme={theme} />}
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as negative" title="Report as negative" placement="top" arrow>
            <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
              {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} />}
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Export" aria-haspopup title="Export" placement="top" arrow>
            <IconButton className={classes.feedbackButton} onClick={(event) => onExportClick(event)} aria-label="Export">
              <ExportIcon masterTheme={masterTheme} theme={theme} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Regenerate response" placement="top" arrow>
            <IconButton className={classes.feedbackButton} onClick={handleRegenerate}>
              <RegenerateIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      </Box>
      {
        (!disableOptions && (botResponse.response && botResponse.response.hasMoreSummary && showSummaryButton)) && (
          <div className={classes.showMoreActionWrapper}>
            <Button
              component="button"
              onClick={handleShowMore}
              className={`${classes.buttonStyle} ${classes.alignRight}`}
            >
              {formatMessage(messages.tellMore)}
            </Button>
            <Button
              component="button"
              onClick={handleMoveOn}
              className={classes.buttonStyle}
            >
              {formatMessage(messages.moveOn)}
            </Button>
          </div>
        )
      }
      {
        showExportOptions && (
          <Menu
            id="exportOptions"
            open={showExportOptions}
            anchorEl={anchorEl}
            classes={{ paper: classes.exportList }}
            className={classes.poperStyle}
            keepMounted
            onClose={onExportClick}
          >
            <MenuItem
              button
              onClick={handleNote}
              classes={{
                root: classes.listItemRootStyle
              }}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <NoteIcon masterTheme={masterTheme} theme={theme} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.saveNote)}
              </ListItemText>
            </MenuItem>
            <MenuItem
              button
              classes={{
                root: `${classes.listItemRootStyle} ${classes.listAlignment}`
              }}
              onClick={handleCopy}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <CopyIcon masterTheme={masterTheme} theme={theme} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.copy)}
              </ListItemText>
            </MenuItem>
          </Menu>
        )
      }
      {
        showPositiveFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
          >
            <PositiveFeedback
              handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
              handleClose={handleClose}
              theme={theme}
              masterTheme={masterTheme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
      {
        showNegativeFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
          >
            <NegativeFeedback
              handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
              handleClose={handleClose}
              theme={theme}
              masterTheme={masterTheme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
    </Box>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

Discuss.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  handleChatGPTCustomNote: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  botResponse: PropTypes.object,
  chapterId: PropTypes.string.isRequired,
  chapterTitle: PropTypes.string.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  onFormatChange: PropTypes.func,
  feedbackIconFocusId: PropTypes.object,
  showFormat: PropTypes.bool,
  inputValue: PropTypes.string,
  explainFlowMessage: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  disableOptions: PropTypes.bool
};

Discuss.defaultProps = {
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  onFormatChange: () => {},
  feedbackIconFocusId: {},
  showFormat: true,
  inputValue: '',
  explainFlowMessage: '',
  botResponse: {},
  disableOptions: false
};
export default memo(withStyles(styles)(injectIntl(Discuss)));
