/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 20200 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Spanish South America - Colombia Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Continuar',
  'browser.check.DONT_SHOW_AGAIN': 'No volver a mostrar',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'sistema operativo y navegador web admitido',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Su navegador <<name>> está desactualizado',
  'browser.check.BROWSER_MESSAGE_DESC': 'Para una mejor experiencia, seleccione uno de los navegadores más recientes:',
  'browser.check.BROWSER_SKIP_NOW': 'Omitir por ahora',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Acerca de los navegadores compatibles',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Su navegador <<name>> no es compatible',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Para una mejor experiencia, seleccione uno de los navegadores compatibles.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Su sistema operativo <<name>> no es compatible',
  'browser.check.OS_UNSUPPORTED_DESC': 'Si bien el producto puede funcionar sin problemas, tenga en cuenta que cualquier problema '
      + 'que encuentre puede resolverse probando con un dispositivo que ejecute un <<link>>',
  'errors.GENERIC_TITLE': 'Error del servidor',
  'errors.GENERIC_DESC': 'Lo sentimos, nuestro sistema no funciona. Inténtelo de nuevo más tarde o contacte al servicio de asistencia técnica.',
  'errors.HTTP_400_TITLE': 'Error del servidor',
  'errors.HTTP_400_DESC': 'Encontramos un error al procesar su solicitud.',
  'errors.HTTP_401_TITLE': '¿Ha iniciado sesión?',
  'errors.HTTP_401_DESC': 'No pudimos recuperar los datos porque es posible que su sesión haya caducado.',
  'errors.HTTP_403_TITLE': 'No autorizado',
  'errors.HTTP_403_DESC': 'No tiene acceso a este recurso',
  'errors.HTTP_888_TITLE': 'El servidor respondió muy lento',
  'errors.HTTP_888_DESC': 'Encontramos un error al procesar su solicitud.'
};

export default RESOURCES;
