/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ContactIntent.jsx
 * @author Vignesh Selvam
 */

import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import messages from '../defaultMessages';
import ThemeConstants from './ThemeConstants';

const styles = () => ({
  linkStyle: {
    color: '#2D9BF0',
    textAlign: 'left',
    fontSize: 16,
    fontFamily: 'TT Commons',
    marginBottom: 10
  },
  contentIntentStyle: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  welcomeWrapper: {
    width: '100%',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      width: 7,
      padding: 4
    },
    '&::-webkit-scrollbar-track': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor,
      borderRadius: '0px 4px 4px 0px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].scrollThumbBgColor,
      borderRadius: 4,
      width: 6
    }
  }
});

const ContactIntent = ({
  classes,
  intl
}) => {
  const { formatMessage } = intl;

  return (
    <div className={classes.welcomeWrapper}>
      <Typography variant="paragraph" className={classes.contentIntentStyle}>
        {formatMessage(messages.contactSupportHeader)}
      </Typography>
      <Typography variant="paragraph" className={classes.contentIntentStyle}>
        {formatMessage(messages.contactSupportDescription)}
        <Link
          href="https://support.pearson.com/getsupport/s/contactsupport"
          className={classes.linkStyle}
          target="_blank"
        >
          {formatMessage(messages.clickHere)}
        </Link>
      </Typography>
    </div>
  );
};

ContactIntent.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
export default withStyles(styles)(injectIntl(ContactIntent));
