/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file PayWallSuggestion.jsx - A component for paywall.
 * @author Arifhusain Soudagar
 */

import React, { useState } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button
} from '@material-ui/core';
import SuggestionScreen from '../Hoc/SuggestionScreen';
import LockIcon from '../../Common/assets/images/lock-icon.png';
import CheckIcon from '../../Common/icons/CheckIcon';
import messages from '../../defaultMessages';
import {
  PAYWALL_CHANNELS_SUBSCRIPTION_URL_DEV, PAYWALL_CHANNELS_SUBSCRIPTION_URL_PROD, PROD, GPT_EVENTS
} from '../../Common/constants';

const Styles = {
  payWallWrapper: {
    display: 'block',
    height: '70%',
    fontFamily: 'TT Commons',
    color: 'white'
  },
  exclamationMark: {
    position: 'absolute',
    top: '72%',
    left: '52.5%',
    color: 'white'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paywallLockIcon: {
    width: 45,
    height: 65
  },
  payWallHeading: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: 0.4,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 12
  },
  payWallDescription: {
    color: 'white',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0.23,
    marginBottom: 12,
    textAlign: 'center'
  },
  payWallList: {
    marginBottom: 0
  },
  payWallListIcon: {
    minWidth: 30
  },
  payWallListItem: {
    paddingLeft: 8
  },
  payWallListItemText: {
    color: 'white',
    fontSize: 15
  },
  feedbackActionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    marginTop: 24
  },
  buttonLabelStyle: {
    minWidth: '100%'
  },
  cancelStyle: {
    background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    padding: '8px 24px',
    color: '#fefefe',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    height: 40,
    borderRadius: 24,
    border: '1px solid #393F4A',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    cursor: 'pointer',
    marginRight: 12
  },
  sendButton: {
    background: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
    padding: '8px 24px',
    color: '#fefefe',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    height: 40,
    borderRadius: 24,
    borderColor: 'transparent',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    cursor: 'pointer'
  }
};

const PayWallSuggestion = ({
  classes, intl, handlePaywallClose, env
}) => {
  const [isClose, setIsClose] = useState(false);
  const { formatMessage } = intl;
  const handleCloseClick = (event) => {
    event.stopPropagation();
    setIsClose(true);
    setTimeout(() => {
      handlePaywallClose(event, GPT_EVENTS.CLOSE_POP_UP_SUBS);
    }, 200); // After animation duration time closed the feedback popup
  };

  const handleSubscribeClick = (event) => {
    handlePaywallClose(event, GPT_EVENTS.POP_UP_SUBS);
    const subscriptionUrl = (env && (env.toLowerCase() === PROD)) ? PAYWALL_CHANNELS_SUBSCRIPTION_URL_PROD : PAYWALL_CHANNELS_SUBSCRIPTION_URL_DEV;
    window.open(subscriptionUrl, '_blank');
  };

  return (
    <>
      <SuggestionScreen isClose={isClose}>
        <Box className={classes.payWallWrapper}>
          <Box className={classes.iconContainer}>
            <img src={LockIcon} alt="lockicon" className={classes.paywallLockIcon} />
          </Box>
          <Typography variant="h3" className={classes.payWallHeading}>
            {formatMessage(messages.unlockUnlimitedAccess)}
          </Typography>
          <Typography className={classes.payWallDescription}>
            {formatMessage(messages.outOfFreeVideos)}
          </Typography>
          <List className={classes.payWallList}>
            <ListItem className={classes.payWallListItem}>
              <ListItemText classes={{ primary: classes.payWallListItemText }}>
                {formatMessage(messages.subscribeNowFor)}
              </ListItemText>
            </ListItem>
            <ListItem className={classes.payWallListItem}>
              <ListItemIcon className={classes.payWallListIcon}><CheckIcon /></ListItemIcon>
              <ListItemText classes={{ primary: classes.payWallListItemText }}>
                {formatMessage(messages.unlimitedVideoLessons)}
              </ListItemText>
            </ListItem>
            <ListItem className={classes.payWallListItem}>
              <ListItemIcon className={classes.payWallListIcon}><CheckIcon /></ListItemIcon>
              <ListItemText classes={{ primary: classes.payWallListItemText }}>
                {formatMessage(messages.examLikePracticeQuestions)}
              </ListItemText>
            </ListItem>
            <ListItem className={classes.payWallListItem}>
              <ListItemIcon className={classes.payWallListIcon}><CheckIcon /></ListItemIcon>
              <ListItemText classes={{ primary: classes.payWallListItemText }}>
                {formatMessage(messages.personalizedStudyPlans)}
              </ListItemText>
            </ListItem>
          </List>
          <Box className={classes.feedbackActionWrapper}>
            <Button
              className={classes.sendButton}
              classes={{
                label: classes.buttonLabelStyle
              }}
              onClick={handleSubscribeClick}
            >
              {formatMessage(messages.subscribe)}
            </Button>
            <Button
              className={classes.cancelStyle}
              classes={{
                label: classes.buttonLabelStyle
              }}
              onClick={handleCloseClick}
            >
              {formatMessage(messages.cancel)}
            </Button>
          </Box>
        </Box>
      </SuggestionScreen>
    </>
  );
};

PayWallSuggestion.propTypes = {
  intl: intlShape.isRequired,
  env: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handlePaywallClose: PropTypes.func
};

PayWallSuggestion.defaultProps = {
  env: '',
  handlePaywallClose: () => { }
};

export default withStyles(Styles)(injectIntl(PayWallSuggestion));
