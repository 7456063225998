/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import ulog from 'ulog';

import { EventProvider } from '@aquila/core';

import TagManagerContainer from './TagManagerContainer';
import env from '../common/env';
import * as shapes from '../common/shapes';
import history from '../common/history';
import App from './App';
import Framework from '../framework/Framework';
import startServices from '../services/startServices';
import BrowserCheckContainer from './BrowserCheckContainer';

/**
 * The react owner that sets up react router and Mobx stores.
 *
 * @author Hari Gangadharan
 */
export default class Container extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    browserLocale: PropTypes.bool,
    gtmArgs: shapes.gtmArgs.isRequired,
    authType: PropTypes.oneOf(['IES', 'INT']),
    authClientId: PropTypes.string.isRequired,
    correlationIdPrefix: PropTypes.string.isRequired,
    partnerEnv: PropTypes.string.isRequired,
    partnerEnvConfig: PropTypes.object,
    product: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    delayedGTM: PropTypes.bool,
    browserCheck: PropTypes.bool,
    sessionDuration: PropTypes.number.isRequired,
    browserRequirementsUrl: PropTypes.string,
    allowedOrigins: PropTypes.arrayOf(PropTypes.string.isRequired),
    onInit: PropTypes.func,
    modules: PropTypes.arrayOf(PropTypes.node),
    mojoMVP: PropTypes.bool,
    noAuthRoutes: PropTypes.array,
    subscriptionFilter: PropTypes.string,
    enableGetDeviceList: PropTypes.bool,
    enableSessionTimedOutEvent: PropTypes.bool
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    allowedOrigins: [],
    authType: 'IES',
    browserLocale: true,
    onInit: () => {},
    modules: [],
    partnerEnvConfig: {},
    delayedGTM: false,
    browserCheck: true,
    browserRequirementsUrl: '',
    mojoMVP: false,
    noAuthRoutes: [],
    subscriptionFilter: '',
    enableGetDeviceList: true,
    enableSessionTimedOutEvent: true
  };

  constructor(props) {
    super(props);
    this.state = { initialized: false };
  }

  componentDidMount() {
    const { noAuthRoutes } = this.props;
    const isNoAuthRoute = Array.isArray(noAuthRoutes)
      && noAuthRoutes.length
      && noAuthRoutes.includes(window.location.pathname);
    env.setEnv(this.props.partnerEnv, this.props.partnerEnvConfig);
    ulog.level = env.get('LOG_LEVEL');
    if (!isNoAuthRoute) {
      Framework.register(this.props.authType, this.props.authClientId, this.props.correlationIdPrefix, this.props.sessionDuration, this.props.enableSessionTimedOutEvent);
      Framework.init(this.props.authType, this.props.authClientId, this.props.correlationIdPrefix);
      startServices(this.props.browserCheck, this.props.mojoMVP, this.props.subscriptionFilter, this.props.enableGetDeviceList);
    }
    this.props.onInit();
    this.setState({ initialized: true });
  }

  render() {
    return this.state.initialized ? (
      <EventProvider product={this.props.product} appVersion={this.props.version}>
        <Fragment>
          { this.props.browserCheck && (
            <BrowserCheckContainer
              product={this.props.product}
              browserRequirementsUrl={this.props.browserRequirementsUrl}
            />
          )}
          <TagManagerContainer gtmArgs={this.props.gtmArgs} delayedGTM={this.props.delayedGTM} />
          <Router history={history}>
            <Provider {...Framework.getStoreRegistry().getStores()}>
              <App
                browserLocale={this.props.browserLocale}
                product={this.props.product}
                version={this.props.version}
                modules={this.props.modules}
                allowedOrigins={this.props.allowedOrigins}
                mojoMVP={this.props.mojoMVP}
              />
            </Provider>
          </Router>
        </Fragment>
      </EventProvider>
    ) : null;
  }
}
