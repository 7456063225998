/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Poland Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Kontynuuj',
  'browser.check.DONT_SHOW_AGAIN': 'Nie pokazuj mi tego ponownie',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'obsługiwany system operacyjny i przeglądarka internetowa',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Twoja przeglądarka <<nazwa>> jest nieaktualna',
  'browser.check.BROWSER_MESSAGE_DESC': 'Aby uzyskać najlepsze rezultaty, wybierz jedną z najnowszych przeglądarek:',
  'browser.check.BROWSER_SKIP_NOW': 'Chwilowo pomiń',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'O obsługiwanych przeglądarkach',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Twoja przeglądarka <<nazwa>> nie jest obsługiwana',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Aby uzyskać najlepsze rezultaty, wybierz jedną z obsługiwanych przeglądarek:',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Twój system operacyjny <<nazwa>> nie jest obsługiwany',
  'browser.check.OS_UNSUPPORTED_DESC': 'Chociaż produkt może działać bez problemów, należy pamiętać, że wszelkie napotkane problemy '
      + 'można rozwiązać, za pomocą urządzenia obsługującego <<link>>',
  'errors.GENERIC_TITLE': 'Błąd serwera',
  'errors.GENERIC_DESC': 'Przepraszamy, nasz system nie działa. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
  'errors.HTTP_400_TITLE': 'Błąd serwera',
  'errors.HTTP_400_DESC': 'Napotkaliśmy błąd podczas przetwarzania Twojego żądania.',
  'errors.HTTP_401_TITLE': 'Czy jesteś zalogowany?',
  'errors.HTTP_401_DESC': 'Nie mogliśmy pobrać danych, ponieważ Twoja sesja logowania mogła wygasnąć.',
  'errors.HTTP_403_TITLE': 'Nieautoryzowany',
  'errors.HTTP_403_DESC': 'Nie masz dostępu do tego zasobu.',
  'errors.HTTP_888_TITLE': 'Serwer był zbyt wolny, aby odpowiedzieć',
  'errors.HTTP_888_DESC': 'Napotkaliśmy błąd podczas przetwarzania Twojego żądania.'
};

export default RESOURCES;
