/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import PropTypes from 'prop-types';

/**
 * A component that helps handle loading state of components.
 *
 * @author Hari Gangadharan
 */
const LoadingHandler = ({
  loading, isError, content, errorContent, loadingContent
}) => {
  if (loading) {
    return loadingContent;
  }
  if (isError) {
    return errorContent;
  }

  return content;
};

LoadingHandler.propTypes = {
  loading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  errorContent: PropTypes.node,
  loadingContent: PropTypes.node
};

LoadingHandler.defaultProps = {
  errorContent: null,
  loadingContent: null
};

export default LoadingHandler;
