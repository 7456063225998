/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ulog from 'ulog';
import EventBus from 'eventing-bus';
import * as constants from '../common/constants';
import * as shapes from '../common/shapes';
import ErrorContainer from './ErrorContainer';
import LocaleSupport from '../i18n/LocaleSupport';

const log = ulog('App');

/**
 * The wrapper that manages the React Routes and general redirection.
 *
 * @author Hari Gangadharan
 */
@inject('language')
@observer
class App extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    allowedOrigins: PropTypes.arrayOf(PropTypes.string).isRequired,
    browserLocale: PropTypes.bool.isRequired,
    language: shapes.language.isRequired,
    history: shapes.history.isRequired,
    modules: PropTypes.arrayOf(PropTypes.node).isRequired
  };

  componentDidMount() {
    // if we set browserLocale as true then it will check the browser language
    // and set it as the default language
    if (this.props.browserLocale) {
      // eslint-disable-next-line react/prop-types
      this.props.language.determineLang(this.props.mojoMVP);
    } else {
      const locale = LocaleSupport.getInstance().getDefaultLang();

      this.props.language.changeLanguageTo(locale);
    }

    this.historyReqSubscriptionCancel = EventBus.on(constants.HISTORY_REPLACE_REQUESTED, (event) => {
      log.info(`Navigation requested: ${event.path}`);
      this.redirect(event.path);
    });
    window.addEventListener('message', this.handleEvents);
  }

  componentDidUpdate() {
    const {
      browserLocale,
      language
    } = this.props;

    if (!browserLocale) {
      const locale = LocaleSupport.getInstance().getDefaultLang();

      language.changeLanguageTo(locale);
    }
  }

  componentWillUnmount() {
    if (this.historyReqSubscriptionCancel) {
      this.historyReqSubscriptionCancel();
    }
    window.removeEventListener('message', this.handleEvents);
  }

  handleEvents = (e) => {
    if (this.props.allowedOrigins.includes(e.origin)) {
      const message = JSON.parse(e.data);
      if (message.type === 'redirect-request') {
        log.info(`Navigation requested from parent: ${message.path}`);
        this.redirect(message.path);
      }
    }
  };

  redirect = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { language } = this.props;

    return (
      <IntlProvider locale={language.currentLanguage} messages={language.messages}>
        <Fragment>
          <Route
            path="/errors/:errorCode"
            exact
            render={
              (props) => (
                <ErrorContainer errorCode={props.match.params.errorCode} />
              )
            }
          />
          {this.props.modules}
        </Fragment>
      </IntlProvider>
    );
  }
}

export default inject('language')(withRouter(App));
