/* eslint-disable no-debugger */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file TutorActiveBody.jsx : A Icon Component that has svg file of bot ring
 * @author Arifhusain Soudagar
 */

const TutorActiveBody = `<svg xmlns="http://www.w3.org/2000/svg" width="106" height="106" viewBox="0 0 106 106" fill="none">
<g filter="url(#filter0_ddi_5528_80135)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0276 53C21.0276 35.3269 35.3277 21 52.9678 21C70.6078 21 84.9079 35.3269 84.9079 53C84.9079 70.6731 70.6078 85 52.9678 85H27.6152C27.3915 84.9923 27.1466 84.9864 26.8895 84.9802C24.9769 84.9342 22.3901 84.872 22.8242 83.92C23.2055 83.0836 23.845 82.3139 24.5248 81.4955C26.4785 79.1435 28.766 76.3898 26.2213 70.498C25.7943 69.5092 25.393 68.5955 25.0178 67.7411C22.3794 61.7326 21.0276 58.654 21.0276 53ZM52.9402 26.633C38.4053 26.633 26.6224 38.4651 26.6224 53C26.6224 67.5349 38.4053 79.367 52.9402 79.367C67.4751 79.367 79.2579 67.5349 79.2579 53C79.2579 38.4651 67.4751 26.633 52.9402 26.633Z" fill="url(#paint0_linear_5528_80135)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0276 53C21.0276 35.3269 35.3277 21 52.9678 21C70.6078 21 84.9079 35.3269 84.9079 53C84.9079 70.6731 70.6078 85 52.9678 85H27.6152C27.3915 84.9923 27.1466 84.9864 26.8895 84.9802C24.9769 84.9342 22.3901 84.872 22.8242 83.92C23.2055 83.0836 23.845 82.3139 24.5248 81.4955C26.4785 79.1435 28.766 76.3898 26.2213 70.498C25.7943 69.5092 25.393 68.5955 25.0178 67.7411C22.3794 61.7326 21.0276 58.654 21.0276 53ZM52.9402 26.633C38.4053 26.633 26.6224 38.4651 26.6224 53C26.6224 67.5349 38.4053 79.367 52.9402 79.367C67.4751 79.367 79.2579 67.5349 79.2579 53C79.2579 38.4651 67.4751 26.633 52.9402 26.633Z" fill="url(#paint1_radial_5528_80135)" fill-opacity="0.8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0276 53C21.0276 35.3269 35.3277 21 52.9678 21C70.6078 21 84.9079 35.3269 84.9079 53C84.9079 70.6731 70.6078 85 52.9678 85H27.6152C27.3915 84.9923 27.1466 84.9864 26.8895 84.9802C24.9769 84.9342 22.3901 84.872 22.8242 83.92C23.2055 83.0836 23.845 82.3139 24.5248 81.4955C26.4785 79.1435 28.766 76.3898 26.2213 70.498C25.7943 69.5092 25.393 68.5955 25.0178 67.7411C22.3794 61.7326 21.0276 58.654 21.0276 53ZM52.9402 26.633C38.4053 26.633 26.6224 38.4651 26.6224 53C26.6224 67.5349 38.4053 79.367 52.9402 79.367C67.4751 79.367 79.2579 67.5349 79.2579 53C79.2579 38.4651 67.4751 26.633 52.9402 26.633Z" fill="url(#paint2_radial_5528_80135)" fill-opacity="0.8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0276 53C21.0276 35.3269 35.3277 21 52.9678 21C70.6078 21 84.9079 35.3269 84.9079 53C84.9079 70.6731 70.6078 85 52.9678 85H27.6152C27.3915 84.9923 27.1466 84.9864 26.8895 84.9802C24.9769 84.9342 22.3901 84.872 22.8242 83.92C23.2055 83.0836 23.845 82.3139 24.5248 81.4955C26.4785 79.1435 28.766 76.3898 26.2213 70.498C25.7943 69.5092 25.393 68.5955 25.0178 67.7411C22.3794 61.7326 21.0276 58.654 21.0276 53ZM52.9402 26.633C38.4053 26.633 26.6224 38.4651 26.6224 53C26.6224 67.5349 38.4053 79.367 52.9402 79.367C67.4751 79.367 79.2579 67.5349 79.2579 53C79.2579 38.4651 67.4751 26.633 52.9402 26.633Z" fill="url(#paint3_radial_5528_80135)" fill-opacity="0.8"/>
</g>
<g style="mix-blend-mode:hard-light">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0273 53C21.0273 35.3269 35.3275 21 52.9675 21C70.6076 21 84.9077 35.3269 84.9077 53C84.9077 70.6731 70.6076 85 52.9675 85H27.615C27.3913 84.9923 27.1463 84.9864 26.8893 84.9802C24.9767 84.9342 22.3899 84.872 22.824 83.92C23.2053 83.0836 23.8447 82.3139 24.5246 81.4955C26.4783 79.1435 28.7657 76.3898 26.2211 70.498C25.794 69.5092 25.3928 68.5955 25.0176 67.7411C22.3792 61.7326 21.0273 58.654 21.0273 53ZM52.94 26.633C38.4051 26.633 26.6222 38.4651 26.6222 53C26.6222 67.5349 38.4051 79.367 52.94 79.367C67.4749 79.367 79.2577 67.5349 79.2577 53C79.2577 38.4651 67.4749 26.633 52.94 26.633Z" fill="url(#paint4_linear_5528_80135)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0273 53C21.0273 35.3269 35.3275 21 52.9675 21C70.6076 21 84.9077 35.3269 84.9077 53C84.9077 70.6731 70.6076 85 52.9675 85H27.615C27.3913 84.9923 27.1463 84.9864 26.8893 84.9802C24.9767 84.9342 22.3899 84.872 22.824 83.92C23.2053 83.0836 23.8447 82.3139 24.5246 81.4955C26.4783 79.1435 28.7657 76.3898 26.2211 70.498C25.794 69.5092 25.3928 68.5955 25.0176 67.7411C22.3792 61.7326 21.0273 58.654 21.0273 53ZM52.94 26.633C38.4051 26.633 26.6222 38.4651 26.6222 53C26.6222 67.5349 38.4051 79.367 52.94 79.367C67.4749 79.367 79.2577 67.5349 79.2577 53C79.2577 38.4651 67.4749 26.633 52.94 26.633Z" fill="url(#paint5_radial_5528_80135)" fill-opacity="0.8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0273 53C21.0273 35.3269 35.3275 21 52.9675 21C70.6076 21 84.9077 35.3269 84.9077 53C84.9077 70.6731 70.6076 85 52.9675 85H27.615C27.3913 84.9923 27.1463 84.9864 26.8893 84.9802C24.9767 84.9342 22.3899 84.872 22.824 83.92C23.2053 83.0836 23.8447 82.3139 24.5246 81.4955C26.4783 79.1435 28.7657 76.3898 26.2211 70.498C25.794 69.5092 25.3928 68.5955 25.0176 67.7411C22.3792 61.7326 21.0273 58.654 21.0273 53ZM52.94 26.633C38.4051 26.633 26.6222 38.4651 26.6222 53C26.6222 67.5349 38.4051 79.367 52.94 79.367C67.4749 79.367 79.2577 67.5349 79.2577 53C79.2577 38.4651 67.4749 26.633 52.94 26.633Z" fill="url(#paint6_radial_5528_80135)" fill-opacity="0.8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0273 53C21.0273 35.3269 35.3275 21 52.9675 21C70.6076 21 84.9077 35.3269 84.9077 53C84.9077 70.6731 70.6076 85 52.9675 85H27.615C27.3913 84.9923 27.1463 84.9864 26.8893 84.9802C24.9767 84.9342 22.3899 84.872 22.824 83.92C23.2053 83.0836 23.8447 82.3139 24.5246 81.4955C26.4783 79.1435 28.7657 76.3898 26.2211 70.498C25.794 69.5092 25.3928 68.5955 25.0176 67.7411C22.3792 61.7326 21.0273 58.654 21.0273 53ZM52.94 26.633C38.4051 26.633 26.6222 38.4651 26.6222 53C26.6222 67.5349 38.4051 79.367 52.94 79.367C67.4749 79.367 79.2577 67.5349 79.2577 53C79.2577 38.4651 67.4749 26.633 52.94 26.633Z" fill="url(#paint7_radial_5528_80135)" fill-opacity="0.8"/>
</g>
<g opacity="0.72" filter="url(#filter1_d_5528_80135)">
<rect x="27" y="27" width="52" height="52" rx="26" stroke="#62FFEE" stroke-width="2" shape-rendering="crispEdges"/>
</g>
<defs>
<filter id="filter0_ddi_5528_80135" x="13.0276" y="15" width="79.8804" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5528_80135"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_5528_80135" result="effect2_dropShadow_5528_80135"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5528_80135" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-2"/>
<feGaussianBlur stdDeviation="6"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="shape" result="effect3_innerShadow_5528_80135"/>
</filter>
<filter id="filter1_d_5528_80135" x="0" y="0" width="106" height="106" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_5528_80135"/>
<feOffset/>
<feGaussianBlur stdDeviation="11"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.76 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5528_80135"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5528_80135" result="shape"/>
</filter>
<linearGradient id="paint0_linear_5528_80135" x1="84.9079" y1="14.2754" x2="25.1629" y2="90.4402" gradientUnits="userSpaceOnUse">
<stop stop-color="#7041EE"/>
<stop offset="0.527788" stop-color="#8D41EE"/>
<stop offset="1" stop-color="#D641EE"/>
</linearGradient>
<radialGradient id="paint1_radial_5528_80135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.3511 7.08696) rotate(96.1469) scale(78.3636 130.387)">
<stop stop-color="#52B6FF"/>
<stop offset="1" stop-color="#8F41EE" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial_5528_80135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.0804 24.7101) rotate(62.2447) scale(31.9679 91.6592)">
<stop stop-color="#9DD6FF"/>
<stop offset="1" stop-color="#8F41EE" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_5528_80135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(115.224 69.9275) rotate(144.277) scale(54.0126 74.3341)">
<stop stop-color="#5263FF"/>
<stop offset="1" stop-color="#8F41EE" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint4_linear_5528_80135" x1="84.9077" y1="14.2754" x2="25.1626" y2="90.4402" gradientUnits="userSpaceOnUse">
<stop stop-color="#7041EE"/>
<stop offset="0.527788" stop-color="#8D41EE"/>
<stop offset="1" stop-color="#D641EE"/>
</linearGradient>
<radialGradient id="paint5_radial_5528_80135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.3508 7.08696) rotate(96.1469) scale(78.3636 130.387)">
<stop stop-color="#52B6FF"/>
<stop offset="1" stop-color="#8F41EE" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint6_radial_5528_80135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.0802 24.7101) rotate(62.2447) scale(31.9679 91.6592)">
<stop stop-color="#9DD6FF"/>
<stop offset="1" stop-color="#8F41EE" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint7_radial_5528_80135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(115.224 69.9275) rotate(144.277) scale(54.0126 74.3341)">
<stop stop-color="#5263FF"/>
<stop offset="1" stop-color="#8F41EE" stop-opacity="0"/>
</radialGradient>
</defs>
</svg>`;

export default TutorActiveBody;
