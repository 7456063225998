/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file MessageContainer.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ThemeConstants from '../Common/ThemeConstants';

const styles = () => ({
  chatMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  use_response: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    marginBottom: 0,
    marginLeft: 10,
    overflow: 'overlay',
    wordBreak: 'break-word',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '&::-webkit-scrollbar': {
      height: 5
    },
    '&::-webkit-scrollbar-track': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].userMessageScrollbar.track
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].userMessageScrollbar.thumb
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].userMessageScrollbar.hover
    }
  },
  Usermessage_Container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  userMessage: {
    maxWidth: '80%',
    display: 'flex',
    borderRadius: 12,
    padding: 8,
    boxSizing: 'border-box',
    alignItems: 'center',
    background: (props) => ThemeConstants[props.masterTheme][props.theme].userMsgbgColor,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  avatarStyle: {
    width: 40,
    height: 40,
    borderRadius: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    boxSizing: 'border-box',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    marginLeft: '16px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].avatarBgColor
  }
});

const UserMessage = (props) => {
  const { classes, message, initials } = props;
  return (
    <div className={classes.chatMessageContainer}>
      <div className={classes.Usermessage_Container}>
        <div className={classes.userMessage}>
          <Typography variant="paragraph" className={classes.use_response}>
            {message}
          </Typography>
          <div aria-hidden className={classes.avatarStyle}>{initials}</div>
        </div>
      </div>
    </div>
  );
};

UserMessage.defaultProps = {
  initials: '',
  message: ''
};

UserMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  initials: PropTypes.string,
  message: PropTypes.string
};

export default withStyles(styles)(UserMessage);
