/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Russia Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Продолжить',
  'browser.check.DONT_SHOW_AGAIN': 'Не показывать повторно',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'поддерживаемая операционная система и веб-браузер',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Ваш браузер <<название>> устарел',
  'browser.check.BROWSER_MESSAGE_DESC': 'Чтобы повысить качество обслуживания, пользуйтесь последними версиями браузеров:',
  'browser.check.BROWSER_SKIP_NOW': 'Пока пропустить',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'О поддерживаемых браузерах',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Ваш браузер <<название>> не поддерживается',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Чтобы повысить качество обслуживания, пользуйтесь поддерживаемыми версиями браузеров:',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Ваша операционная система <<название>> не поддерживается',
  'browser.check.OS_UNSUPPORTED_DESC': 'Несмотря на безупречную работу изделия, любые ошибки, с которыми вы можете столкнуться, '
    + 'можно устранить, запустив его с устройства, использующего <<link>>',
  'errors.GENERIC_TITLE': 'Ошибка сервера',
  'errors.GENERIC_DESC': 'Система не функционирует. Повторите попытку позже или обратитесь в службу поддержки.',
  'errors.HTTP_400_TITLE': 'Ошибка сервера',
  'errors.HTTP_400_DESC': 'При обработке вашего запроса произошла ошибка.',
  'errors.HTTP_401_TITLE': 'Вы вошли в систему?',
  'errors.HTTP_401_DESC': 'Не удалось доставить ваши данные, так как ваш сеанс работы в системе, возможно, истек.',
  'errors.HTTP_403_TITLE': 'Полномочия отсутствуют',
  'errors.HTTP_403_DESC': 'У вас нет доступа к этому ресурсу.',
  'errors.HTTP_888_TITLE': 'Слишком медленная реакция сервера',
  'errors.HTTP_888_DESC': 'При обработке вашего запроса произошла ошибка.'
};

export default RESOURCES;
