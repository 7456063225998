/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * German Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'browser.check.DONT_SHOW_AGAIN': 'Nicht mehr zeigen',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'unterstütztes Betriebssystem und Webbrowser',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Ihr Browser <<name>> ist nicht mehr aktuell',
  'browser.check.BROWSER_MESSAGE_DESC': 'Wählen Sie für die beste Erfahrung einen der neuesten Browser aus:',
  'browser.check.BROWSER_SKIP_NOW': 'Für jetzt überspringen',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Informationen zu unterstützten Browsern',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Ihr Browser <<name>> wird nicht unterstützt',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Wählen Sie für die beste Erfahrung einen der unterstützten Browser aus.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Ihr Betriebssystem <<name>> wird nicht unterstützt',
  // eslint-disable-next-line max-len
  'browser.check.OS_UNSUPPORTED_DESC': 'Auch wenn das Produkt ohne Probleme funktioniert, beachten Sie bitte, dass alle Probleme, die bei Ihnen auftreten, durch den Versuch mit einem Gerät, auf dem ein <<link>> läuft, gelöst werden können.',
  'common.OK': 'Ok',
  'common.CONTINUE': 'Weiter',
  'errors.GENERIC_TITLE': 'Serverfehler',
  // eslint-disable-next-line max-len
  'errors.GENERIC_DESC': 'Leider ist unser System ausgefallen. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.',
  'errors.HTTP_400_TITLE': 'Serverfehler',
  'errors.HTTP_400_DESC': 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten.',
  'errors.HTTP_401_TITLE': 'Haben Sie sich angemeldet?',
  'errors.HTTP_401_DESC': 'Wir konnten die Daten nicht abrufen, da Ihre Anmeldesitzung möglicherweise abgelaufen ist.',
  'errors.HTTP_403_TITLE': 'Nicht berechtigt',
  'errors.HTTP_403_DESC': 'Sie haben keinen Zugriff auf diese Ressource.',
  'errors.HTTP_888_TITLE': 'Der Server war zu langsam, um zu reagieren',
  'errors.HTTP_888_DESC': 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten.'
};

export default RESOURCES;
