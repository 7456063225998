/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { applySnapshot, types } from 'mobx-state-tree';
/**
 * A Store to handle User details.
 *
 * @author Hari Gangadharan
 */

const JobProfile = types.model('JobProfile', {
  linkedInUrl: types.optional(types.string, '')
});

const documentSettings = types.model('documentSettings', {
  documentTypes: types.optional(types.string, ''),
  uploadType: types.optional(types.string, '')
});

const orgConfigs = types.model('orgConfigs', {
  documentSettings: types.maybeNull(documentSettings)
});

const Source = types.model('Source',
  {
    type: types.optional(types.string, '')
  });

const Discount = types.model(
  'Discount',
  {
    type: types.maybeNull(types.string)
  }
);

const swapTitle = types.model(
  'swapTitle',
  {
    period: types.maybeNull(types.string),
    count: types.maybeNull(types.number),
    allowSwap: types.maybeNull(types.boolean),
    allowRenew: types.maybeNull(types.boolean),
    expirationDate: types.maybeNull(types.string)
  }
);

const autoRenewal = types.model(
  'autoRenewal',
  {
    period: types.maybeNull(types.string),
    showRenewalAlert: types.maybeNull(types.boolean),
    type: types.maybeNull(types.string),
    expirationDate: types.maybeNull(types.string)
  }
);

const Gpsdetail = types.model(
  'Gpsdetail',
  {
    nextBillingDate: types.maybeNull(types.string),
    canceledAt: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    entitlementLevel: types.maybeNull(types.string),
    entitlementType: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    frequency: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    subscriptionType: types.maybeNull(types.string),
    frequencyType: types.maybeNull(types.string),
    swapTitleCount: types.maybeNull(types.number),
    maxEntitlements: types.maybeNull(types.integer),
    autoRenew: types.maybeNull(types.boolean),
    subscriptionId: types.maybeNull(types.string),
    productModelId: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    entitlementTier: types.maybeNull(types.string),
    projectedCancellationDate: types.maybeNull(types.string),
    discount: types.maybeNull(types.array(Discount)),
    subscriptionSource: types.maybeNull(types.string),
    swapTitle: types.maybeNull(types.array(swapTitle)),
    autoRenewal: types.maybeNull(types.array(autoRenewal))
  }
);

const User = types.model(
  'User',
  {
    id: types.string,
    firstName: types.string,
    lastName: types.string,
    email: types.string,
    username: types.string,
    initials: types.string,
    token: types.string,
    isAuthenticated: types.boolean,
    roles: types.optional(types.array(types.string), []),
    orgId: types.optional(types.string, ''),
    orgName: types.optional(types.string, ''),
    orgConfigs: types.maybeNull(orgConfigs),
    isAdmin: types.optional(types.boolean, false),
    isTpi: types.optional(types.boolean, false),
    contextId: types.optional(types.string, ''),
    smsUserId: types.maybeNull(types.string),
    company: types.optional(types.string, ''),
    jobTitle: types.optional(types.string, ''),
    jobProfile: types.maybeNull(JobProfile),
    guidanceTerms: types.optional(types.boolean, false),
    source: types.maybeNull(Source),
    timezone: types.maybeNull(types.string),
    preferredTimezone: types.maybeNull(types.string),
    countryCode: types.optional(types.string, ''),
    gpsSubscriptions: types.maybeNull(types.array(Gpsdetail)),
    ldUserHash: types.maybeNull(types.string),
    isMojoUser: types.maybeNull(types.boolean),
    isImpersonatedId: types.optional(types.boolean, false),
    phoneNumber: types.maybeNull(types.string, ''),
    homeCountryCode: types.maybeNull(types.string),
    socialLink: types.maybeNull(types.string),
    mobileAccess: types.optional(types.boolean, false),
    hasChannelsAccess: types.optional(types.boolean, false),
    dob: types.maybeNull(types.string),
    studyLevel: types.maybeNull(types.string)
  }
).views((self) => ({
  get fullName() {
    return self.firstName || self.lastName ? `${self.firstName} ${self.lastName}` : '...';
  }
})).actions((self) => ({
  /* eslint-disable no-param-reassign */
  setAuthenticated(id, token, contextId) {
    self.setToken(token);
    self.setId(id);
    self.contextId = contextId;
    self.isAuthenticated = true;
  },

  set(user) {
    applySnapshot(self, { ...self, ...user });
  },

  setId(id) {
    self.id = id;
  },

  setToken(token) {
    self.token = token;
    self.isAuthenticated = true;
  }
  /* eslint-enable no-param-reassign */
}));

export default User;
