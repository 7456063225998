/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Quiz.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Typography } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';
import messages from '../defaultMessages';
import ThemeConstants from '../Common/ThemeConstants';

const styles = () => ({
  DelayedResponseWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    width: '100%'
  },
  DelayedResponseContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // background: '#e8eefa',
    borderRadius: '18px',
    boxSizing: 'border-box'
  },
  DelayedResponseContainer__question: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  DelayedResponseContainer__actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    gap: 10
  },
  DelayedResponseContainer__actions__no: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '0.24px',
    textDecorationLine: 'underline',
    color: '#655591'
  },
  DelayedResponseContainer__actions__yes: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    padding: '8px 24px',
    width: 76,
    height: 40,
    border: '1px solid #BCC1CB',
    borderRadius: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: '24px',
    letterSpacing: '0.4px'
  },
  disabledButtonStyle: {
    opacity: '.5',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  }
});

const DelayedResponse = ({
  classes,
  handleShowWaitingMessage,
  handleStopRequestShowOptions,
  intl: { formatMessage },
  disableOption
}) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div className={classes.DelayedResponseWrapper}>
      <div className={classes.DelayedResponseContainer}>
        <Typography className={classes.DelayedResponseContainer__question} variant="p">
          {formatMessage(messages.delayedResponseMessage)}
        </Typography>
        {!showOptions && !disableOption
          && (
          <div className={classes.DelayedResponseContainer__actions}>
            <Button
              onClick={() => { setShowOptions(true); handleShowWaitingMessage(); }}
              className={classes.DelayedResponseContainer__actions__yes}
              classes={{ disabled: classes.disabledButtonStyle }}
            >
              Wait
            </Button>
            <Link
              href={false}
              onClick={handleStopRequestShowOptions}
              component="button"
              variant="text"
              className={classes.DelayedResponseContainer__actions__no}
            >
              Stop
            </Link>
          </div>
          )}
      </div>
    </div>
  );
};

DelayedResponse.propTypes = {
  classes: PropTypes.object.isRequired,
  handleShowWaitingMessage: PropTypes.func.isRequired,
  handleStopRequestShowOptions: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  disableOption: PropTypes.bool
};

DelayedResponse.defaultProps = {
  disableOption: false
};

export default withStyles(styles)(injectIntl(DelayedResponse));
