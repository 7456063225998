/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import pseudoloc from 'pseudoloc-js/index-browserify';
import de from './de';
import en from './en';
import es from './es';
import esco from './es-co';
import esmx from './es-mx';
import fr from './fr';
import frca from './fr-ca';
import id from './id';
import it from './it';
import ja from './ja';
import ko from './ko';
import nl from './nl';
import pl from './pl';
import ptBr from './pt-br';
import ru from './ru';
import trTR from './tr-TR';
import zhCn from './zh-cn';
import zhHk from './zh-hk';

let myInstance = null;

const fwTranslations = new Map();

export default class LocaleSupport {
  constructor() {
    if (myInstance) {
      throw new Error('Singleton: use getInstance() method instead');
    }
    fwTranslations
      .set('de', de)
      .set('en', en)
      .set('es', es)
      .set('es-co', esco)
      .set('es-mx', esmx)
      .set('fr', fr)
      .set('fr-ca', frca)
      .set('id', id)
      .set('it', it)
      .set('it-it', it)
      .set('ja', ja)
      .set('ko', ko)
      .set('nl', nl)
      .set('pl', pl)
      .set('pt-br', ptBr)
      .set('ru', ru)
      .set('tr', trTR)
      .set('tr-TR', trTR)
      .set('zh-cn', zhCn)
      .set('zh-hk', zhHk);
    this.defaultLang = null;
    this.translations = null;
    this.pseudoLocaleCode = null;
    pseudoloc.option.extend = 0.3;
    pseudoloc.option.startDelimiter = '{';
    pseudoloc.option.endDelimiter = '}';
  }

  static getMessagesFromTranslations(defaultLang, locale, translations) {
    let messages = translations.get(defaultLang);
    if (locale && translations.has(locale)) {
      messages = translations.get(locale);
    } else if (locale.length > 2) {
      messages = translations.get(locale.substr(0, 2));
    }

    return messages;
  }

  static createPseudoLocaleMessages(messages) {
    const pseudoLocaleMessages = {};
    Object.keys(messages).forEach((key) => {
      pseudoLocaleMessages[key] = pseudoloc.str(messages[key]);
    });

    return pseudoLocaleMessages;
  }

  setPseudoLocaleCode(pseudoLocaleCode) {
    this.pseudoLocaleCode = pseudoLocaleCode;
  }

  setDefaultLang(defaultLang) {
    this.defaultLang = defaultLang;
  }

  getDefaultLang() {
    return this.defaultLang;
  }

  setTranslations(translations) {
    this.translations = translations;
  }

  getTranslations() {
    return this.translations;
  }

  checkValidity() {
    if (!this.defaultLang) {
      throw new Error('Default Language not set - use setDefaultLang()');
    }
    if (!(this.translations.has(this.defaultLang))) {
      throw new Error('Translation for default language missing - use setTranslations()');
    }
    if (this.pseudoLocaleCode) {
      const messages = this.translations.get(this.defaultLang);
      this.translations.set(this.pseudoLocaleCode, LocaleSupport.createPseudoLocaleMessages(messages));
      const fwMessages = fwTranslations.get('en');
      fwTranslations.set(this.pseudoLocaleCode, LocaleSupport.createPseudoLocaleMessages(fwMessages));
    }
  }

  getDefaultMessages() {
    const messages = LocaleSupport.getMessagesFromTranslations(this.defaultLang, this.defaultLang, this.translations);
    const frameworkMessages = LocaleSupport.getMessagesFromTranslations(
      'en',
      this.defaultLang,
      fwTranslations
    );

    return { ...frameworkMessages, ...messages };
  }

  getMessages(locale) {
    const messages = LocaleSupport.getMessagesFromTranslations(this.defaultLang, locale, this.translations);
    const frameworkMessages = LocaleSupport.getMessagesFromTranslations(
      'en',
      locale,
      fwTranslations
    );

    return { ...frameworkMessages, ...messages };
  }

  static getInstance() {
    if (!myInstance) {
      myInstance = new LocaleSupport();
    }

    return myInstance;
  }
}
