/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import ulog from 'ulog';
// import PathUtils from '../utils/PathUtils';
import history from '../common/history';
import * as constants from '../common/constants';
import Utils from '../utils/Utils';

const log = ulog('AuthService');

/**
 * Authentication Service authenticates the user. It uses an appropriate auth strategy to authenticate against the
 * right service.
 *
 * @author Hari Gangadharan
 */
export default class AuthService {
  constructor(storeRegistry) {
    this.accessToken = '';
    this.isFirstTime = true;
    this.isInitialized = false;
    this.clientId = null;
    this.correlationIdPrefix = 'XYZ';
    this.storeRegistry = storeRegistry;
    this.authStrategies = new Map();
    this.type = null;
    this.sessionDuration = 7200;
    this.enableSessionTimedOutEvent = true;
  }

  register(key, authStrategy) {
    this.authStrategies.set(key, authStrategy);
  }

  getAuthStrategy() {
    if (!this.authStrategies.has(this.type)) {
      return null;
    }

    return this.authStrategies.get(this.type);
  }

  getSessionDuration() {
    return this.sessionDuration;
  }

  getClientId() {
    return this.clientId;
  }

  setUserToken(token) {
    this.accessToken = token;
    this.storeRegistry.getStore('user').setToken(token);
  }

  setAuthenticated(userId, accessToken, contextId = '') {
    this.accessToken = accessToken;
    this.storeRegistry.getStore('user').setAuthenticated(userId, accessToken, contextId);
    if (this.isFirstTime) {
      log.info('Authentication completed.');
      this.isFirstTime = false;
      this.getUserProfile(userId);
      EventBus.publish(constants.AUTH_VERIFIED, { userId });
    }
  }

  /**
   * get User Profile details
   */
  getUserProfile(userId, delay = 0) {
    const userProfileEvent = this.type + constants.USER_PROFILE_REQUESTED;
    log.info(`Event dipatched: ${userProfileEvent}`);
    setTimeout(() => { EventBus.publish(userProfileEvent, { userId }); }, delay);
  }

  /**
   * Update user profile
   */
  updateUserProfile(payLoad, isSilentUpdate) {
    const userProfileEvent = this.type + constants.USER_PROFILE_REQUESTED;
    log.info(`Event dipatched: ${userProfileEvent}`);
    EventBus.publish(userProfileEvent, {
      type: 'POST',
      isSilentUpdate,
      payLoad
    });
  }

  /**
   * Initialize the Auth Service
   */
  init() {
    if (!this.isInitialized) {
      this.getAuthStrategy().init(this.handleInit);
    }

    return this;
  }

  /**
   * Set the auth strategy properties
   */
  setAuthStrategy(type, clientId, correlationIdPrefix, sessionDuration, enableSessionTimedOutEvent) {
    if (!this.isInitialized) {
      this.type = type;
      this.clientId = clientId;
      this.correlationIdPrefix = correlationIdPrefix;
      this.enableSessionTimedOutEvent = enableSessionTimedOutEvent;
      if (sessionDuration) { this.sessionDuration = sessionDuration; }
    }

    return this;
  }

  handleInit = () => {
    this.isInitialized = true;
    EventBus.on(constants.LOGIN_REQUESTED, (event) => {
      this.getAuthStrategy().login(event.location);
    });
  };

  /**
   * Handles the errors in API calls.
   *
   * @param error the error from the API
   * @param response the response object from API call.
   */
  // eslint-disable-next-line class-methods-use-this
  handleErrors = (error, response) => {
    if (response) {
      switch (response.status) {
        case 401: {
          // 401 Authentication error (Auth timeout)
          const location = window.location.toString();
          setTimeout(() => {
            EventBus.publish(constants.LOGIN_REQUESTED, { location });
          }, 2000);
          break;
        }
        case 403:
          if (response.config.url === '/dm/swap' && response.data.message.toLowerCase() === 'already swapped') {
            break;
          }
          // 403 Unauthorized
          history.replace('/errors/HTTP_403');
          break;
        default:
          break;
      }
    }
  };

  logout = (requireLogin = false) => {
    this.getAuthStrategy().logout(requireLogin);
  };

  /**
   * A method to add authentication interceptors to the API client.
   */
  intercept(authHeaderName, authPrefix, client, addCorrelationId = true, handleResponse = true) {
    if (handleResponse) {
      client.interceptors.response.use(
        (response) => {
          this.handleErrors(null, response);

          return response;
        },
        (error) => {
          this.handleErrors(error, error.response);

          return Promise.reject(error);
        }
      );
    }
    client.interceptors.request.use(
      async (requestConfig) => {
        const config = requestConfig;
        if (this.type && this.type.toLowerCase() === 'ies') {
          this.accessToken = await this.getAuthStrategy().getToken();
        }
        if (authHeaderName) {
          config.headers[authHeaderName] = authPrefix + this.accessToken;
        }
        if (addCorrelationId) {
          config.headers['Correlation-Id'] = `${this.correlationIdPrefix}-${Utils.uuid()}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
  }
}
