/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file CheckboxCheckedIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const Styles = () => ({
  checkedIcon: {
    width: 20,
    height: 20,
    fill: 'none'
  }
});

const CheckboxCheckedIcon = ({ classes }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 20 20"
    classes={{
      root: classes.checkedIcon
    }}
  >
    <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="url(#paint0_linear_8142_5492)" />
    <path d="M6 10.156L8.69451 12.8903C8.78203 12.9875 8.95027 12.9872 9.03745 12.8899L14 7.03711" stroke="#FEFEFE" strokeWidth="3" strokeLinecap="round" />
    <defs>
      <linearGradient id="paint0_linear_8142_5492" x1="0" y1="0" x2="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE512E" />
        <stop offset="0.634355" stopColor="#DF006B" />
        <stop offset="1" stopColor="#BD0071" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

CheckboxCheckedIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(CheckboxCheckedIcon);
