/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import EventBus from 'eventing-bus';
import env from '../common/env';
import * as constants from '../common/constants';
import Utils from '../utils/Utils';

/**
 * A Container component to initialize the OS/Browser Check Scripts.
 *
 * @author Hari Gangadharan
 */
export default class BrowserCheckContainer extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    product: PropTypes.string.isRequired,
    browserRequirementsUrl: PropTypes.string.isRequired
  };

  componentDidMount() {
    if (!Utils.isInIframe()) {
      this.loadBrowserCheck(this.ref);
    }
  }

  loadBrowserCheck(mountPoint) {
    const browserCheckStyle = document.createElement('link');
    browserCheckStyle.type = 'text/css';
    browserCheckStyle.rel = 'stylesheet';
    browserCheckStyle.href = env.get('BROWSER_CHECK_STYLE');
    mountPoint.appendChild(browserCheckStyle);

    const browserCheckScript = document.createElement('script');
    browserCheckScript.type = 'text/javascript';
    browserCheckScript.src = env.get('BROWSER_CHECK_SCRIPT');
    browserCheckScript.onload = () => {
      const { product, browserRequirementsUrl } = this.props;
      EventBus.publish(constants.BROWSER_CHECK_REQUESTED, {
        product,
        browserRequirementsUrl
      });
    };
    mountPoint.appendChild(browserCheckScript);
  }

  render() {
    return (
      <div ref={(c) => { this.ref = c; }} />
    );
  }
}
