/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file WelcomeMessage.jsx
 * @author SaiKiran
 */

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import messages from '../defaultMessages';
import * as constants from '../Common/constants';
// import { eventAction, gaEvents } from '../../analytics/analytics';
import ChatComponentStyles from '../Components/Chatbot/ChatComponentStyles';

const AfterStop = ({
  classes,
  intl,
  onOptionClick,
  onOptionClickTriggerGA,
  isMove,
  isExpanded,
  showSummary,
  showPractice
}) => {
  const { formatMessage } = intl;
  const buttonStyle = isExpanded ? `${classes.welcomeBtnStyles} ${classes.expandedActionButtonStyle}` : classes.welcomeBtnStyles;
  return (
    <div>
      <Typography variant="paragraph" className={classes.welcomeStyle}>
        {isMove ? formatMessage(messages.moveOnDescription) : formatMessage(messages.stopDescription)}
      </Typography>

      <div className={classes.welcomeBtnsWrapper}>
        {
          showSummary && (
            <Button
              component="button"
              onClick={() => { onOptionClickTriggerGA(); onOptionClick(constants.SUMMARY, true, null, false, false); }}
              className={buttonStyle}
            >
              {formatMessage(messages.welcomeBtnSummarize)}
            </Button>
          )
        }
        {
          showPractice && (
            <Button
              component="button"
              onClick={() => { onOptionClickTriggerGA(); onOptionClick(constants.QUIZ, true, null, false, false); }}
              className={buttonStyle}
            >
              {formatMessage(messages.welcomeBtnPractice)}
            </Button>
          )
        }
        <Button
          component="button"
          onClick={() => { onOptionClickTriggerGA(); onOptionClick(constants.DISCUSS, true, null, false, false); }}
          className={buttonStyle}
        >
          {formatMessage(messages.welcomeBtnExplain)}
        </Button>
      </div>
    </div>
  );
};

AfterStop.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onOptionClickTriggerGA: PropTypes.func.isRequired,
  isMove: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  showSummary: PropTypes.bool.isRequired,
  showPractice: PropTypes.bool.isRequired
};

export default withStyles(ChatComponentStyles)(injectIntl(AfterStop));
