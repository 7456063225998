/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { inject } from 'mobx-react';

import * as shapes from '../shapes';
import ErrorModel from '../../models/ErrorModel';
import ErrorCard from './ErrorCard';

/**
 * A component that displays errors based on the error object from state store.
 *
 * @author Hari Gangadharan
 */
const StateErrorDisplay = (
  {
    error, language, langKeyPrefix
  }
) => (
  <ErrorCard
    title={(
      <FormattedMessage
        {...language.getText(`${langKeyPrefix}.HTTP_${error.status}_TITLE`)}
      />
    )}
    body={(
      <div>
        <FormattedMessage
          {...language.getText(`${langKeyPrefix}.HTTP_${error.status}_DESC`)}
        />
        {' '}
      </div>
    )}
    showDetails
    details={(
      <Typography component="div">
        {error.message}
      </Typography>
    )}
  />
);

StateErrorDisplay.propTypes = {
  error: shapes.modelOf(ErrorModel),
  language: shapes.language.isRequired,
  langKeyPrefix: PropTypes.string
};

StateErrorDisplay.defaultProps = {
  error: {},
  langKeyPrefix: 'errors'
};

export default inject('language')(StateErrorDisplay);
