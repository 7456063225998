/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import ulog from 'ulog';

import BaseService from './BaseService';
import Framework from '../framework/Framework';
import * as constants from '../common/constants';
import env from '../common/env';

const log = ulog('IntUserLogoutService');

const clientManager = Framework.getClientManager();
/**
 * Internal User Service gets the user info from Internal IDP.
 *
 * @author Hari Gangadharan
 */
export default class IntUserLogoutService extends BaseService {
  constructor() {
    super(constants.INT_USER_LOGOUT_REQUESTED);

    if (!clientManager.get('intAuthService')) {
      clientManager.add({
        name: 'intAuthService',
        authHeader: 'myCloudProxySession',
        baseUrl: env.get('INT_BASE_URL'),
        timeout: env.get('INT_TIMEOUT'),
        addCorrelationId: false
      });
    }
  }

  handleEvent(eventName) { // eslint-disable-line class-methods-use-this
    log.info('Event Request:', eventName);
    const userProfileClient = clientManager.get('intAuthService');
    userProfileClient.post('/sessions/?_action=logout', { headers: { 'Content-Type': 'application/json' } }).then(
      () => {
        const location = window.location.toString();
        EventBus.publish(constants.LOGIN_REQUESTED, { location });
      }
    );
  }
}
/*
https://identity-internal-test.pearson.com/auth/json/pearson/sessions/?_action=logout
https://identity-internal-test.pearson.com/auth/json/pearson/sessions?_action=logout
*/
