/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file GenerateTopics.jsx
 * @author Arifhusain Soudagar
 */

import React, {
  useState, useRef, useEffect, memo
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography
} from '@material-ui/core';
import { Utilities } from '@aquila/core';
import messages from '../../defaultMessages';
import ThemeConstants from '../../Common/ThemeConstants';
import { clickTopicSuggestionsEvent } from '../../analytics/analyticsEvents';
import * as constants from '../../Common/constants';
import globalContext from '../../Context/globalContext';

const styles = () => ({
  generateTopicsContainer: {
    border: 'none',
    padding: 0,
    borderRadius: '18px',
    boxSizing: 'border-box'
  },
  optionDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    margin: 0,
    marginBottom: 8
  },
  sendStyle: {
    padding: '8px 24px',
    width: 88,
    height: 40,
    background: (props) => `${ThemeConstants[props.masterTheme][props.theme].bgColor} !important`,
    border: '1px solid #bcc1cb',
    borderRadius: '24px',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    marginTop: 8,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  defaultRadio: {
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].defaultRadio}) center no-repeat !important`
  },
  selectedStyle: {
    // color: props => `${ThemeConstants[props.masterTheme][props.theme].selectedRadioBtnColor} !important`
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].radioSelectedBG}) center no-repeat !important`
  },
  radioRootStyle: {
    padding: 5,
    alignSelf: 'flex-start',
    marginTop: 0,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].radioBtnColor,
    '&:hover': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&:focus': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&.Mui-focusVisible': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  topicStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    lineHeight: '24px',
    letterSpacing: '0.24px'
  },
  topicBottomStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    paddingTop: 8,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    letterSpacing: '0.24px',
    lineHeight: '24px'
  },
  labelStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    padding: '2.5px 0px',
    lineHeight: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  selectedLabel: {
    fontWeight: '400 !important',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  labelRootStyle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  disabledButtonStyle: {
    opacity: '.5',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  generateOptionStyle: {
    marginTop: 10
  }
});

const GenerateTopics = memo(({
  classes, onGenerateTopicsClick, topics, intl, disbaleTopics, isContentSelectorFirstTime, contentSelectedText, handleGAEvents, data, getPromptVersion, requestId, bookId
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [keepDisabled, setKeepDisabled] = useState(false);
  const { formatMessage } = intl;
  const optionRef = useRef(null);
  useEffect(() => {
    if (optionRef && optionRef.current) {
      optionRef.current.focus();
    }
  }, []);
  const handleChange = (event) => {
    const currentValue = event.target.value;
    setSelectedOption(currentValue);
  };

  const handleSend = () => {
    clickTopicSuggestionsEvent(handleGAEvents, constants.GPT_EVENTS.CONTENTSELECTOR_SUGGESTIONS, bookId, data, getPromptVersion);
    setKeepDisabled(true);
    onGenerateTopicsClick({}, `Explain ${selectedOption.toLowerCase()}`, true, null, null, null, null, true, false, requestId);
  };

  return (
    <>
      <fieldset className={classes.generateTopicsContainer}>
        <legend id="generateTopicsTitle">
          <Typography className={classes.topicStyle}>{isContentSelectorFirstTime ? formatMessage(messages.topicMessageForFirstTimeUser) : formatMessage(messages.topicMessageForSecondTimeUser)}</Typography>
          <Typography className={classes.topicBottomStyle}>{contentSelectedText > 500 ? formatMessage(messages.alternatively) : formatMessage(messages.chooseTopics)}</Typography>
        </legend>
        <RadioGroup role="none" name="topics" value={selectedOption} onChange={handleChange} className={classes.generateOptionStyle}>
          {
            topics.map((item, index) => (
              <FormControlLabel
                value={item}
                control={(
                  <Radio
                    inputRef={(index === 0) ? optionRef : null}
                    disabled={keepDisabled || disbaleTopics}
                    checked={selectedOption === item}
                    classes={{
                      root: classes.radioRootStyle
                    }}
                    icon={<span className={classes.defaultRadio} />}
                    checkedIcon={<span className={classes.selectedStyle} />}
                  />
                )}
                label={Utilities.textFromContent(item, globalContext.enableNewMathConfig)}
                classes={{
                  root: classes.labelRootStyle,
                  label: selectedOption === item ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
                }}
              />
            ))
        }
        </RadioGroup>
        <Button
          className={classes.sendStyle}
          onClick={handleSend}
          disabled={(!selectedOption) || (keepDisabled) || disbaleTopics}
          classes={{ disabled: classes.disabledButtonStyle }}
        >
          {formatMessage(messages.send)}
        </Button>
      </fieldset>
    </>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

GenerateTopics.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onGenerateTopicsClick: PropTypes.func.isRequired,
  isContentSelectorFirstTime: PropTypes.bool.isRequired,
  contentSelectedText: PropTypes.string.isRequired,
  handleGAEvents: PropTypes.func,
  getPromptVersion: PropTypes.func,
  disbaleTopics: PropTypes.bool,
  topics: PropTypes.array,
  requestId: PropTypes.string,
  bookId: PropTypes.string
};

GenerateTopics.defaultProps = {
  topics: [],
  data: {},
  handleGAEvents: () => {},
  getPromptVersion: () => {},
  disbaleTopics: false,
  requestId: '',
  bookId: ''
};

export default memo(withStyles(styles)(injectIntl(GenerateTopics)));
