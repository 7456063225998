/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework from '../framework/Framework';
import AppState from '../models/AppState';
import Language from '../models/Language';
import User from '../models/User';
import Promo from '../models/Promo';
import Device from '../models/Device';
import Preference from '../models/Preference';

const storeRegistry = Framework.getStoreRegistry();

storeRegistry.register({
  name: 'language',
  spec: Language,
  saveLocally: true,
  initialState: {
    language: '',
    userLanguages: [],
    fromParam: false
  }
});

storeRegistry.register({
  name: 'user',
  spec: User,
  saveLocally: false,
  initialState: {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    gmail: '',
    initials: '',
    isAuthenticated: false,
    token: ''
  }
});

storeRegistry.register({
  name: 'device',
  spec: Device,
  saveLocally: false,
  initialState: {
    limitReached: false,
    limit: 0,
    devices: [],
    isSwapAlreadyDone: false
  }
});

storeRegistry.register({
  name: 'appState',
  spec: AppState,
  saveLocally: false,
  initialState: {
    isInitialized: false
  }
});

storeRegistry.register({
  name: 'promo',
  spec: Promo,
  saveLocally: true,
  initialState: {
    mobilePromoDismissed: false
  }
});

storeRegistry.register({
  name: 'preference',
  spec: Preference,
  saveLocally: true,
  initialState: {
    status: ''
  }
});

export default storeRegistry.getStores();
