/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file WelcomeModal.jsx
 * @author SaiKiran
 */

import React, { useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import WelcomeBtnDefault from '../../Common/icons/WelcomeBtnDefault.svg';
import WelcomeBtnHover from '../../Common/icons/WelcomeBtnHover.svg';
import messages from '../../defaultMessages';
import WelcomeExpandImage from '../../Common/icons/WelcomeExpandImage.png';
import WelcomeExpandImageLarge from '../../Common/icons/WelcomeExpandImageLarge.png';
import WelcomeExpandImageSmall from '../../Common/icons/WelcomeExpandImageSmall.png';
import WelcomeExpandImageExtraSmall from '../../Common/icons/WelcomeExpandImageExtraSmall.png';

const styles = () => ({
  ModalWindow: {
    borderRadius: '12px',
    border: '1px solid #DDE3EE',
    background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%)',
    boxShadow: '0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px - 5px rgba(0, 0, 0, 0.20)',
    // backgroundColor: '#F7F9FD',
    flex: 1,
    width: 'calc(100% - 16px)',
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden'
  },
  ModalWindowExpanded: {
    flexDirection: 'row'
  },
  ModalText: {
    padding: '40px 40px 32px 40px'
  },
  textAnimation: {
    animation: '$leftToRight 0.5s ease',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationDelay: '0.4s',
    animationFillMode: 'forwards',
    transform: 'translateX(-100%)'
  },
  ModalHeader: {
    color: '#F7F9FD',
    fontFamily: 'TT Commons',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: 16
  },
  ModalContent: {
    color: '#F7F9FD',
    fontFamily: 'TT Commons',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    marginBottom: 16,
    display: 'block'
  },
  ModalButton: {
    background: `url(${WelcomeBtnDefault}) no-repeat`,
    width: 64,
    height: 64,
    cursor: 'pointer',
    marginTop: '15px',
    zIndex: '1',
    // animation: '$leftToRight 2s ease',
    // animationDelay: '3s',
    transform: 'scale(1)',
    transition: 'transform 150ms ease-out',
    '&:hover': {
      background: `url(${WelcomeBtnHover}) no-repeat`,
      transform: 'scale(1.1)'
    }
  },

  ModalImageWrapper: {
    // padding: '0px 0px 40px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: 1
  },
  imageWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    display: 'flex'
  },
  ModalButtonWrapper__Img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  slideInAnimation: {
    animation: '$leftToRight 250ms ease-out',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationDelay: '0.9s',
    animationFillMode: 'forwards',
    transform: 'translateX(-200%)'
  },
  '@keyframes slideIn': {
    from: { width: '100%', marginLeft: '0%' },
    to: { width: '0%', marginLeft: '100%' }
  },
  '@keyframes leftToRight': {
    to: {
      transform: 'translateX(0%)'
    }
  },
  slideOutAnimation: {
    animation: '$slideOut 250ms ease-out',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    animationDelay: '10ms'
  },
  arrowSlideOutAnimation: {
    animation: '$arrowSlideOut 250ms ease-out',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards'
  },
  '@keyframes arrowSlideOut': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1
    },
    '5%': {
      transform: 'translateX(10%)',
      opacity: 0.9
    },
    '90%': {
      transform: 'translateX(90%)',
      opacity: 0.1
    },
    '100%': {
      transform: 'translateX(100%)',
      opacity: 0
    }
  },
  '@keyframes slideOut': {
    '0%': {
      transform: 'translateX(0%)',
      opacity: 1
    },
    '95%': {
      transform: 'translateX(190%)',
      opacity: 1
    },
    '100%': {
      transform: 'translateX(200%)',
      opacity: 0
    }
  },
  orderList: {
    paddingInlineStart: 20,
    margin: 0,
    lineHeight: '24px',
    listStyleType: 'disc',
    '& li': {
      fontSize: 20,
      fontFamily: 'TT Commons',
      marginBottom: 5,
      color: '#F7F9FD',
      fontWeight: 400
    }
  },
  expandImageWrapper: {
    right: 20
  },
  expandModelStyle: {
    width: 320
  },
  standardImageWrapper: {
    // backgroundImage: `url(${WelcomeExpandImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: 'calc(100% - 300px)',
    alignSelf: 'flex-end',
    backgroundPosition: 'right bottom'
  },
  standardImageLarge: {
    backgroundImage: `url(${WelcomeExpandImageLarge})`
  },
  standardImageSmall: {
    backgroundImage: `url(${WelcomeExpandImageSmall})`
  },
  standardImageExtraSmall: {
    backgroundImage: `url(${WelcomeExpandImageExtraSmall})`,
    right: '15px'
  },
  pngImageWrapper: {
    backgroundImage: `url(${WelcomeExpandImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: 'calc(100% - 16px)',
    alignSelf: 'flex-end',
    marginRight: '15px',
    backgroundPosition: 'right bottom'
  },
  expandPngImageWrapper: {
    backgroundImage: `url(${WelcomeExpandImageLarge})`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: 'calc(100% - 16px)',
    alignSelf: 'flex-end',
    marginRight: '15px',
    backgroundPosition: 'right bottom',
    position: 'absolute',
    right: '0px'
  }
});
const WelcomeModal = ({
  classes,
  intl,
  onNext,
  isExpanded,
  aitutorWelcomeScreenCohorts,
  aiTutorAutoOpenCount
}) => {
  const { formatMessage } = intl;
  const [botContainerWidth, setBotContainerWidth] = useState('');
  const [botContainerHeight, setBotContainerHeight] = useState('');
  const [showAnimation, setShowAnimation] = useState(true);
  const [isSlideOut, setSlideOut] = useState(false);
  const [arrowSlideOut, setArrowSlideOut] = useState(false);
  const handleResize = () => {
    setBotContainerWidth(document.getElementById('bot_container_id') && document.getElementById('bot_container_id').clientWidth);
    setBotContainerHeight(document.getElementById('bot_container_id') && document.getElementById('bot_container_id').clientHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    setTimeout(() => {
      setShowAnimation(false);
    }, 1500);
  }, []);
  useLayoutEffect(() => {
    setBotContainerWidth(document.getElementById('bot_container_id') && document.getElementById('bot_container_id').clientWidth);
    setBotContainerHeight(document.getElementById('bot_container_id') && document.getElementById('bot_container_id').clientHeight);
  }, [isExpanded]);

  const handleNext = () => {
    setArrowSlideOut(true);
    setTimeout(() => {
      setSlideOut(true);
    }, 250);
    setTimeout(() => {
      onNext();
    }, 500);
  };

  const getExpandClassName = () => {
    if (isExpanded && (botContainerWidth > 1100 || (botContainerHeight <= 600 && botContainerWidth > 570))) {
      return classes.pngImageWrapper;
    } if (isExpanded && botContainerWidth <= 1100 && botContainerWidth >= 500 && botContainerHeight > 600 && !(botContainerHeight < 900 && botContainerWidth < 600)) {
      return classes.expandPngImageWrapper;
    } if (isExpanded && botContainerWidth < 500 && botContainerWidth >= 300 && botContainerHeight > 500) {
      return classes.pngImageWrapper;
    }
    // return classes.pngImageWrapper;
    // (isExpanded && (botContainerWidth > 1100 || (botContainerHeight < 600 && botContainerWidth > 730))) ? classes.pngImageWrapper : `${(isExpanded && botContainerWidth <= 1099 && botContainerWidth >= 500 && botContainerHeight > 600) ? classes.expandPngImageWrapper : `${(isExpanded && botContainerWidth < 500 && botContainerWidth >= 300 && botContainerHeight > 600) ? classes.expandPngImageWrapperSmall : ''}`}`;
  };

  const getClassName = () => {
    if (!isExpanded && botContainerHeight >= 485 && botContainerHeight < 800) {
      return classes.standardImageExtraSmall;
    } if (!isExpanded && (botContainerHeight >= 800 && botContainerHeight < 925)) {
      return classes.standardImageSmall;
    } if (!isExpanded && botContainerHeight >= 925) {
      return classes.standardImageLarge;
    }
  };

  return (
    <div className={`${(isExpanded && (botContainerWidth > 1100 || botContainerHeight <= 600)) ? `${classes.ModalWindow} ${classes.ModalWindowExpanded}` : classes.ModalWindow} ${isSlideOut ? classes.slideOutAnimation : ''}`}>
      <div className={(isExpanded && botContainerWidth > 400) ? `${classes.expandModelStyle} ${classes.ModalText}` : `${classes.ModalText} ${showAnimation ? classes.textAnimation : ''}`}>

        <Typography variant="h2" className={classes.ModalHeader}>
          {aiTutorAutoOpenCount === 0 ? formatMessage(messages.onBoardingWelcomeMessage) : aitutorWelcomeScreenCohorts }
        </Typography>
        <Typography variant="paragraph" className={classes.ModalContent}>
          {aiTutorAutoOpenCount === 0 ? formatMessage(messages.onBoardingDescription) : formatMessage(messages.onBoardingAutoOpenDescription)}
        </Typography>
        <ul className={classes.orderList}>
          <li>
            {aiTutorAutoOpenCount === 0 ? formatMessage(messages.onBoardingPracticeMessage) : formatMessage(messages.onPracticeMessage)}
          </li>
          <li>
            {aiTutorAutoOpenCount === 0 ? formatMessage(messages.onBoardingExplainMessage) : formatMessage(messages.onExplainMessage)}
          </li>
          <li>
            {aiTutorAutoOpenCount === 0 ? formatMessage(messages.onBoardingSummaryMessage) : formatMessage(messages.onSummaryMessage)}
          </li>
        </ul>
        <IconButton
          className={`${classes.ModalButton} ${showAnimation ? classes.slideInAnimation : ''}  ${arrowSlideOut ? classes.arrowSlideOutAnimation : ''}`}
          onClick={() => handleNext()}
          aria-label="Get Started"
        />
      </div>
      <div className={classes.ModalImageWrapper}>
        { !isExpanded
          ? ((!isExpanded && (botContainerHeight && botContainerHeight >= 500)) || (isExpanded && botContainerWidth > 400))
          && <div className={isExpanded ? `${classes.expandImageWrapper} ${classes.imageWrapper}` : `${classes.imageWrapper} ${classes.standardImageWrapper} ${getClassName()}`} />
          : <div className={getExpandClassName()} />}

      </div>
    </div>
  );
};
WelcomeModal.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onNext: PropTypes.object.isRequired,
  isExpanded: PropTypes.object.isRequired,
  aitutorWelcomeScreenCohorts: PropTypes.string.isRequired,
  aiTutorAutoOpenCount: PropTypes.number.isRequired
};

export default withStyles(styles)(injectIntl(WelcomeModal));
