/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Turkish Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'browser.check.DONT_SHOW_AGAIN': 'Bunu bir daha bana gösterme',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'desteklenen işletim sistemi ve web tarayıcısı',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Tarayıcınız <<name>> güncel değil',
  'browser.check.BROWSER_MESSAGE_DESC': 'En iyi deneyim için güncel tarayıcılardan birini seçin:',
  'browser.check.BROWSER_SKIP_NOW': 'Şimdilik geç',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Desteklenen tarayıcılar hakkında',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Tarayıcınız, <<name>> desteklenmiyor',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'En iyi deneyim için desteklenen tarayıcılardan birini seçin.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'İşletim sisteminiz, <<name>> desteklenmiyor',
  // eslint-disable-next-line max-len
  'browser.check.OS_UNSUPPORTED_DESC': 'Ürün herhangi bir sorun olmadan çalışabilse de, bir sorun yaşamanız halinde <<link>> çalıştırabilen bir cihaz bu sorununuzu çözecektir.',
  'common.OK': 'Ok',
  'common.CONTINUE': 'Devam et',
  'errors.GENERIC_TITLE': 'Sunucu Hatası',
  'errors.GENERIC_DESC': 'Üzgünüz, sistemimiz çalışmıyor. Lütfen daha sonra tekrar deneyin veya desteğe başvurun.',
  'errors.HTTP_400_TITLE': 'Sunucu Hatası',
  'errors.HTTP_400_DESC': 'İsteğiniz yerine getirilirken bir hatayla karşılaştık.',
  'errors.HTTP_401_TITLE': 'Giriş yaptınız mı?',
  'errors.HTTP_401_DESC': 'Giriş oturumunuzun süresi dolmuş olabileceğinden verileri getiremedik.',
  'errors.HTTP_403_TITLE': 'Yetkisiz',
  'errors.HTTP_403_DESC': 'Bu kaynağa erişiminiz yok.',
  'errors.HTTP_888_TITLE': 'Sunucu yanıt veremeyecek kadar yavaş',
  'errors.HTTP_888_DESC': 'İsteğiniz yerine getirilirken bir hatayla karşılaştık.'
};

export default RESOURCES;
