/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  card: {
    maxWidth: 600,
    margin: '0 auto',
    padding: 16,
    textAlign: 'center'
  },
  body: {
    padding: '32px 0'

  }
});

const Details = ({ details, showDetails }) => (
  showDetails ? (
    <Typography component="div" variant="body1">
      {details}
    </Typography>
  ) : null
);

Details.propTypes = {
  showDetails: PropTypes.bool,
  details: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ])
};

Details.defaultProps = {
  showDetails: false,
  details: ''
};

/**
 * A component that displays errors.
 *
 * @author Hari Gangadharan
 */
const ErrorCard = (
  {
    title, body, details, showDetails, actions, classes
  }
) => (
  <div className={classes.card}>
    <Typography component="div" variant="h1">
      {title}
    </Typography>
    <Typography component="div" variant="body1" className={classes.body}>
      {body}
    </Typography>
    <Details showDetails={showDetails} details={details} />
    {actions}
  </div>
);

ErrorCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ]).isRequired,
  showDetails: PropTypes.bool,
  details: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ]),
  body: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ]).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ]),
  classes: PropTypes.object.isRequired
};

ErrorCard.defaultProps = {
  actions: null,
  showDetails: false,
  details: ''
};

export default withStyles(styles)(ErrorCard);
