/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import EventBus from 'eventing-bus';
import BaseService from './BaseService';
import Framework from '../framework/Framework';

import * as constants from '../common/constants';
import env from '../common/env';
import Utils from '../utils/Utils';

const clientManager = Framework.getClientManager();
/**
 * User Service gets the user profile from IES through Tiburon.
 *
 * @author Hari Gangadharan
 */
export default class UserService extends BaseService {
  constructor(isMojoMvp, subscriptionFilter, enableGetDeviceList) {
    super(constants.IES_USER_PROFILE_REQUESTED, 'userStatus');
    this.isMojoMvp = isMojoMvp;
    this.subscriptionFilter = subscriptionFilter;
    this.enableGetDeviceList = enableGetDeviceList;
    clientManager.add({
      name: 'userProfile',
      authHeader: 'authorization',
      tokenPrefix: 'Bearer ',
      baseUrl: env.get('USER_PROFILE_BASE_URL'),
      timeout: env.get('USER_PROFILE_TIMEOUT'),
      addCorrelationId: false
    });
  }

  handleEvent(eventName, event) {
    if (event.type === 'POST') {
      return clientManager.get('userProfile').post('/users', event.payLoad);
    }
    const url = window.location.href;
    let subscriptionFilter = this.subscriptionFilter || 'gpsSubscriptions';
    if (url.indexOf('/home') > -1) {
      subscriptionFilter = this.subscriptionFilter || 'gpsSubscriptions';
    }
    const productType = env.get('PRODUCT_TYPE');
    const userEndPoint = productType ? `/users/${event.userId}?include=${subscriptionFilter}&type=${productType}` : `/users/${event.userId}?include=${subscriptionFilter}`;

    return clientManager.get('userProfile').get(userEndPoint);
  }

  // eslint-disable-next-line class-methods-use-this
  retrivePreference = (user, delay = 0) => {
    const preferenceStatus = constants.GPC_PREFERENCE_RETRIEVE;
    const payload = { email: user.email };
    setTimeout(() => { EventBus.publish(preferenceStatus, payload); }, delay);
  };

  handleResponse(response, event) {
    const user = response.data;
    const gpsSubscription = user.gpsSubscriptions && user.gpsSubscriptions.find(
      (subscription) => subscription.status === constants.ACTIVE
      || subscription.status === constants.PAUSED
    );
    let isMojoUser = false;
    const piSession = window.piSession;
    const isCanadianProxy = Utils.getQueryParameterbyName('cp');
    const isGhostAccount = Utils.isQueryParamAvailable(constants.TPI, 'y');

    if ((isCanadianProxy === '1' || isGhostAccount) && piSession) {
      piSession.setOptions({ requireLogin: false });
    }

    if (!this.isMojoMvp && window.location.href.includes('/home')) {
      this.retrivePreference(user);
    }

    if (this.isMojoMvp && gpsSubscription) {
      const isLocalhost = window.location.host.includes(constants.LOCAL);
      const currentUrl = window.location.href;
      if (!isLocalhost && !window.location.host.includes('etext-ise-perf') && window.location.host.includes(constants.ETEXT) && window.location.pathname.includes('home')) {
        window.location.href = currentUrl.replace(constants.ETEXT, constants.PLUS);
      }
      isMojoUser = true;
      if (this.enableGetDeviceList) {
        Framework.initiateDeviceManagement(user.isImpersonatedId);
      }

      if (!window.isSSIEnabled && piSession) {
        piSession.setOptions({
          requireLogin: false
        });
      }
    }
    user.isMojoUser = isMojoUser;
    Framework.getStoreRegistry().getStore('user').set(user);
    Framework.getEventManager().publish(constants.USER_PROFILE_FETCHED, user);
    if (event.type === 'POST' && !event.isSilentUpdate) {
      Framework.getEventManager().publish(constants.USER_PROFILE_UPDATE_SUCCESS);
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    if (event.type === 'POST' && !event.isSilentUpdate) {
      Framework.getEventManager().publish(constants.USER_PROFILE_UPDATE_FAILED, {
        message: (error.response && error.response.data && error.response.data.message) || error.message
      });
      Utils.dispatchGaEvent(constants.LOAD_ERROR, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_LABEL, error.response.data.message);
    }
  }
}
