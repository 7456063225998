/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatHistoryListItem.jsx
 * @author Arifhusain Soudagar
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  intlShape
} from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Utilities } from '@aquila/core';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ClickAwayListener,
  Paper,
  InputBase
} from '@material-ui/core';
import messages from '../../defaultMessages';
import ChatHistoryStyles from './ChatHistoryStyles';
import ThreeDots from '../../Common/icons/ThreeDots';
import PencilIcon from '../../Common/icons/PencilIcon';
import DeleteBinIcon from '../../Common/icons/DeleteBinIcon';
import MessageIcon from '../../Common/icons/MessageIcon';
import globalContext from '../../Context/globalContext';
import RightIcon from '../../Common/icons/RightIcon';
import HistoryCloseIcon from '../../Common/icons/HistoryCloseIcon';
import Utils from '../../Common/Utils';

const ChatHistoryListItem = memo(({
  intl,
  classes,
  data,
  onRenderHistory,
  masterTheme,
  theme,
  isRenameSuccess,
  onDelete,
  onRename
}) => {
  const { formatMessage } = intl;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [renameTextbox, setRenameTextbox] = React.useState(false);
  const [inputDisable, setInputDisable] = React.useState(false);
  const [blankInputSubmitStyle, setBlankInputSubmitStyle] = React.useState(false);
  const [renameHistoryListItem, setRenameHistoryListItem] = React.useState('');
  const inputRef = React.useRef(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [renameTextbox]);
  React.useEffect(() => {
    if (isRenameSuccess) {
      setRenameTextbox(false);
    }
  }, [isRenameSuccess]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStateChange = () => {
    setRenameTextbox(false);
    setRenameHistoryListItem('');
    setBlankInputSubmitStyle(false);
  };

  const handleRename = (e, title) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
    setRenameTextbox(true);
    setInputDisable(false);
    setRenameHistoryListItem(title);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
    onDelete(data.threadId);
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBlankInputSubmitStyle(false);
    setRenameHistoryListItem(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (data.title !== renameHistoryListItem && data.title !== renameHistoryListItem.trim()) {
      if (!renameHistoryListItem || renameHistoryListItem.trim() === '') {
        setBlankInputSubmitStyle(true);
      } else {
        const updatedHistoryTitle = renameHistoryListItem.trim();
        onRename(data.threadId, updatedHistoryTitle);
        setInputDisable(true);
      }
    } else {
      handleStateChange();
    }
  };

  const handleClickAway = () => {
    handleStateChange();
  };

  const handleKeyUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Escape') {
      handleStateChange();
    }
  };
  return (
    <>
      <ListItem
        button={!renameTextbox}
        className={(data.threadId === globalContext.threadId) ? `${classes.historyListItemWrapper} ${classes.activeHistoryListItemWrapper}` : classes.historyListItemWrapper}
        classes={{ focusVisible: classes.listFocusStyle }}
        id={data.threadId}
      >
        {renameTextbox ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper component="form" onSubmit={handleSubmit} className={classes.renameInputRoot}>
              <InputBase
                disabled={inputDisable}
                type="text"
                value={renameHistoryListItem}
                className={`${blankInputSubmitStyle ? classes.blankInputStyle : classes.filledInputStyle} ${classes.renameInput}`}
                onChange={handleChange}
                ref={inputRef}
                onKeyUp={handleKeyUp}
              />
              <IconButton
                className={classes.renameButtonStyle}
                onClick={handleSubmit}
              >
                <RightIcon theme={theme} masterTheme={masterTheme} />
              </IconButton>
              <IconButton
                className={classes.renameButtonStyle}
                onClick={handleStateChange}
              >
                <HistoryCloseIcon theme={theme} masterTheme={masterTheme} />
              </IconButton>
            </Paper>
          </ClickAwayListener>
        )
          : (
            <>
              <MessageIcon masterTheme={masterTheme} theme={theme} />
              <ListItemText className={(data.threadId === globalContext.threadId) ? `${classes.historyListItemStyle} ${classes.activeHistoryListItemStyle}` : classes.historyListItemStyle} primary={Utilities.textFromContent(Utils.capitalizeFirstLetter(data.title), globalContext.enableNewMathConfig)} onClick={() => { onRenderHistory(data.threadId); }} />
              <IconButton classes={{ root: `${classes.menuButtonStyle} ${anchorEl ? classes.menuButtonActiveStyle : ''}` }} onClick={handleClick}>
                <ListItemIcon classes={{ root: classes.listItemIconStyle }}>
                  <ThreeDots masterTheme={masterTheme} theme={theme} />
                </ListItemIcon>
              </IconButton>
            </>
          )}
        {
          anchorEl
          && (
          <Menu
            getContentAnchorEl={null}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            classes={{
              list: classes.menuContainer,
              paper: classes.renamePaperStyle
            }}
            className={classes.poperStyle}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                width: 130
              }
            }}
          >
            <MenuItem className={classes.menuItemStyle} onClick={(e) => handleRename(e, data.title)}>
              <ListItemIcon classes={{ root: classes.listItemIconStyle }}>
                <PencilIcon masterTheme={masterTheme} theme={theme} />
              </ListItemIcon>
              <Typography className={classes.menuItemTextStyle} variant="inherit">{formatMessage(messages.rename)}</Typography>
            </MenuItem>
            <MenuItem className={classes.menuItemStyle} onClick={handleDelete}>
              <ListItemIcon classes={{ root: classes.listItemIconStyle }}>
                <DeleteBinIcon masterTheme={masterTheme} theme={theme} />
              </ListItemIcon>
              <Typography className={classes.menuItemTextStyle} variant="inherit">{formatMessage(messages.delete)}</Typography>
            </MenuItem>
          </Menu>
          )
        }
      </ListItem>
    </>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));
ChatHistoryListItem.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onRenderHistory: PropTypes.func.isRequired,
  masterTheme: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  isRenameSuccess: PropTypes.bool,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

ChatHistoryListItem.defaultProps = {
  isRenameSuccess: false
};

export default memo(withStyles(ChatHistoryStyles)(injectIntl(ChatHistoryListItem)));
