import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';

import messages from '../defaultMessages';
import ChatComponentStyles from '../Components/Chatbot/ChatComponentStyles';
import * as constants from '../Common/constants';

const SuggestionPills = ({
  classes,
  intl,
  currentContext,
  optionSelected,
  onCallback,
  isExpanded
}) => {
  const { formatMessage } = intl;
  let userOption = constants.DISCUSS;
  if (optionSelected === constants.SUMMARY || optionSelected === constants.BULLET || optionSelected === constants.PARAGRAPH) {
    userOption = constants.SUMMARY;
  } else if (optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE || optionSelected === constants.QUIZ) {
    userOption = constants.QUIZ;
  }
  let currentContextMessage = currentContext;
  if (currentContext === constants.CURRENT_CHAPTER) {
    currentContextMessage = 'current chapter';
  } else if (currentContext === constants.CURRENT_SECTION) {
    currentContextMessage = 'current section';
  }

  const suggestionAction = (type) => {
    onCallback(type, currentContext);
  };
  const buttonStyle = isExpanded ? `${classes.welcomeBtnStyles} ${classes.expandedActionButtonStyle}` : classes.welcomeBtnStyles;
  return (
    <Box>
      <Typography className={classes.suggestionText}>
        {formatMessage(messages.suggestionPillsText)}
        {' '}
        {currentContextMessage}
        ?
      </Typography>
      <div className={classes.suggestionActionContainer}>
        { (userOption !== constants.SUMMARY) && (
        <Button
          className={buttonStyle}
          onClick={() => suggestionAction(constants.SUMMARY, currentContext)}
          variant="contained"
        >
          {formatMessage(messages.welcomeBtnSummarize)}
        </Button>
        )}
        { (userOption !== constants.QUIZ) && (
          <Button
            className={buttonStyle}
            onClick={() => suggestionAction(constants.QUIZ, currentContext)}
            variant="contained"
          >
              {formatMessage(messages.welcomeBtnPractice)}
          </Button>
        )}
        { userOption !== constants.DISCUSS && (
          <Button
            className={buttonStyle}
            onClick={() => suggestionAction(constants.DISCUSS, currentContext)}
            variant="contained"
          >
            {formatMessage(messages.welcomeBtnExplain)}
          </Button>
        )}
      </div>
    </Box>
  );
};

SuggestionPills.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  currentContext: PropTypes.string.isRequired,
  optionSelected: PropTypes.string.isRequired,
  onCallback: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired
};

export default withStyles(ChatComponentStyles)(injectIntl(SuggestionPills));
