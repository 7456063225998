(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"), require("@aquila/core"), require("mobx-state-tree"), require("react-intl"), require("prop-types"), require("mobx-react"), require("@material-ui/core/Button"), require("@material-ui/core"), require("@material-ui/core/Typography"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("index", ["react", "react-router-dom", "@aquila/core", "mobx-state-tree", "react-intl", "prop-types", "mobx-react", "@material-ui/core/Button", "@material-ui/core", "@material-ui/core/Typography", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["index"] = factory(require("react"), require("react-router-dom"), require("@aquila/core"), require("mobx-state-tree"), require("react-intl"), require("prop-types"), require("mobx-react"), require("@material-ui/core/Button"), require("@material-ui/core"), require("@material-ui/core/Typography"), require("mobx"));
	else
		root["index"] = factory(root["react"], root["react-router-dom"], root["@aquila/core"], root["mobx-state-tree"], root["react-intl"], root["prop-types"], root["mobx-react"], root["@material-ui/core/Button"], root["@material-ui/core"], root["@material-ui/core/Typography"], root["mobx"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__4442__, __WEBPACK_EXTERNAL_MODULE__6223__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__993__, __WEBPACK_EXTERNAL_MODULE__1949__, __WEBPACK_EXTERNAL_MODULE__88__, __WEBPACK_EXTERNAL_MODULE__4785__, __WEBPACK_EXTERNAL_MODULE__7276__, __WEBPACK_EXTERNAL_MODULE__2532__, __WEBPACK_EXTERNAL_MODULE__5314__) => {
return 