import React, { useEffect, useState } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, TextField, Button, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import messages from '../../defaultMessages';
import FeedbackStyles from './FeedbackStyles';
import CheckboxCheckedIcon from '../../Common/icons/CheckboxCheckedIcon';
import CheckboxIcon from '../../Common/icons/CheckboxIcon';
import * as constants from '../../Common/constants';

const LABELS = {
  notHelpful: 'Not helpful',
  notCorrect: 'Not correct',
  unSafe: 'Unsafe/harmful',
  somethingElse: 'Something else'
};

const SOMETHING_ELSE = 'somethingElse';
const NOT_HELPFUL = 'notHelpful';
const NOT_CORRECT = 'notCorrect';
const UN_SAFE = 'unSafe';
function NegativeFeedback({
  classes,
  intl,
  handleSubmitNegativeFeedback,
  handleClose,
  isClickAway
}) {
  const { formatMessage } = intl;
  const [feedbackOptions, setFeedbackOptions] = React.useState({
    notHelpful: false,
    notCorrect: false,
    unSafe: false,
    sometingElse: false
  });
  const [inputValue, setInputValue] = useState('');
  const [isClose, setIsClose] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [textBoxRequired, setTextBoxRequired] = useState(false);
  const cancelButtonRef = React.useRef(null);
  const submitButtonRef = React.useRef(null);
  const checkButtonRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const handleInputChange = (e) => {
    const currentValue = e.target.value;
    setInputValue(currentValue);
    if (currentValue && currentValue.trim() && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE] || feedbackOptions[SOMETHING_ELSE])) {
      setDisableSubmitButton(false);
      setTextBoxRequired(false);
    } else {
      // setDisableSubmitButton(true);
      setDisableSubmitButton(!(feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE]) || feedbackOptions[SOMETHING_ELSE]);
      setTextBoxRequired(feedbackOptions[SOMETHING_ELSE]);
    }
  };
  const handleChange = (event) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;
    setFeedbackOptions({ ...feedbackOptions, [checkboxName]: isChecked });
    if (checkboxName === SOMETHING_ELSE && !isChecked && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE])) {
      setDisableSubmitButton(false);
      setTextBoxRequired(false);
    } else if (checkboxName === SOMETHING_ELSE && !isChecked && !(feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE])) {
      setDisableSubmitButton(true);
      setTextBoxRequired(false);
    } else if ((checkboxName === SOMETHING_ELSE && isChecked) || feedbackOptions[SOMETHING_ELSE]) {
      setDisableSubmitButton(!inputValue);
      setTextBoxRequired(!inputValue);
    } else if ((checkboxName === NOT_HELPFUL && !isChecked && (feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE])) || ((checkboxName === NOT_CORRECT && !isChecked && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[UN_SAFE]))) || ((checkboxName === UN_SAFE && !isChecked && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT])))) {
      setDisableSubmitButton(false);
      setTextBoxRequired(feedbackOptions[SOMETHING_ELSE]);
    } else if ((checkboxName === NOT_HELPFUL && !isChecked && !(feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE])) || (checkboxName === NOT_CORRECT && !isChecked && !(feedbackOptions[NOT_HELPFUL] || feedbackOptions[UN_SAFE])) || (checkboxName === UN_SAFE && !isChecked && !(feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT]))) {
      setDisableSubmitButton(true);
      setTextBoxRequired(feedbackOptions[SOMETHING_ELSE] && !inputValue);
    } else {
      setDisableSubmitButton(false);
      setTextBoxRequired(false);
    }
  };
  const {
    notHelpful, notCorrect, unSafe, somethingElse
  } = feedbackOptions;

  const mapFinalOptionsToLabels = () => {
    const finalSelectedOptions = [];
    Object.entries(feedbackOptions).forEach(([key, value]) => {
      if (value === true) {
        finalSelectedOptions.push(LABELS[key]);
      }
    });
    return finalSelectedOptions;
  };

  const handleCheckBoxKeyDown = (event) => {
    if (event.shiftKey && event.key === 'Tab' && disableSubmitButton) {
      event.preventDefault();
      cancelButtonRef.current.focus();
    }
    if (event.shiftKey && event.key === 'Tab' && !disableSubmitButton) {
      event.preventDefault();
      submitButtonRef.current.focus();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE] || (feedbackOptions[SOMETHING_ELSE] && inputValue && inputValue.trim()))) {
      event.preventDefault();
      setIsClose(true);
      setTimeout(() => {
        handleSubmitNegativeFeedback(inputValue, mapFinalOptionsToLabels());
      }, 150);
    }
  };

  const handleCloseKeyDown = (event) => {
    if (!event.shiftKey && event.key === 'Tab' && disableSubmitButton) {
      event.preventDefault();
      checkButtonRef.current.focus();
    }
  };

  const handleCloseClick = () => {
    setIsClose(true);
    setTimeout(() => {
      handleClose(constants.NEGATIVE_FEEDBACK);
    }, 200); // After animation duration time closed the feedback popup
  };

  const handleSubmit = (event) => {
    if (!event.shiftKey && event.key === 'Tab' && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE] || (feedbackOptions[SOMETHING_ELSE] && inputValue && inputValue.trim()))) {
      event.preventDefault();
      checkButtonRef.current.focus();
    } else if (event.key === 'Enter' && (feedbackOptions[NOT_HELPFUL] || feedbackOptions[NOT_CORRECT] || feedbackOptions[UN_SAFE] || (feedbackOptions[SOMETHING_ELSE] && inputValue && inputValue.trim()))) {
      event.preventDefault();
      setIsClose(true);
      setTimeout(() => {
        handleSubmitNegativeFeedback(inputValue, mapFinalOptionsToLabels());
      }, 150);
    }
  };

  const handleSubmitClick = () => {
    setIsClose(true);
    setTimeout(() => {
      handleSubmitNegativeFeedback(inputValue, mapFinalOptionsToLabels());
    }, 150);
  };

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.ariaLabel === null) {
      inputRef.current.ariaLabel = constants.WHAT_WAS_WRONG_RESPONSE;
    }
  }, []);

  return (
    <Box className={`${classes.feedbackContainer} ${isClose || isClickAway ? classes.slideDown : classes.slideUp}`} aria-labelledby="negativeFeedbackHeading">
      <Box className={classes.feedbackContainer__Header} id="negativeFeedback">
        <Typography id="negativeFeedbackHeading" className={classes.feedbackContainer__Header__text} variant="h4">{formatMessage(messages.negativeFeedbackHeading)}</Typography>
      </Box>
      <FormControl component="fieldset">
        <legend>
          <Typography id="negativeFeedbackDescription" className={classes.feedbackDescription} variant="h6">{formatMessage(messages.negativeFeedbackDescription)}</Typography>
        </legend>
        <FormGroup className={classes.formControl}>
          <FormControlLabel
            control={(
              <Checkbox
                classes={{
                  root: classes.checkboxRootStyle
                }}
                checked={notHelpful}
                inputRef={checkButtonRef}
                autoFocus
                onChange={handleChange}
                onKeyDown={handleCheckBoxKeyDown}
                name="notHelpful"
                checkedIcon={<CheckboxCheckedIcon />}
                icon={<CheckboxIcon />}
              />
)}
            label="Not helpful"
            classes={{ root: classes.checkboxRoot, label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                classes={{
                  root: classes.checkboxRootStyle
                }}
                checked={notCorrect}
                onChange={handleChange}
                name="notCorrect"
                checkedIcon={<CheckboxCheckedIcon />}
                icon={<CheckboxIcon />}
              />
)}
            label="Not correct"
            classes={{ root: classes.checkboxRoot, label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                classes={{
                  root: classes.checkboxRootStyle
                }}
                checked={unSafe}
                onChange={handleChange}
                name="unSafe"
                checkedIcon={<CheckboxCheckedIcon />}
                icon={<CheckboxIcon />}
              />
)}
            label="Unsafe/harmful"
            classes={{ root: classes.checkboxRoot, label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                classes={{
                  root: classes.checkboxRootStyle
                }}
                checked={somethingElse}
                onChange={handleChange}
                name="somethingElse"
                checkedIcon={<CheckboxCheckedIcon />}
                icon={<CheckboxIcon />}
              />
)}
            label="Something else"
            classes={{ root: classes.checkboxRoot, label: classes.checkboxLabel }}
          />
        </FormGroup>
      </FormControl>
      <TextField
        id="inputTextBox"
        inputRef={inputRef}
        InputProps={{
          classes: {
            root: `${classes.textBoxStyle} ${textBoxRequired ? classes.textBoxRequiredStyle : ''}`,
            input: classes.textBoxInputStyle,
            underline: classes.underlineStyle
          }
        }}
        multiline
        minRows={1}
        fullWidth
        placeholder={formatMessage(messages.negativeFeedbackPlaceHolder)}
        value={inputValue}
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => handleInputChange(event)}
      />
      <div className={classes.feedbackActionWrapper}>
        <Button
          ref={cancelButtonRef}
          id="negativeFeedbackCancel"
          className={classes.cancelStyle}
          onClick={handleCloseClick}
          onKeyDown={handleCloseKeyDown}
        >
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          ref={submitButtonRef}
          id="summary__NegativeFeedback__submit"
          disabled={disableSubmitButton}
          classes={{ disabled: classes.sendButtonDisabled }}
          className={classes.sendButton}
          onClick={handleSubmitClick}
          onKeyDown={handleSubmit}
        >
          {formatMessage(messages.submit)}
        </Button>
      </div>
    </Box>
  );
}

NegativeFeedback.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  handleSubmitNegativeFeedback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isClickAway: PropTypes.bool.isRequired
};

export default withStyles(FeedbackStyles)(injectIntl(NegativeFeedback));
