/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Indonesian Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Lanjutkan',
  'browser.check.DONT_SHOW_AGAIN': 'Jangan tampilkan ini lagi',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'sistem operasi dan browser web yang didukung',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Browser <<name>> Anda sudah usang',
  'browser.check.BROWSER_MESSAGE_DESC': 'Untuk pengalaman terbaik, pilih salah satu browser terbaru:',
  'browser.check.BROWSER_SKIP_NOW': 'Lewati untuk saat ini',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Tentang browser yang didukung',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Browser Anda, <<name>> tidak didukung',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Untuk pengalaman terbaik, pilih salah satu browser yang didukung.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Sistem operasi Anda, <<name>> tidak didukung',
  'browser.check.OS_UNSUPPORTED_DESC': 'Meskipun produk dapat berfungsi tanpa masalah, harap ketahui bahwa setiap masalah yang '
      + 'Anda temui dapat diselesaikan dengan mencoba menggunakan perangkat yang menjalankan <<link>>',
  'errors.GENERIC_TITLE': 'Kesalahan Server',
  'errors.GENERIC_DESC': 'Maaf, sistem kami tidak beroperasi. Silakan coba lagi nanti atau hubungi dukungan.',
  'errors.HTTP_400_TITLE': 'Kesalahan Server',
  'errors.HTTP_400_DESC': 'Kami mengalami kesalahan saat memroses permintaan Anda.',
  'errors.HTTP_401_TITLE': 'Anda masuk?',
  'errors.HTTP_401_DESC': 'Kami tidak dapat mengambil data karena sesi masuk Anda mungkin telah kedaluwarsa.',
  'errors.HTTP_403_TITLE': 'Tidak diotorisasi',
  'errors.HTTP_403_DESC': 'Anda tidak memiliki akses ke sumber daya ini.',
  'errors.HTTP_888_TITLE': 'Server terlalu lamban merespons',
  'errors.HTTP_888_DESC': 'Kami mengalami kesalahan saat memroses permintaan Anda.'
};

export default RESOURCES;
