/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ImageParser.js
 * @author Sarathkumar Venkatachalam
 */

import BaseParser from './BaseParser';
import * as constants from '../constants';

export default class ImageParser extends BaseParser {
  static parse(rootElementId, callback) {
    const images = document.querySelectorAll(`#${rootElementId} img`);
    if (images && images.length) {
      images.forEach((imageElement) => {
        imageElement.addEventListener('click', () => { callback(constants.IMAGE); });
      });
    }
  }
}
