/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * Channel Services
 * @author Sarathkumar Venkatachalam
 */

export default class ChannelServices {
  constructor(client) {
    this.channelClient = client;
  }

  fetchVideo(data, callback) {
    const { channelId, userTopic } = data;
    return new Promise((resolve, reject) => {
      this.channelClient.get(`search/etext/${channelId}?q=${userTopic}`).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
