/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import * as yup from 'yup';
import { onSnapshot } from 'mobx-state-tree';
import ulog from 'ulog';
import env from '../common/env';

const log = ulog('StoreRegistry');

const schema = yup.object().shape({
  name: yup.string().required(),
  saveLocally: yup.boolean(),
  init: yup.mixed(),
  initialState: yup.object().required(),
  spec: yup.mixed().required()
}).required();

/**
 * Registry for all the stores.
 *
 * @author Hari Gangadharan
 */
export default class StoreRegistry {
  constructor() {
    this.stores = {};
  }

  register(store) {
    schema.validateSync(store);
    const {
      name, saveLocally, spec, init
    } = store;
    let { initialState } = store;
    if (this.stores[name]) {
      throw new Error(`Store with name ${name} already registered - use another name`);
    }
    let createdStore = null;
    if (saveLocally) {
      let savedItem;
      try {
        savedItem = localStorage.getItem(name);
      } catch (e) {
        log.error(e);
        setTimeout(() => {
          window.location.href = `${env.get('IES_BASE_URL')}/login/static/html/cookies-not-enabled.html`;
        }, 1000);
      }
      if (savedItem) {
        const json = JSON.parse(savedItem);
        if (spec.is(json)) {
          initialState = json;
        }
      }
      createdStore = spec.create(initialState);
      onSnapshot(createdStore, (snapshot) => {
        try {
          localStorage.setItem(name, JSON.stringify(snapshot));
        } catch (e) {
          log.error(e);
          setTimeout(() => {
            window.location.href = `${env.get('IES_BASE_URL')}/login/static/html/cookies-not-enabled.html`;
          }, 1000);
        }
      });
    } else {
      createdStore = spec.create(initialState);
    }
    this.stores[store.name] = createdStore;
    if (init) {
      init(createdStore);
    }
  }

  /**
   * Gets the store associated with the name.
   * @param name the name of the store
   * @return {*} the store for name
   */
  getStore(name) {
    return this.stores[name];
  }

  /**
   * Gets all Mobx stores in this registry.
   *
   * @return {{}}
   */
  getStores() {
    return this.stores;
  }
}
