/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Portuguese Brazil Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'common.OK': 'Ok',
  'common.CONTINUE': 'Continuar',
  'browser.check.DONT_SHOW_AGAIN': 'Não mostrar novamente',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'sistema de operação e navegador de web suportados',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Seu navegador <<name>> está desatualizado',
  'browser.check.BROWSER_MESSAGE_DESC': 'Para melhor experiência, selecione um dos navegadores mais atuais:',
  'browser.check.BROWSER_SKIP_NOW': 'Pular por ora',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Sobre os navegadores suportados',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Seu navegador, <<name>> não é suportado',
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Para melhor experiência, selecione um dos navegadores suportados.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Seu sistema operacional, <<name>> não é suportado',
  'browser.check.OS_UNSUPPORTED_DESC': 'Ainda que o produto funcione sem problemas, saiba que quaisquer problemas que '
    + 'enfrentar podem ser resolvidos ao tentar executar dispositivo <<link>>',
  'errors.GENERIC_TITLE': 'Erro de servidor',
  'errors.GENERIC_DESC': 'Sentimos muito, nosso sistema caiu. Tente novamente mais tarde ou entre em contato com o suporte.',
  'errors.HTTP_400_TITLE': 'Erro de servidor',
  'errors.HTTP_400_DESC': 'Encontramos um erro ao processar sua solicitação.',
  'errors.HTTP_401_TITLE': 'Você está conectado(a)?',
  'errors.HTTP_401_DESC': 'Não conseguimos encontrar os dados já que sua sessão de login pode ter expirado.',
  'errors.HTTP_403_TITLE': 'Não autorizado',
  'errors.HTTP_403_DESC': 'Você não tem acesso a este recurso.',
  'errors.HTTP_888_TITLE': 'O servidor está lento demais para responder',
  'errors.HTTP_888_DESC': 'Encontramos um erro ao processar sua solicitação.'
};

export default RESOURCES;
