/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Spanish Mexico Language Resource file.
 *
 * @author Vignesh Selvam
 */
const RESOURCES = {
  'browser.check.DONT_SHOW_AGAIN': 'No volver a mostrarme esto.',
  'browser.check.SYS_REQUIREMENTS_LINK_TEXT': 'Sistema operativo y navegador web compatibles.',
  'browser.check.BROWSER_MESSAGE_TITLE': 'Su navegador, <<name>>, está obsoleto.',
  // eslint-disable-next-line max-len
  'browser.check.BROWSER_MESSAGE_DESC': 'Para tener la mejor experiencia, seleccione uno de los navegadores más recientes:',
  'browser.check.BROWSER_SKIP_NOW': 'Omitir por ahora',
  'browser.check.BROWSER_SUPPORTED_LINK_TEXT': 'Acerca de los navegadores compatibles',
  'browser.check.BROWSER_UNSUPPORTED_TITLE': 'Su navegador, <<name>>, no es compatible.',
  // eslint-disable-next-line max-len
  'browser.check.BROWSER_UNSUPPORTED_DESC': 'Para tener la mejor experiencia, seleccione uno de los navegadores compatibles.',
  'browser.check.OS_UNSUPPORTED_TITLE': 'Su sistema operativo, <<name>>, no es compatible.',
  // eslint-disable-next-line max-len
  'browser.check.OS_UNSUPPORTED_DESC': 'Aunque el producto pueda funcionar sin inconvenientes, tenga en cuenta que, en caso de tener algún problema, podría resolverlo con un dispositivo que ejecute un <<link>>.',
  'common.OK': 'Aceptar',
  'common.CONTINUE': 'Continuar',
  'errors.GENERIC_TITLE': 'Error del servidor',
  'errors.GENERIC_DESC': 'Lo sentimos, el sistema está caído. Intente de nuevo más tarde o comuníquese con soporte.',
  'errors.HTTP_400_TITLE': 'Error del servidor',
  'errors.HTTP_400_DESC': 'Detectamos un error al procesar su solicitud.',
  'errors.HTTP_401_TITLE': '¿Ha iniciado sesión?',
  'errors.HTTP_401_DESC': 'No pudimos obtener los datos, ya que su sesión podría haber caducado.',
  'errors.HTTP_403_TITLE': 'No autorizado',
  'errors.HTTP_403_DESC': 'No tiene acceso a este recurso.',
  'errors.HTTP_888_TITLE': 'El servidor tardó demasiado en responder.',
  'errors.HTTP_888_DESC': 'Detectamos un error al procesar su solicitud.'
};

export default RESOURCES;
